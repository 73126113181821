.divwrap,
.nephewNodes,
.order {
  display: flex;
  align-items: flex-start;
}
.nephewNodes {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 236px;
  box-sizing: border-box;
  max-width: 100%;
}
.divwrap,
.order {
  justify-content: flex-start;
}
.divwrap {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  box-sizing: border-box;
  gap: 192px 0;
  max-width: 100%;
}
.order {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: row;
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .nephewNodes {
    padding-bottom: 99px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) {
  .divwrap {
    gap: 96px 0;
  }
}
@media screen and (max-width: 750px) {
  .nephewNodes {
    padding-bottom: 64px;
    box-sizing: border-box;
  }
  .divwrap {
    gap: 48px 0;
  }
}
