.div {
  position: absolute;
  top: 53px;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.groupChild {
  position: absolute;
  top: -0.5px;
  left: 805.5px;
  border-right: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1px;
  height: 158px;
}
.groupItem {
  position: absolute;
  top: -21px;
  left: -70px;
  border-radius: var(--br-3xs);
  background-color: var(--color-gainsboro-300);
  width: 248px;
  height: 153px;
  display: none;
}
.div1,
.div2 {
  position: absolute;
  top: 0;
  left: 25px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.div2 {
  top: 60px;
  left: 0;
  font-size: var(--font-size-21xl);
}
.parent,
.rectangleParent {
  position: absolute;
  top: 25px;
  left: 894px;
  width: 119px;
  height: 115px;
  font-size: var(--font-size-6xl);
}
.parent {
  top: 293px;
  left: 547px;
  width: 1013px;
  height: 157px;
  text-align: left;
  font-size: var(--font-size-26xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
