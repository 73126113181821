.icon {
  align-self: stretch;
  height: 47px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}
.wrapper {
  width: 334px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.headerList,
.headerList1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList3 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.loginFrame,
.messageFrame {
  display: flex;
  justify-content: flex-start;
}
.messageFrame {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.loginFrame {
  flex-direction: row;
  align-items: flex-end;
  gap: 0 67px;
  max-width: 100%;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}
.headerList4 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.frameWrapper,
.helpFrame,
.loginFrameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.helpFrame {
  justify-content: flex-start;
  gap: 0 12px;
  text-align: right;
}
.frameWrapper,
.loginFrameParent {
  max-width: 100%;
}
.loginFrameParent {
  width: 914.5px;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frameWrapper {
  align-self: stretch;
  justify-content: flex-end;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
.h1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.div,
.h1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.confirmationGroupFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-25xl);
  font-size: var(--font-size-21xl);
}
.frameChild {
  height: 83px;
  width: 376px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-darkslategray-500);
  display: none;
  max-width: 100%;
}
.div1 {
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-white);
  text-align: left;
  z-index: 1;
}
.helpListFrame,
.rectangleParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.rectangleParent {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-xl);
  background-color: var(--color-darkslategray-500);
  width: 376px;
  border-radius: var(--br-xl);
  justify-content: center;
}
.rectangleParent:hover {
  background-color: var(--color-dimgray-200);
}
.helpListFrame {
  justify-content: flex-end;
  padding: 0 var(--padding-30xl) 0 var(--padding-57xl);
}
.orderCompletedFrame {
  width: 501px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 73px 0;
}
.frameGroup,
.frameParent,
.orderCompletedFrame {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frameGroup {
  width: 1186.5px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 498px 0;
}
.frameParent {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.orderdone,
.orderdoneInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.orderdoneInner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-16xl);
  max-width: 100%;
  text-align: left;
  font-size: 55px;
  color: var(--color-darkslategray-500);
  font-family: var(--font-apple-sd-gothic-neo);
}
.orderdone {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: 504px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1600px) {
  .frameParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .frameGroup {
    gap: 249px 0;
  }
  .orderdone {
    gap: 252px 0;
  }
}
@media screen and (max-width: 900px) {
  .loginFrame {
    gap: 0 33px;
  }
  .div,
  .h1 {
    line-height: 44px;
  }
  .h1 {
    font-size: 44px;
  }
  .div {
    font-size: var(--font-size-13xl);
  }
  .helpListFrame {
    padding-left: var(--padding-19xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
  .orderCompletedFrame {
    gap: 36px 0;
  }
  .frameGroup {
    gap: 124px 0;
  }
  .orderdone {
    gap: 126px 0;
  }
}
@media screen and (max-width: 450px) {
  .loginFrame {
    gap: 0 17px;
  }
  .div,
  .h1 {
    font-size: var(--font-size-14xl);
    line-height: 33px;
  }
  .div {
    font-size: var(--font-size-5xl);
  }
  .confirmationGroupFrame {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .div1 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .orderCompletedFrame {
    gap: 18px 0;
  }
  .frameGroup {
    gap: 62px 0;
  }
}
