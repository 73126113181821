.headerListItemLink,
.instagram1Icon {
  height: 29px;
  width: 29px;
  position: absolute;
  margin: 0 !important;
  right: 374px;
  bottom: 77.7px;
  overflow: hidden;
  flex-shrink: 0;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  right: 121px;
  bottom: 53.7px;
  object-fit: contain;
  cursor: pointer;
}
.instagram1Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}
