.headerList,
.headerList1 {
  position: absolute;
  top: 11px;
  cursor: pointer;
}
.headerList {
  left: 1102px;
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList1 {
  left: 1391px;
}
.headerList1,
.headerList2,
.headerList3 {
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList2 {
  position: absolute;
  top: 11px;
  left: 1263px;
}
.headerList3 {
  left: 956px;
}
.headerList3,
.headerList4,
.headerListItemLink {
  position: absolute;
  top: 11px;
  cursor: pointer;
}
.headerListItemLink {
  height: calc(100% - 65px);
  bottom: 54px;
  left: 1725px;
  max-height: 100%;
  width: 62px;
  overflow: hidden;
  object-fit: contain;
}
.headerList4 {
  right: 49.5px;
  letter-spacing: -0.16px;
  line-height: 48px;
  text-align: right;
  display: flex;
  align-items: center;
  width: 71.5px;
  height: 48px;
}
.instagram1Icon {
  top: 21px;
  left: 1505px;
  width: 29px;
  height: 29px;
  overflow: hidden;
}
.header,
.icon,
.instagram1Icon {
  position: absolute;
}
.icon {
  top: 27px;
  left: 23px;
  width: 334px;
  height: 47px;
  object-fit: cover;
  cursor: pointer;
}
.header {
  top: 11px;
  left: 12px;
  width: 1920px;
  height: 128px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
