@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../public/AppleSDGothicNeoL.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../public/AppleSDGothicNeoR.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../public/AppleSDGothicNeoR.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../public/AppleSDGothicNeoSB.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../public/AppleSDGothicNeoB.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Apple Symbols";
  src: url("../public/AppleSDGothicNeoR.ttf");
  font-weight: 400;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-apple-sd-gothic-neo: "Apple SD Gothic Neo";
  --font-cormorant: Cormorant;
  --font-apple-symbols: "Apple Symbols";
  --font-alegreya: Alegreya;

  /* font sizes */
  --font-size-mid: 17px;
  --font-size-xl: 20px;
  --font-size-mini: 15px;
  --font-size-3xl: 22px;
  --font-size-21xl: 40px;
  --font-size-31xl: 50px;
  --font-size-3xs: 10px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-15xl: 34px;
  --font-size-8xl: 27px;
  --font-size-7xl: 26px;
  --font-size-2xl: 21px;
  --font-size-9xl: 28px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-11xl: 30px;
  --font-size-46xl: 65px;
  --font-size-33xl: 52px;
  --font-size-20xl: 39px;
  --font-size-13xl: 32px;
  --font-size-sm: 14px;
  --font-size-19xl: 38px;
  --font-size-4xl: 23px;
  --font-size-14xl: 33px;
  --font-size-56xl: 75px;
  --font-size-41xl: 60px;
  --font-size-26xl: 45px;
  --font-size-6xl: 25px;
  --font-size-16xl: 35px;
  --font-size-xs: 12px;
  --font-size-smi: 13px;

  /* Colors */
  --color-white: #fff;
  --color-dimgray-200: #636b61;
  --color-dimgray-100: #5f695a;
  --color-dimgray: #606062;
  --color-dimgray-300: #5e635c;
  --color-dimgray-400: rgba(94, 99, 92, 0.09);
  --color-dimgray-500: rgba(95, 105, 90, 0.9);
  --color-darkslategray-100: #4a4a4a;
  --color-darkslategray-400: #3e4345;
  --color-darkslategray-500: #3e463a;
  --color-darkslategray-200: #383c35;
  --color-darkslategray-600: #333;
  --color-darkslategray-700: rgba(56, 60, 53, 0.6);
  --color-darkslategray-800: rgba(56, 60, 53, 0.5);
  --color-darkslategray-900: rgba(56, 60, 53, 0.7);
  --color-darkslategray-1000: rgba(56, 60, 53, 0.51);
  --color-darkslategray-300: rgba(56, 60, 53, 0.54);
  --color-lightgray: #c4cdbf;
  --color-gainsboro-100: #e4e9e1;
  --color-gainsboro-200: #e6e6e6;
  --color-gainsboro-300: #e3e8e1;
  --color-gainsboro-400: #e3e7e1;
  --color-gainsboro-500: #d9d9d9;
  --color-gainsboro-600: rgba(227, 232, 225, 0.5);
  --color-gainsboro-700: rgba(227, 231, 225, 0.71);
  --color-gray-100: #797979;
  --color-gray-200: rgba(0, 0, 0, 0.3);
  --color-gray-300: rgba(33, 33, 33, 0.4);
  --color-gray-400: rgba(255, 255, 255, 0.9);
  --color-gray-500: rgba(255, 255, 255, 0.2);
  --color-black: #000;
  --color-gold: #fee500;
  --color-whitesmoke: #e6ebec;
  --color-darkgray: #91998c;
  --color-darkorange: #ff852d;
  --color-chocolate-100: #cc5200;
  --color-chocolate-200: rgba(204, 82, 0, 0.09);

  /* Gaps */
  --gap-smi: 13px;
  --gap-xl: 20px;
  --gap-4xs: 9px;
  --gap-mini: 15px;
  --gap-4xs-6: 8.6px;
  --gap-12xs: 1px;
  --gap-45xl-4: 64.4px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-2xs: 11px;
  --gap-15xl: 34px;
  --gap-mid: 17px;
  --gap-2xl: 21px;
  --gap-0: 0px;
  --gap-6xs-8: 6.8px;
  --gap-7xs: 6px;
  --gap-52xl: 71px;
  --gap-lg: 18px;
  --gap-16xl: 35px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-9xl: 28px;
  --gap-27xl-5: 46.5px;
  --gap-4xl: 23px;
  --gap-xs: 12px;
  --gap-8xs: 5px;
  --gap-45xl-3: 64.3px;
  --gap-111xl: 130px;
  --gap-46xl: 65px;
  --gap-sm: 14px;
  --gap-85xl-4: 104.4px;
  --gap-33xl: 52px;
  --gap-7xl: 26px;
  --gap-238xl: 257px;
  --gap-45xl: 64px;
  --gap-109xl: 128px;
  --gap-20xl: 39px;
  --gap-lgi: 19px;
  --gap-5xl: 24px;
  --gap-6xs: 7px;
  --gap-22xl: 41px;
  --gap-250xl: 269px;
  --gap-48xl: 67px;
  --gap-115xl: 134px;
  --gap-27xl: 46px;
  --gap-72xl: 91px;
  --gap-26xl: 45px;
  --gap-120xl: 139px;
  --gap-50xl: 69px;
  --gap-6xl-5: 25.5px;
  --gap-3xl: 22px;
  --gap-171xl: 190px;
  --gap-76xl: 95px;
  --gap-28xl: 47px;
  --gap-30xl: 49px;
  --gap-mid-2: 17.2px;
  --gap-77xl: 96px;
  --gap-173xl: 192px;
  --gap-124xl: 143px;
  --gap-17xl: 36px;
  --gap-3xs: 10px;
  --gap-6xl: 25px;
  --gap-11xl: 30px;
  --gap-43xl: 62px;
  --gap-105xl: 124px;
  --gap-31xl: 50px;
  --gap-81xl: 100px;
  --gap-41xl: 60px;
  --gap-25xl: 44px;
  --gap-8xs-2: 4.2px;
  --gap-12xs-2: 0.2px;
  --gap-3xs-3: 9.3px;
  --gap-70xl: 89px;
  --gap-12xs-7: 0.7px;
  --gap-82xl: 101px;
  --gap-12xl: 31px;
  --gap-47xl-5: 66.5px;
  --gap-14xl: 33px;
  --gap-3xl-3: 22.3px;
  --gap-9xl-5: 28.5px;
  --gap-18xl: 37px;
  --gap-60xl: 79px;
  --gap-8xl: 27px;
  --gap-11xs: 2px;
  --gap-3xl-5: 22.5px;
  --gap-2xl-5: 21.5px;
  --gap-12xl-5: 31.5px;
  --gap-28xl-5: 47.5px;
  --gap-3xl-2: 22.2px;
  --gap-sm-8: 13.8px;
  --gap-192xl: 211px;
  --gap-86xl: 105px;

  /* Paddings */
  --padding-12xs-3: 0.3px;
  --padding-138xl: 157px;
  --padding-5xl: 24px;
  --padding-6xl-3: 25.3px;
  --padding-83xl: 102px;
  --padding-xl: 20px;
  --padding-11xs: 2px;
  --padding-sm: 14px;
  --padding-1071xl: 1090px;
  --padding-526xl: 545px;
  --padding-253xl: 272px;
  --padding-21xl: 40px;
  --padding-188xl-2: 207.2px;
  --padding-183xl-2: 202.2px;
  --padding-16xl: 35px;
  --padding-9xs: 4px;
  --padding-34xl: 53px;
  --padding-33xl: 52px;
  --padding-7xl: 26px;
  --padding-20xl: 39px;
  --padding-50xl-4: 69.4px;
  --padding-14xl: 33px;
  --padding-26xl: 45px;
  --padding-10xl: 29px;
  --padding-6xs: 7px;
  --padding-735xl: 754px;
  --padding-471xl: 490px;
  --padding-299xl: 318px;
  --padding-4xs: 9px;
  --padding-455xl: 474px;
  --padding-181xl: 200px;
  --padding-289xl: 308px;
  --padding-xs: 12px;
  --padding-12xs: 1px;
  --padding-10xs-4: 2.4px;
  --padding-sm-2: 13.2px;
  --padding-smi: 13px;
  --padding-6xl: 25px;
  --padding-58xl: 77px;
  --padding-mid-5: 17.5px;
  --padding-297xl: 316px;
  --padding-186xl: 205px;
  --padding-3xl: 22px;
  --padding-6xl-4: 25.4px;
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-641xl: 660px;
  --padding-311xl: 330px;
  --padding-146xl: 165px;
  --padding-base-6: 16.6px;
  --padding-mid: 17px;
  --padding-46xl: 65px;
  --padding-57xl: 76px;
  --padding-30xl: 49px;
  --padding-90xl: 109px;
  --padding-52xl: 71px;
  --padding-mini: 15px;
  --padding-lg: 18px;
  --padding-91xl: 110px;
  --padding-27xl: 46px;
  --padding-5xs: 8px;
  --padding-6xs-4: 6.4px;
  --padding-25xl: 44px;
  --padding-47xl: 66px;
  --padding-24xl: 43px;
  --padding-base-7: 16.7px;
  --padding-66xl: 85px;
  --padding-36xl: 55px;
  --padding-17xl: 36px;
  --padding-9xl: 28px;
  --padding-17xl-5: 36.5px;
  --padding-15xl: 34px;
  --padding-19xl: 38px;
  --padding-1059xl: 1078px;
  --padding-250xl: 269px;
  --padding-520xl: 539px;
  --padding-mini-4: 14.4px;
  --padding-8xs-2: 4.2px;
  --padding-10xs: 3px;
  --padding-base-3: 16.3px;
  --padding-11xl-7: 30.7px;
  --padding-xs-1: 11.1px;
  --padding-sm-3: 13.3px;
  --padding-lgi-3: 19.3px;
  --padding-2xl: 21px;
  --padding-smi-8: 12.8px;
  --padding-base-8: 16.8px;
  --padding-3xs: 10px;
  --padding-4xs-6: 8.6px;
  --padding-60xl: 79px;
  --padding-6xl-7: 25.7px;
  --padding-2xs: 11px;
  --padding-xs-3: 11.3px;
  --padding-2xl-2: 21.2px;
  --padding-9xl-4: 28.4px;
  --padding-lgi: 19px;
  --padding-sm-6: 13.6px;
  --padding-76xl: 95px;
  --padding-70xl-1: 89.1px;
  --padding-34xl-5: 53.5px;
  --padding-34xl-7: 53.7px;
  --padding-11xs-6: 1.6px;
  --padding-4xl: 23px;
  --padding-31xl: 50px;
  --padding-xs-2: 11.2px;
  --padding-10xs-2: 2.2px;
  --padding-4xs-2: 8.2px;
  --padding-12xl: 31px;
  --padding-18xl: 37px;
  --padding-8xs: 5px;
  --padding-29xl: 48px;
  --padding-153xl-5: 172.5px;
  --padding-153xl: 172px;
  --padding-93xl: 112px;
  --padding-11xl: 30px;
  --padding-104xl: 123px;
  --padding-105xl: 124px;
  --padding-97xl: 116px;
  --padding-75xl: 94px;
  --padding-73xl: 92px;
  --padding-44xl: 63px;
  --padding-50xl: 69px;
  --padding-mid-7: 17.7px;
  --padding-sm-5: 13.5px;
  --padding-8xs-5: 4.5px;
  --padding-11xl-3: 30.3px;
  --padding-2xs-9: 10.9px;
  --padding-18xl-5: 37.5px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-base: 16px;
  --br-mini: 15px;
  --br-12xs: 1px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-5xs: 8px;
  --br-9xs: 4px;
}
