.listItem,
.listItem1,
.shippingReturn {
  position: relative;
  letter-spacing: -0.16px;
}
.shippingReturn {
  margin: 0;
  font-size: inherit;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
}
.listItem,
.listItem1 {
  line-height: 25.6px;
}
.listItem1 {
  align-self: stretch;
  height: 39px;
  font-size: var(--font-size-sm);
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.sequentialFlow {
  width: 168.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 4.100000000000364px 0;
  box-sizing: border-box;
  gap: 4.099999999998545px;
}
.b {
  width: 82.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b,
.div,
.listItem2 {
  box-sizing: border-box;
  display: flex;
}
.div {
  width: 553.8px;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  max-width: 100%;
  padding-right: var(--padding-xl);
}
.listItem2 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
}
.b1 {
  width: 82.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b1,
.div1,
.listItem3 {
  box-sizing: border-box;
  display: flex;
}
.div1 {
  width: 677.4px;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  max-width: 100%;
  padding-right: var(--padding-xl);
}
.listItem3 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
}
.b2,
.div2 {
  position: relative;
  letter-spacing: -0.16px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b2 {
  width: 82.6px;
  line-height: 25.6px;
  display: flex;
  box-sizing: border-box;
}
.div2 {
  width: 560.2px;
  font-size: var(--font-size-sm);
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  max-width: 100%;
}
.b3,
.div2,
.listItem4 {
  display: flex;
  box-sizing: border-box;
}
.listItem4 {
  align-self: stretch;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
  max-width: 100%;
}
.b3 {
  width: 101.2px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.listItem6,
.listItem7,
.listItem8,
.listItem9 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  white-space: nowrap;
}
.userInteractions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 22.399999999999636px;
  gap: 4.0000000000009095px;
}
.listItem10,
.listItem11,
.listItem12,
.listItem13 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  white-space: nowrap;
}
.dataVisualizer,
.timelineBuilder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataVisualizer {
  gap: 4.000000000000607px;
}
.timelineBuilder {
  gap: var(--gap-9xs);
}
.listItem14,
.listItem15,
.listItem16 {
  width: 619.4px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.listItem15,
.listItem16 {
  width: 1190.5px;
}
.listItem16 {
  width: 475.7px;
}
.p,
.p1 {
  margin: 0;
}
.listItemContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
}
.listItem17,
.listItem18,
.listItem19,
.listItem20 {
  width: 624.9px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.listItem18,
.listItem19,
.listItem20 {
  width: 570.6px;
}
.listItem19,
.listItem20 {
  width: 487.3px;
}
.listItem20 {
  width: 342.6px;
}
.shapeCreator {
  flex: 1;
  flex-direction: column;
  gap: 4.00000000000052px;
  min-width: 785px;
  max-width: 100%;
}
.delayNode,
.listItem5,
.shapeCreator,
.sortingLogic {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.delayNode {
  width: 1236.6px;
  flex-direction: row;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: 6.699999999999818px;
  max-width: 100%;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-500);
}
.listItem5,
.sortingLogic {
  flex-direction: column;
}
.listItem5 {
  align-self: stretch;
  border-top: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  padding: 20.200000000000728px 0 23.899999999999636px;
  gap: 4.099999999998545px;
  max-width: 100%;
  z-index: 1;
  margin-top: -0.1px;
}
.sortingLogic {
  width: 1258px;
}
.b4 {
  width: 134.5px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b4,
.div3,
.listItem21 {
  box-sizing: border-box;
  display: flex;
}
.div3 {
  width: 478px;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  max-width: 100%;
  padding-right: var(--padding-xl);
}
.listItem21 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.299999999999272px 0 20.100000000000364px;
  gap: var(--gap-9xs);
}
.connectionNode,
.divcommonDetailContent,
.divprddelivery {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divcommonDetailContent {
  border-top: 1px solid var(--color-darkslategray-200);
  overflow-x: auto;
  flex-direction: column;
  justify-content: flex-start;
  /*padding: 40.20000000000073px 0 0;*/
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
}
.connectionNode,
.divprddelivery {
  flex-direction: row;
}
.divprddelivery {
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  /*padding: 0 var(--padding-xs);*/
  gap: var(--gap-xl);
}
.connectionNode {
  align-self: stretch;
  justify-content: flex-start;
  /*padding: 0 0 var(--padding-300xl);*/
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1600px) {
  .divprddelivery {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .shapeCreator {
    min-width: 100%;
  }
  .delayNode {
    flex-wrap: wrap;
  }
  .connectionNode {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .shippingReturn {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .divcommonDetailContent {
    box-sizing: border-box;
  }
  .connectionNode {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .shippingReturn {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .divcommonDetailContent {
    box-sizing: border-box;
  }
}
