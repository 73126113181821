.detail {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  flex-shrink: 0;
}
.itemLink {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-mid);
  background-color: var(--color-darkslategray-500);
  width: 76.1px;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.itemLink:hover {
  background-color: var(--color-dimgray-200);
}
.review {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.itemLink1,
.outlierNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemLink1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) 16.600000000000364px var(--padding-smi)
  var(--padding-mid);
  background-color: var(--color-white);
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
}
.itemLink1:hover {
  background-color: var(--color-gainsboro-200);
}
.outlierNode {
  width: 168.6px;
  gap: 7.899999999999636px;
}
.clusterNode,
.icon {
  width: 600px;
  max-width: 100%;
}
.clusterNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.icon {
  height: 10160px;
  position: relative;
  object-fit: cover;
}
.listItem,
.prddetail {
  display: flex;
  align-items: center;
}
.prddetail {
  align-self: stretch;
  background: linear-gradient(
          180deg,
          rgba(216, 221, 229, 0.4),
          rgba(196, 205, 191, 0.4)
  );
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-25xl) 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 83px;
  max-width: 100%;
}
.listItem {
  width: 38.4px;
  height: 30.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  justify-content: center;
  flex-shrink: 0;
}
.c121412,
.c1214121,
.p,
.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7 {
  margin: 0;
}
.c121412Container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  color: var(--color-lightgray);
  text-align: left;
}
.listItemLinkParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5px;
  max-width: 100%;
}
.childNodeB,
.childNodeBInner,
.productPageSerummistset {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.childNodeBInner {
  flex-direction: row;
  padding: 0 var(--padding-20xl);
  max-width: 100%;
}
.childNodeB,
.productPageSerummistset {
  flex-direction: column;
}
.childNodeB {
  align-self: stretch;
  padding: 0 0 2021.8999999999996px;
  gap: var(--gap-10xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
.productPageSerummistset {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: var(--gap-111xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .childNodeB {
    padding-bottom: 361px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 65px 130px;
  }
}
@media screen and (max-width: 900px) {
  .prddetail {
    gap: 41px 83px;
    padding-right: var(--padding-3xl);
    box-sizing: border-box;
  }
  .childNodeB {
    padding-bottom: 235px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 32px 130px;
  }
}
@media screen and (max-width: 450px) {
  .prddetail {
    gap: 21px 83px;
  }
  .childNodeB {
    padding-bottom: 153px;
    box-sizing: border-box;
  }
}


.thumbIcon {
  height: 1050px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 585px;
}
.discountChild {
  height: 40px;
  width: 60px;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: none;
}
.onYangOncheon {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 45.6px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #383c35;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.discount {
  width: 60px;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-smi) 0 var(--padding-mini);
  box-sizing: border-box;
  text-align: center;
}
.div,
.div1,
.heading4 {
  position: relative;
  letter-spacing: -0.16px;
}
.heading4 {
  margin: 0;
  width: 218.8px;
  font-size: var(--font-size-21xl);
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.div,
.div1 {
  line-height: 45.6px;
  white-space: nowrap;
}
.div {
  text-decoration: line-through;
}
.div1 {
  height: 42px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.onYangOncheonSwimSpaBody {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
  color: var(--color-darkslategray-200);
}
.cartButton,
.onYangOncheonSwimSpaBody,
.parent,
.productSetText {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xl);
}
.cartButton,
.productSetText {
  align-self: stretch;
}
.productSetText {
  flex-direction: row;
  padding: 0 0 0 var(--padding-9xs);
  font-size: var(--font-size-19xl);
  color: var(--color-darkslategray-300);
}
.cartButton {
  flex-direction: column;
  gap: var(--gap-6xs);
}
.ml {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
}
.cousinNodes {
  width: 281.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 140px;
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.h3 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
}
.aVL,
.bSPTree {
  position: absolute;
}
.bSPTree {
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.aVL {
  margin: 0;
  top: 0;
  left: 9px;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.rTree {
  height: 56px;
  flex: 1;
  position: relative;
}
.redBlackTree {
  margin-right: 12px;
  position: relative;
  line-height: 56px;
}
.rTreeChild {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.h31 {
  margin: 0;
  position: absolute;
  top: 0;
  left: 8px;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.rTree1 {
  height: 56px;
  flex: 1;
  position: relative;
}
.binaryTree,
.kDTree,
.quadtree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.kDTree {
  width: 101px;
  justify-content: flex-start;
  gap: var(--gap-xl-5);
}
.binaryTree,
.quadtree {
  max-width: 100%;
}
.quadtree {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.binaryTree {
  width: 626px;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.strong0,
.total {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
}
.total {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.strong0 {
  text-align: right;
}
.chatFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-11xl);
  font-family: var(--font-cormorant);
}
.balancedTree,
.skewedTree {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.skewedTree {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xl);
}
.balancedTree {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-8xs) 0 var(--padding-base);
  box-sizing: border-box;
  font-family: var(--font-apple-sd-gothic-neo);
}
.cart1 {
  width: 50px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-200);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.cart {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-white);
  flex: 0.8704;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 201px;
}
.cart:hover {
  background-color: var(--color-gainsboro-200);
}
.buy,
.buy1,
.cart {
  display: flex;
  justify-content: center;
}
.buy1 {
  width: 41.5px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.buy {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-darkslategray-200);
  align-self: stretch;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
}
.buy:hover {
  background-color: var(--color-dimgray-300);
}
.binarySearchTree,
.chatItemLabel,
.monFriText {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatItemLabel {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 201px;
}
.binarySearchTree,
.monFriText {
  flex-direction: row;
  max-width: 100%;
}
.monFriText {
  flex: 1;
  gap: 42.30000000000018px;
}
.binarySearchTree {
  align-self: stretch;
  padding: 0 0 0 var(--padding-xs);
  box-sizing: border-box;
}
.descendantNodes {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-20xl);
}
.ancestorNodes,
.childNodes,
.descendantNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.ancestorNodes {
  width: 671.7px;
  flex-direction: column;
  padding: var(--padding-90xl) 0 0;
  box-sizing: border-box;
  min-width: 671.6999999999998px;
}
.childNodes {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-mini);
}
.detail,
.parentNode {
  font-family: var(--font-cormorant);
}
.parentNode {
  width: 1716.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-46xl) 117px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
}
.detail {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  color: var(--color-white);
  text-align: center;
  flex-shrink: 0;
}
.itemLink {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-mid);
  background-color: var(--color-darkslategray-500);
  width: 76.1px;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.itemLink:hover {
  background-color: var(--color-dimgray-200);
}
.review {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.itemLink1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) 16.600000000000364px var(--padding-smi)
    var(--padding-mid);
  background-color: var(--color-white);
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  justify-content: flex-start;
}
.itemLink1:hover {
  background-color: var(--color-gainsboro-200);
}
.chatListItemMonFri,
.itemLink1,
.itemLinkParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.itemLinkParent {
  width: 168.6px;
  justify-content: flex-start;
  gap: 7.899999999999636px;
}
.chatListItemMonFri {
  width: 576px;
  justify-content: center;
  max-width: 100%;
}
.icon {
  width: 50%; /* 이미지의 크기 조정, 필요에 따라 조정 */
  height: auto; /* 높이는 자동으로 설정하여 비율 유지 */
  margin: 20px auto; /* 상하 20px, 좌우 자동으로 마진 설정하여 중앙 정렬 */
  align-self: stretch;
  /*height: 12162px;*/
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.icosahedronTree {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.icosahedronTree {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-base);
}
.listItem {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 15px;
  font-weight: 600;
  flex-shrink: 0;
}
.itemLinkDetail {
  width: 118.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl);
  box-sizing: border-box;
}
.c121412,
.p,
.p1,
.p2,
.p3 {
  margin: 0;
}
.c121412Container {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
}
.inputCollectors,
.productPageSerum,
.siblingNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.inputCollectors {
  flex-direction: row;
  padding: 0 var(--padding-20xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-lightgray);
}
.productPageSerum,
.siblingNodes {
  flex-direction: column;
}
.siblingNodes {
  align-self: stretch;
  /*padding: 0 0 2175px;*/
  gap: var(--gap-sm);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
.productPageSerum {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: var(--gap-111xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1600px) {
  .ancestorNodes {
    flex: 1;
  }
  .childNodes {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .parentNode {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-57xl);
    box-sizing: border-box;
  }
  .siblingNodes {
    padding-bottom: 252px;
    box-sizing: border-box;
  }
  .productPageSerum {
    gap: 65px 130px;
  }
}
@media screen and (max-width: 900px) {
  .thumbIcon {
    min-width: 100%;
  }
  .heading4 {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-11xl);
    line-height: 36px;
  }
  .strong0,
  .total {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }
  .monFriText {
    flex-wrap: wrap;
    gap: 42.30000000000018px 21px;
  }
  .descendantNodes {
    gap: 19px 39px;
  }
  .ancestorNodes {
    padding-top: var(--padding-52xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .parentNode {
    padding-bottom: var(--padding-30xl);
    box-sizing: border-box;
  }
  .siblingNodes {
    padding-bottom: 164px;
    box-sizing: border-box;
  }
  .productPageSerum {
    gap: 32px 130px;
  }
}
@media screen and (max-width: 450px) {
  .onYangOncheon {
    font-size: var(--font-size-lg);
    line-height: 36px;
  }
  .heading4 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-4xl);
    line-height: 27px;
  }
  .aVL,
  .h3,
  .h31,
  .redBlackTree {
    font-size: var(--font-size-lg);
    line-height: 45px;
  }
  .quadtree {
    flex-wrap: wrap;
  }
  .strong0,
  .total {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }
  .cart1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
  .cart {
    flex: 1;
  }
  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
}
