.icon {
  align-self: stretch;
  height: 47px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}
.leafNode {
  width: 334px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  max-width: 100%;
}
.hea5 {
  position: fixed;
  color: black;
  top: 0;
  right: 20px;
  z-index: 1;
  font-size: medium;
  font-family: var(--font-cormorant)
}
.hea6 {
  position: fixed;
  color: black;
  top: 22px;
  right: 20px;
  z-index: 1;
  font-size: medium;
  font-family: var(--font-cormorant)
}
.branchNode,
.join,
.leafNode {
  display: flex;
  box-sizing: border-box;
}
.branchNode {
  width: 1905px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 0 var(--padding-xl);
  max-width: 100%;
  gap: var(--gap-xl);
}
.join {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0.0999999999985448px;
  gap: 200px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .branchNode {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1150px) {
  .join {
    gap: 100px 0;
  }
}
@media screen and (max-width: 800px) {
  .join {
    gap: 50px 0;
  }
}
