.cartempty {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  box-sizing: border-box;
  gap: 183px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1025px) {
  .cartempty {
    gap: 91px 0;
  }
}
@media screen and (max-width: 750px) {
  .cartempty {
    gap: 46px 0;
  }
}
