.order {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.siblingOnceRemovedNodes {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  z-index: 1;
}
.ancestorOnceRemovedNodes {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px 0;
}
.h2 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.siblingTwiceRemovedNodes {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  z-index: 1;
}
.auntTwiceRemovedNodes,
.uncleTwiceRemovedNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.auntTwiceRemovedNodes {
  flex: 1;
  flex-direction: column;
}
.uncleTwiceRemovedNodes {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-4xs);
  box-sizing: border-box;
  flex-shrink: 0;
}
.cousinOnceRemovedTwiceNode,
.descendantTwiceRemovedNodes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.descendantTwiceRemovedNodes {
  align-self: stretch;
  align-items: flex-end;
  gap: 13.8px 0;
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
.cousinOnceRemovedTwiceNode {
  flex: 1;
  align-items: flex-start;
  gap: 22.2px 0;
  min-width: 528px;
}
.h21 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.ancestorFiveTimesRemovedNo {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  z-index: 1;
}
.auntFourTimesRemovedNodes,
.uncleFourTimesRemovedNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.auntFourTimesRemovedNodes {
  flex: 1;
  flex-direction: column;
  gap: 22.5px 0;
}
.uncleFourTimesRemovedNodes {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
}
.ancestorSixTimesRemovedNod {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-darkslategray-200);
  z-index: 1;
}
.cousinFiveTimesRemovedNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.div {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.auntFiveTimesRemovedNodes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 9px;
}
.frameChild {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.auntFiveTimesRemovedNodesInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.div1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.auntFiveTimesRemovedNodes1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 10px;
}
.siblingFiveTimesRemovedNod,
.uncleFiveTimesRemovedNodes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.uncleFiveTimesRemovedNodes {
  gap: 0 68px;
}
.siblingFiveTimesRemovedNod {
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.conditionChecker,
.div2 {
  position: relative;
  z-index: 1;
}
.div2 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.conditionChecker {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-gainsboro-300);
}
.loopControl {
  height: 52px;
  width: 425px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.uncleSixTimesRemovedNodes {
  width: 613px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.div3,
.inputProcessor {
  position: relative;
  z-index: 1;
}
.div3 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.inputProcessor {
  height: 50px;
  flex: 1;
  background-color: var(--color-gainsboro-300);
  min-width: 193px;
}
.outputHandlerChild {
  height: 50px;
  width: 114px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  display: none;
}
.heading4 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.16px;
  line-height: 31.2px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-200);
  text-align: right;
  z-index: 2;
}
.logicGate,
.outputHandler {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.outputHandler {
  cursor: pointer;
  border: 1px solid var(--color-lightgray);
  padding: var(--padding-4xs) var(--padding-19xl) var(--padding-4xs)
    var(--padding-20xl);
  background-color: var(--color-white);
  border-radius: var(--br-xl);
  z-index: 1;
}
.dataBackup:hover,
.outputHandler:hover {
  background-color: var(--color-gainsboro-200);
  border: 1px solid var(--color-darkgray);
  box-sizing: border-box;
}
.logicGate {
  align-self: stretch;
  gap: 0 14px;
}
.dataAggregator,
.parent {
  display: flex;
  max-width: 100%;
}
.dataAggregator {
  width: 425px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-11xs);
  box-sizing: border-box;
}
.parent {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.uncleSixTimesRemovedNodes1 {
  width: 614px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.div4,
.frameItem {
  position: relative;
  z-index: 1;
}
.div4 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.frameItem {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-gainsboro-300);
}
.group,
.rectangleWrapper {
  display: flex;
  max-width: 100%;
}
.rectangleWrapper {
  height: 52px;
  width: 427px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-11xs);
  box-sizing: border-box;
}
.group {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.uncleSixTimesRemovedNodes2 {
  width: 615px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.div5,
.frameInner {
  position: relative;
  z-index: 1;
}
.div5 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.frameInner {
  align-self: stretch;
  height: 50px;
  background-color: var(--color-gainsboro-300);
}
.container,
.rectangleContainer,
.uncleSixTimesRemovedNodes3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleContainer {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 278px;
  max-width: 100%;
}
.container,
.uncleSixTimesRemovedNodes3 {
  flex-direction: row;
}
.container {
  flex: 1;
  gap: 0 42px;
  max-width: 100%;
}
.uncleSixTimesRemovedNodes3 {
  width: 615px;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
}
.div6,
.div7 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.errorHandler,
.parallelProcessor {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.parallelProcessor {
  flex-direction: row;
  padding: 0 var(--padding-11xs);
}
.errorHandler {
  flex-direction: column;
  gap: 6px 0;
}
.dataSorter {
  align-self: stretch;
  height: 50px;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataSplitter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 77px;
}
.dataSplitter1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.dataSplitterChild {
  align-self: stretch;
  height: 50px;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataSplitter2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 77px;
}
.dataSplitter3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.dataMergerChild {
  height: 50px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  min-width: 77px;
  z-index: 1;
}
.dataMerger {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 11.75px;
}
.dataDeleter {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataUpdater {
  height: 52px;
  width: 131px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}
.dataProtector {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.dataUpdaterChild {
  align-self: stretch;
  height: 50px;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataUpdater1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) var(--padding-2xs) 0 0;
  box-sizing: border-box;
  min-width: 97px;
}
.dataUpdaterItem {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataUpdater2 {
  height: 52px;
  width: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}
.dataCreator {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-11xs) 0 0;
  gap: 0 3px;
  font-size: var(--font-size-3xl);
}
.dataCreator,
.dataTransformer,
.functionCaller,
.variableHolder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataTransformer {
  flex: 1;
  flex-direction: column;
  gap: 6px 0;
  min-width: 277px;
  max-width: 100%;
}
.functionCaller,
.variableHolder {
  flex-direction: row;
}
.variableHolder {
  flex: 1;
  gap: 0 73px;
  max-width: 100%;
}
.functionCaller {
  width: 615px;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.div8 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.dataVisualizer,
.dataVisualizer1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--color-gainsboro-300);
  z-index: 1;
}
.dataAnalyzer {
  align-self: stretch;
  height: 111px;
  flex-direction: column;
  gap: 11px 0;
}
.dataAnalyzer,
.dataLinker,
.dataReceiver,
.dataSharer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataLinker {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 278px;
  max-width: 100%;
}
.dataReceiver,
.dataSharer {
  flex-direction: row;
}
.dataReceiver {
  flex: 1;
  gap: 0 45px;
  max-width: 100%;
}
.dataSharer {
  width: 615px;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
}
.siblingSixTimesRemovedNode {
  width: 615px;
  overflow-x: auto;
  gap: 6px 0;
}
.descendantFiveTimesRemoved,
.siblingFourTimesRemovedNod,
.siblingSixTimesRemovedNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.descendantFiveTimesRemoved {
  gap: 11px 0;
  font-size: var(--font-size-6xl);
}
.siblingFourTimesRemovedNod {
  align-self: stretch;
  gap: 47.5px 0;
}
.h22 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-21xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.dataEncoder {
  align-self: stretch;
  height: 1px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
}
.dataEncoder,
.dataVerifierChild,
.div9 {
  position: relative;
  z-index: 1;
}
.div9 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.dataVerifierChild {
  height: 50px;
  width: 297px;
}
.dataBackupChild {
  height: 50px;
  width: 114px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  display: none;
}
.heading41 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.16px;
  line-height: 31.2px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-200);
  text-align: right;
  z-index: 2;
}
.dataBackup {
  cursor: pointer;
  border: 1px solid var(--color-lightgray);
  padding: var(--padding-4xs) var(--padding-19xl) var(--padding-4xs)
    var(--padding-20xl);
  background-color: var(--color-white);
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}
.div10 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.dataTransfer {
  flex-direction: row;
  padding: 0 0 0 var(--padding-5xl);
}
.dataSecurity,
.dataTransfer,
.dataVerifier {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataSecurity {
  flex-direction: column;
  gap: 7px 0;
  min-width: 128px;
}
.dataVerifier {
  align-self: stretch;
  flex-direction: row;
  gap: 0 16px;
}
.dataEncryptor,
.dataValidator {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.dataValidator {
  width: 441px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-mini);
}
.dataEncryptor {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.p,
.p1,
.p2 {
  margin: 0;
}
.div11 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  z-index: 2;
}
.dataDecoder,
.dataQueue {
  display: flex;
  justify-content: flex-start;
}
.dataQueue {
  height: 60px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
  margin-top: -15px;
  text-align: right;
  font-size: var(--font-size-mini);
}
.dataDecoder {
  width: 628px;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
}
.dataExpander,
.dataPool {
  align-self: stretch;
  box-sizing: border-box;
}
.dataExpander {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs-5);
  gap: 31.5px 0;
  max-width: 100%;
}
.dataPool {
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gray-200);
  z-index: 1;
}
.div12,
.heading42 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.heading42 {
  line-height: 31.2px;
}
.dataMatrix,
.dataStream {
  display: flex;
  align-items: flex-start;
}
.dataMatrix {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-2xs) 0 0;
  text-align: right;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-200);
}
.dataStream {
  width: 623px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.dataHeap {
  align-self: stretch;
  flex-direction: column;
  gap: 22px 0;
}
.dataHeap,
.dataRetriever,
.dataStorage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.dataRetriever {
  flex: 1;
  flex-direction: column;
  gap: 22.5px 0;
}
.dataStorage {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-27xl) var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
}
.h23 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.dateHandler,
.h23,
.switchNode {
  position: relative;
  z-index: 1;
}
.dateHandler {
  align-self: stretch;
  height: 1px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
}
.switchNode {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--color-darkslategray-200);
}
.caseNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.div13 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.elseNode,
.ifNode {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.elseNode {
  flex-direction: row;
  gap: 0 15px;
}
.ifNode {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.outputNode {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro-500);
  z-index: 1;
}
.inputNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.div14 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.variableNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 15px;
}
.assignmentNode {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro-500);
  z-index: 1;
}
.functionCallNode,
.loopNode {
  display: flex;
  align-items: flex-start;
}
.functionCallNode {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.loopNode {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.parallelNode {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro-500);
  z-index: 1;
}
.sequenceNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
}
.div15 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.compositeNode,
.functionNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 15px;
}
.functionNode {
  flex: 1;
  flex-direction: column;
  gap: 9px 0;
  min-width: 266px;
  max-width: 100%;
}
.div16 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.geometryTransform {
  flex: 1;
  flex-direction: row;
  gap: 0 15px;
}
.geometryTransform,
.imageProcessor,
.textParser {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.imageProcessor {
  width: 564px;
  flex-direction: row;
  padding: 0 var(--padding-4xs);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
}
.textParser {
  align-self: stretch;
  flex-direction: column;
  gap: 21.5px 0;
}
.joinNode {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gray-200);
  box-sizing: border-box;
  z-index: 1;
}
.conditionSplitter,
.valueFilter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.valueFilter {
  flex: 1;
  flex-direction: column;
  gap: 30px 0;
}
.conditionSplitter {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-19xl) var(--padding-12xs);
  box-sizing: border-box;
}
.h24,
.stackNode {
  position: relative;
  z-index: 1;
}
.h24 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.stackNode {
  align-self: stretch;
  height: 1px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
}
.dictionaryNode {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-sm-5);
  gap: 22.5px 0;
  font-size: var(--font-size-21xl);
}
.div17,
.div18,
.div19 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.hashTableNode,
.heapNode {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.hashTableNode {
  flex-direction: row;
  padding: 0 var(--padding-12xs);
}
.heapNode {
  flex-direction: column;
  gap: 2px 0;
}
.heading43,
.heading44,
.heading45 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 31.2px;
  z-index: 1;
}
.forestNode {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 27px 0;
}
.priorityQueueNode,
.treeNode {
  display: flex;
  align-items: flex-start;
}
.treeNode {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
  text-align: right;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-200);
}
.priorityQueueNode {
  width: 624px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.clusterNode {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--color-gray-200);
  box-sizing: border-box;
  z-index: 1;
}
.queueNode {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px 0;
  max-width: 100%;
}
.div20,
.heading46 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.heading46 {
  line-height: 31.2px;
  white-space: nowrap;
}
.vertexNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) 0 0;
  text-align: right;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-200);
}
.arrayNode,
.componentNode,
.matrixNode {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.componentNode {
  width: 623px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.arrayNode,
.matrixNode {
  justify-content: flex-start;
}
.arrayNode {
  flex: 1;
  flex-direction: column;
  gap: 22px 0;
}
.matrixNode {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-24xl) var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
}
.dijkstraNode {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-darkslategray-500);
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.div21 {
  position: absolute;
  top: 14px;
  left: 266px;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-white);
  text-align: left;
  z-index: 2;
}
.shortestPathNode {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 83px;
  flex: 1;
  position: relative;
  max-width: 100%;
}
.cycleDetector,
.descendantFourTimesRemoved {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.cycleDetector {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.descendantFourTimesRemoved {
  width: 644px;
  flex-direction: column;
  gap: 89px 0;
  min-width: 644px;
}
.auntOnceRemovedNodes {
  flex: 1;
  flex-direction: row;
  gap: 0 72px;
}
.auntOnceRemovedNodes,
.cousinOnceRemovedNodes,
.uncleOnceRemovedNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.uncleOnceRemovedNodes {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--font-size-21xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.cousinOnceRemovedNodes {
  width: 1536px;
  flex-direction: column;
  gap: 79px 0;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1275px) {
  .descendantFourTimesRemoved {
    flex: 1;
  }
  .auntOnceRemovedNodes {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .h2,
  .order {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .h2 {
    font-size: var(--font-size-13xl);
  }
  .cousinOnceRemovedTwiceNode {
    min-width: 100%;
  }
  .h21 {
    font-size: var(--font-size-13xl);
    line-height: 44px;
  }
  .siblingFourTimesRemovedNod {
    gap: 24px 0;
  }
  .h22 {
    font-size: var(--font-size-13xl);
    line-height: 44px;
  }
  .dataSecurity {
    flex: 1;
  }
  .dataEncryptor,
  .dataVerifier {
    flex-wrap: wrap;
  }
  .dataExpander {
    gap: 16px 0;
  }
  .h23 {
    font-size: var(--font-size-13xl);
    line-height: 44px;
  }
  .geometryTransform {
    flex-wrap: wrap;
  }
  .h24 {
    font-size: var(--font-size-13xl);
    line-height: 44px;
  }
  .descendantFourTimesRemoved {
    gap: 44px 0;
    min-width: 100%;
  }
  .auntOnceRemovedNodes {
    gap: 0 36px;
  }
  .cousinOnceRemovedNodes {
    gap: 39px 0;
  }
}
@media screen and (max-width: 450px) {
  .h2,
  .h21,
  .order {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .h2,
  .h21 {
    font-size: var(--font-size-5xl);
  }
  .div,
  .div1 {
    font-size: var(--font-size-xl);
    line-height: 44px;
  }
  .uncleFiveTimesRemovedNodes {
    gap: 0 34px;
  }
  .div2,
  .div3 {
    font-size: var(--font-size-xl);
    line-height: 44px;
  }
  .heading4 {
    font-size: var(--font-size-base);
    line-height: 25px;
  }
  .logicGate {
    flex-wrap: wrap;
  }
  .dataSplitter1,
  .dataSplitter3,
  .div4,
  .div5,
  .div6,
  .div7 {
    font-size: var(--font-size-xl);
    line-height: 44px;
  }
  .dataSplitter1,
  .dataSplitter3 {
    width: 100%;
    height: 11px;
  }
  .dataMerger {
    flex-wrap: wrap;
  }
  .dataProtector {
    font-size: var(--font-size-lg);
    line-height: 44px;
  }
  .dataCreator {
    flex-wrap: wrap;
  }
  .div8 {
    font-size: var(--font-size-xl);
    line-height: 44px;
  }
  .h22 {
    font-size: var(--font-size-5xl);
    line-height: 33px;
  }
  .div9 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .heading41 {
    font-size: var(--font-size-base);
    line-height: 25px;
  }
  .div12 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .heading42 {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .dataStream {
    flex-wrap: wrap;
  }
  .dataStorage {
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .h23 {
    font-size: var(--font-size-5xl);
    line-height: 33px;
  }
  .div13,
  .div14 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .loopNode {
    flex-wrap: wrap;
  }
  .div15,
  .div16 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .h24 {
    font-size: var(--font-size-5xl);
    line-height: 33px;
  }
  .div17,
  .div18,
  .div19 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .heading43,
  .heading44,
  .heading45 {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .priorityQueueNode {
    flex-wrap: wrap;
  }
  .div20 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .heading46 {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .componentNode {
    flex-wrap: wrap;
  }
  .matrixNode {
    padding-bottom: var(--padding-9xl);
    box-sizing: border-box;
  }
  .div21 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .descendantFourTimesRemoved {
    gap: 22px 0;
  }
  .auntOnceRemovedNodes {
    gap: 0 18px;
  }
  .cousinOnceRemovedNodes {
    gap: 20px 0;
  }
}
