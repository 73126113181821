.listItem {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 15px;
  font-weight: 600;
  flex-shrink: 0;
}
.detailReviewFrame {
  width: 40.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.c121412,
.p,
.p1,
.p2,
.p3 {
  margin: 0;
}
.c121412Container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  color: var(--color-lightgray);
  text-align: left;
}
.cartFrame {
  flex-direction: column;
  gap: var(--gap-sm);
}
.cartFrame,
.edge,
.inputProcessor,
.productPageFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.productPageFrame {
  flex-direction: row;
  padding: 0 var(--padding-20xl);
  box-sizing: border-box;
}
.edge,
.inputProcessor {
  align-self: stretch;
  flex-direction: column;
}
.edge {
  gap: var(--gap-26xl);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
.inputProcessor {
  /*padding: 0 0 2519px;*/
  box-sizing: border-box;
  gap: var(--gap-111xl);
}
.listItem1,
.listItem2,
.shippingReturn {
  position: relative;
  letter-spacing: -0.16px;
}
.shippingReturn {
  margin: 0;
  font-size: inherit;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
}
.listItem1,
.listItem2 {
  line-height: 25.6px;
}
.listItem2 {
  align-self: stretch;
  height: 39px;
  font-size: var(--font-size-sm);
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.audioHandler {
  width: 168.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 4.100000000000364px 0;
  box-sizing: border-box;
  gap: 4.099999999998545px;
}
.b {
  width: 82.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b,
.div,
.listItem3 {
  box-sizing: border-box;
  display: flex;
}
.div {
  width: 553.8px;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  max-width: 100%;
  padding-right: var(--padding-xl);
}
.listItem3 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
}
.b1 {
  width: 82.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b1,
.div1,
.listItem4 {
  box-sizing: border-box;
  display: flex;
}
.div1 {
  width: 677.4px;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  max-width: 100%;
  padding-right: var(--padding-xl);
}
.listItem4 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
}
.b2,
.div2 {
  position: relative;
  letter-spacing: -0.16px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.b2 {
  width: 82.6px;
  line-height: 25.6px;
  display: flex;
  box-sizing: border-box;
}
.div2 {
  width: 560.2px;
  font-size: var(--font-size-sm);
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  max-width: 100%;
}
.b3,
.div2,
.listItem5 {
  display: flex;
  box-sizing: border-box;
}
.listItem5 {
  align-self: stretch;
  border-top: 1px solid var(--color-gainsboro-400);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.200000000000728px 0 20.700000000000728px;
  gap: 4.099999999998545px;
  max-width: 100%;
}
.b3 {
  width: 101.2px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.listItem10,
.listItem7,
.listItem8,
.listItem9 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  white-space: nowrap;
}
.dataLoader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 22.399999999999636px;
  gap: 4.0000000000009095px;
}
.listItem11,
.listItem12,
.listItem13,
.listItem14 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  white-space: nowrap;
}
.dataFilter,
.dataRouter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataFilter {
  gap: 4.000000000000607px;
}
.dataRouter {
  gap: var(--gap-9xs);
}
.listItem15,
.listItem16,
.listItem17 {
  width: 619.4px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.listItem16,
.listItem17 {
  width: 1190.5px;
}
.listItem17 {
  width: 475.7px;
}
.p4,
.p5 {
  margin: 0;
}
.listItemContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
}
.listItem18,
.listItem19,
.listItem20,
.listItem21 {
  width: 624.9px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.listItem19,
.listItem20,
.listItem21 {
  width: 570.6px;
}
.listItem20,
.listItem21 {
  width: 487.3px;
}
.listItem21 {
  width: 342.6px;
}
.dataTransformer {
  flex: 1;
  flex-direction: column;
  gap: 4.00000000000052px;
  min-width: 785px;
  max-width: 100%;
}
.dataSplitter,
.dataStorage,
.dataTransformer,
.listItem6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dataSplitter {
  width: 1236.6px;
  flex-direction: row;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: 6.700000000000728px;
  max-width: 100%;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-500);
}
.dataStorage,
.listItem6 {
  flex-direction: column;
}
.listItem6 {
  align-self: stretch;
  border-top: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  padding: 20.200000000000728px 0 23.899999999999636px;
  gap: 4.099999999998545px;
  max-width: 100%;
  z-index: 1;
  margin-top: -0.1px;
}
.dataStorage {
  width: 1258px;
}
.b4,
.div3 {
  width: 134.5px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.div3 {
  width: 478px;
  font-size: var(--font-size-sm);
  line-height: 22.4px;
  color: var(--color-darkslategray-500);
  max-width: 100%;
}
.divcommonDetailContent,
.listItem22 {
  flex-direction: column;
  justify-content: flex-start;
}
.listItem22 {
  width: 1258px;
  border-top: 1px solid var(--color-gainsboro-400);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 20.299999999999272px 0 20.100000000000364px;
  gap: var(--gap-9xs);
}
.divcommonDetailContent {
  border-top: 1px solid var(--color-darkslategray-200);
  overflow-x: auto;
  padding: 40.20000000000073px 0 0;
  max-width: 100%;
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
}
.divcommonDetailContent,
.divprddelivery,
.productPageMistset {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.divprddelivery {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--padding-xs);
  max-width: 100%;
  gap: var(--gap-xl);
}
.productPageMistset {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: 516px;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1600px) {
  .divprddelivery {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .inputProcessor {
    gap: 65px 130px;
    padding-bottom: var(--padding-431xl);
    box-sizing: border-box;
  }
  .dataTransformer {
    min-width: 100%;
  }
  .dataSplitter {
    flex-wrap: wrap;
  }
  .productPageMistset {
    gap: 258px 516px;
  }
}
@media screen and (max-width: 900px) {
  .edge {
    gap: 22px 45px;
  }
  .inputProcessor {
    gap: 32px 130px;
    padding-bottom: var(--padding-273xl);
    box-sizing: border-box;
  }
  .shippingReturn {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .divcommonDetailContent {
    padding-top: var(--padding-7xl);
    box-sizing: border-box;
  }
  .productPageMistset {
    gap: 129px 516px;
  }
}
@media screen and (max-width: 450px) {
  .inputProcessor {
    padding-bottom: var(--padding-171xl);
    box-sizing: border-box;
  }
  .shippingReturn {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .divcommonDetailContent {
    padding-top: var(--padding-xl);
    box-sizing: border-box;
  }
}
.detail {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  flex-shrink: 0;
}
.itemLink {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-mid);
  background-color: var(--color-darkslategray-500);
  width: 76.1px;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.itemLink:hover {
  background-color: var(--color-dimgray-200);
}
.review {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.itemLink1,
.outlierNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemLink1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) 16.600000000000364px var(--padding-smi)
  var(--padding-mid);
  background-color: var(--color-white);
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
}
.itemLink1:hover {
  background-color: var(--color-gainsboro-200);
}
.outlierNode {
  width: 168.6px;
  gap: 7.899999999999636px;
}
.clusterNode,
.icon {
  width: 600px;
  max-width: 100%;
}
.clusterNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.icon {
  height: 10160px;
  position: relative;
  object-fit: cover;
}
.listItem,
.prddetail {
  display: flex;
  align-items: center;
}
.prddetail {
  align-self: stretch;
  background: linear-gradient(
          180deg,
          rgba(216, 221, 229, 0.4),
          rgba(196, 205, 191, 0.4)
  );
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-25xl) 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 83px;
  max-width: 100%;
}
.listItem {
  width: 38.4px;
  height: 30.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  justify-content: center;
  flex-shrink: 0;
}
.c121412,
.c1214121,
.p,
.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7 {
  margin: 0;
}
.c121412Container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  color: var(--color-lightgray);
  text-align: left;
}
.listItemLinkParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5px;
  max-width: 100%;
}
.childNodeB,
.childNodeBInner,
.productPageSerummistset {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.childNodeBInner {
  flex-direction: row;
  padding: 0 var(--padding-20xl);
  max-width: 100%;
}
.childNodeB,
.productPageSerummistset {
  flex-direction: column;
}
.childNodeB {
  align-self: stretch;
  padding: 0 0 2021.8999999999996px;
  gap: var(--gap-10xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
.productPageSerummistset {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: var(--gap-111xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .childNodeB {
    padding-bottom: 361px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 65px 130px;
  }
}
@media screen and (max-width: 900px) {
  .prddetail {
    gap: 41px 83px;
    padding-right: var(--padding-3xl);
    box-sizing: border-box;
  }
  .childNodeB {
    padding-bottom: 235px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 32px 130px;
  }
}
@media screen and (max-width: 450px) {
  .prddetail {
    gap: 21px 83px;
  }
  .childNodeB {
    padding-bottom: 153px;
    box-sizing: border-box;
  }
}
