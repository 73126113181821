.thumbIcon {
  height: 1050px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 585px;
}
.frameWrapper,
.thumbParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.thumbParent {
  flex: 1;
  gap: var(--gap-xl);
}
.frameWrapper {
  width: 1717.7px;
  padding: 0 var(--padding-46xl) var(--padding-66xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1400px) {
  .thumbParent {
    flex-wrap: wrap;
  }
  .frameWrapper {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1200px) {
  .frameWrapper {
    padding-bottom: var(--padding-36xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .thumbIcon {
    min-width: 100%;
  }
  .frameWrapper {
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }
}
