.privacyPolicy1 {
  position: absolute;
  top: 269px;
  left: 879px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.divcontainerChild {
  position: absolute;
  top: 331.8px;
  left: 882px;
  max-height: 100%;
  width: 974px;
}
.p {
  margin: 0;
}
.o {
  white-space: pre-wrap;
}
.cookie,
.o,
.o1,
.o10,
.o11,
.o12,
.o13,
.o2,
.o3,
.o4,
.o5,
.o6,
.o7,
.o8,
.o9,
.oo,
.p1,
.p2,
.privacykisaorkr {
  margin: 0;
}
.wwwspogokr1 {
  text-decoration: underline;
}
.wwwspogokr {
  color: inherit;
  white-space: pre-wrap;
}
.span {
  text-decoration: underline;
}
.a {
  color: inherit;
  white-space: pre-wrap;
}
.wwwspogokr0234802000 {
  margin: 0;
}
.wwwctrcgokr1 {
  text-decoration: underline;
}
.wwwctrcgokr {
  color: inherit;
  white-space: pre-wrap;
}
.wwwctrcgokr182 {
  margin: 0;
}
.oContainer {
  position: absolute;
  top: 355px;
  left: 884px;
  font-size: var(--font-size-3xs);
  letter-spacing: -0.16px;
  line-height: 23px;
  font-family: var(--font-apple-sd-gothic-neo);
}
.divcontainer {
  position: absolute;
  top: 0;
  left: calc(50% - 960px);
  width: 1920px;
  height: 2379px;
}
.privacyPolicy {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 3094px;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
