.discountChild {
  height: 40px;
  width: 60px;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: none;
}
.div {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 45.6px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #383c35;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.discount {
  width: 60px;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 var(--padding-base);
  box-sizing: border-box;
  text-align: center;
}
.div1,
.div2,
.heading4 {
  position: relative;
  letter-spacing: -0.16px;
}
.heading4 {
  margin: 0;
  width: 218.8px;
  font-size: var(--font-size-21xl);
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.div1,
.div2 {
  line-height: 42px;
  white-space: nowrap;
}
.div1 {
  text-decoration: line-through;
}
.div2 {
  color: var(--color-darkslategray-200);
}
.discountParent,
.frameContainer,
.parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.discountParent,
.frameContainer {
  align-self: stretch;
}
.frameContainer {
  flex-direction: row;
  padding: 0 0 0 var(--padding-9xs);
  font-size: var(--font-size-19xl);
  color: var(--color-darkslategray-300);
}
.discountParent {
  flex-direction: column;
  gap: var(--gap-5xs);
}
.ml {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
}
.frameGroup {
  width: 281.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-93xl);
  box-sizing: border-box;
  gap: var(--gap-6xl);
}
.div3 {
  position: relative;
  line-height: 56px;
}
.frameChild {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.div4 {
  position: absolute;
  top: 0;
  left: 9px;
  line-height: 56px;
  z-index: 1;
}
.rectangleParent {
  height: 56px;
  flex: 1;
  position: relative;
}
.div5 {
  margin-right: 12px;
  position: relative;
  line-height: 56px;
}
.frameItem {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.div6 {
  position: absolute;
  top: 0;
  left: 8px;
  line-height: 56px;
  z-index: 1;
}
.rectangleGroup {
  height: 56px;
  flex: 1;
  position: relative;
}
.frameParent2,
.frameWrapper1,
.group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frameParent2 {
  width: 101px;
  justify-content: flex-start;
  gap: var(--gap-xl-5);
}
.frameWrapper1,
.group {
  max-width: 100%;
}
.group {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frameWrapper1 {
  width: 634px;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
  box-sizing: border-box;
}
.strong0,
.total {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
}
.total {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.strong0 {
  text-align: right;
}
.totalParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-11xl);
  font-family: var(--font-cormorant);
}
.frameDiv,
.frameParent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameParent1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-sm);
}
.frameDiv {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-8xs) 0 var(--padding-xs);
  box-sizing: border-box;
  font-family: var(--font-apple-sd-gothic-neo);
}
.cart1 {
  width: 50px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-200);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.buy1,
.cart,
.cart1 {
  display: flex;
  justify-content: center;
}
.cart {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-white);
  flex: 0.8704;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 201px;
}
.cart:hover {
  background-color: var(--color-gainsboro-200);
}
.buy1 {
  width: 41.5px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.buy,
.buyWrapper {
  display: flex;
  align-items: flex-start;
}
.buy {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-darkslategray-200);
  align-self: stretch;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  justify-content: center;
}
.buy:hover {
  background-color: var(--color-dimgray-300);
}
.buyWrapper {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 201px;
}
.cartParent {
  flex: 1;
  flex-direction: row;
  gap: 42.30000000000018px;
}
.cartParent,
.frameParent,
.frameWrapper,
.frameWrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameWrapper2 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-5xs);
  box-sizing: border-box;
}
.frameParent,
.frameWrapper {
  flex-direction: column;
}
.frameParent {
  align-self: stretch;
  gap: var(--gap-20xl);
}
.frameWrapper {
  width: 667.7px;
  padding: var(--padding-90xl) 0 0;
  box-sizing: border-box;
  min-width: 667.6999999999998px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1400px) {
  .frameWrapper {
    flex: 1;
  }
}
@media screen and (max-width: 1200px) {
  .frameWrapper {
    min-width: 100%;
  }
}
@media screen and (max-width: 825px) {
  .heading4 {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .div1,
  .div2 {
    font-size: var(--font-size-11xl);
    line-height: 36px;
  }
  .strong0,
  .total {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }
  .cartParent {
    flex-wrap: wrap;
    gap: 42.30000000000018px 21px;
  }
  .frameParent {
    gap: 19px 39px;
  }
  .frameWrapper {
    padding-top: var(--padding-52xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .div {
    font-size: var(--font-size-lg);
    line-height: 36px;
  }
  .heading4 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .div1,
  .div2 {
    font-size: var(--font-size-4xl);
    line-height: 27px;
  }
  .div3,
  .div4,
  .div5,
  .div6 {
    font-size: var(--font-size-lg);
    line-height: 45px;
  }
  .group {
    flex-wrap: wrap;
  }
  .strong0,
  .total {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }
  .cart1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
  .cart {
    flex: 1;
  }
  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
}
