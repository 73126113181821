.detail {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  flex-shrink: 0;
}
.itemLink {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-mid);
  background-color: var(--color-darkslategray-500);
  width: 76.1px;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.itemLink:hover {
  background-color: var(--color-dimgray-200);
}
.review {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.16px;
  line-height: 20px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.itemLink1,
.outlierNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemLink1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) 16.600000000000364px var(--padding-smi)
  var(--padding-mid);
  background-color: var(--color-white);
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
}
.itemLink1:hover {
  background-color: var(--color-gainsboro-200);
}
.outlierNode {
  width: 168.6px;
  gap: 7.899999999999636px;
}
.clusterNode,
.icon {
  width: 600px;
  max-width: 100%;
}
.clusterNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.icon {
  height: 10160px;
  position: relative;
  object-fit: cover;
}
.listItem,
.prddetail {
  display: flex;
  align-items: center;
}
.prddetail {
  align-self: stretch;
  background: linear-gradient(
          180deg,
          rgba(216, 221, 229, 0.4),
          rgba(196, 205, 191, 0.4)
  );
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-25xl) 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 83px;
  max-width: 100%;
}
.listItem {
  width: 38.4px;
  height: 30.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  justify-content: center;
  flex-shrink: 0;
}
.c121412,
.c1214121,
.p,
.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7 {
  margin: 0;
}
.c121412Container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  color: var(--color-lightgray);
  text-align: left;
}
.listItemLinkParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5px;
  max-width: 100%;
}
.childNodeB,
.childNodeBInner,
.productPageSerummistset {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.childNodeBInner {
  flex-direction: row;
  padding: 0 var(--padding-20xl);
  max-width: 100%;
}
.childNodeB,
.productPageSerummistset {
  flex-direction: column;
}
.childNodeB {
  align-self: stretch;
  padding: 0 0 2021.8999999999996px;
  gap: var(--gap-10xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
.productPageSerummistset {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: var(--padding-3xl) 0 0.2999999999992724px;
  gap: var(--gap-111xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .childNodeB {
    padding-bottom: 361px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 65px 130px;
  }
}
@media screen and (max-width: 900px) {
  .prddetail {
    gap: 41px 83px;
    padding-right: var(--padding-3xl);
    box-sizing: border-box;
  }
  .childNodeB {
    padding-bottom: 235px;
    box-sizing: border-box;
  }
  .productPageSerummistset {
    gap: 32px 130px;
  }
}
@media screen and (max-width: 450px) {
  .prddetail {
    gap: 21px 83px;
  }
  .childNodeB {
    padding-bottom: 153px;
    box-sizing: border-box;
  }
}
