.headerList {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  z-index: 1;
}
.itemFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.headerList1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  z-index: 1;
}
.itemFrame1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  z-index: 1;
}
.headerList3 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.listContainer {
  margin: 0;
  width: 482px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
.headerList4 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  z-index: 1;
}
.divxansProductIcon,
.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 441px;
  object-fit: cover;
}
.icon {
  top: 24px;
  left: 32px;
  width: 120px;
  height: 122.6px;
  cursor: pointer;
  z-index: 1;
}
.headerListItemLink,
.instagram2Icon {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
.headerListItemLink {
  top: 16px;
  left: 1757px;
  width: 62px;
  height: 63px;
  object-fit: contain;
  cursor: pointer;
}
.instagram2Icon {
  top: 24px;
  left: 1537px;
  width: 29px;
  height: 29px;
}
.productImageFrame {
  height: 441px;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: -61px;
  left: 0;
}
.header {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: var(--padding-sm) var(--padding-mid-5) var(--padding-297xl);
  box-sizing: border-box;
  position: relative;
  gap: 0 361px;
  max-width: 100%;
  text-align: right;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1425px) {
  .header {
    gap: 0 180px;
  }
}
@media screen and (max-width: 950px) {
  .headerList4 {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .header {
    flex-wrap: wrap;
    gap: 0 90px;
  }
}
@media screen and (max-width: 450px) {
  .headerList4 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .header {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-186xl);
    box-sizing: border-box;
  }
}
