.cart {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.productInfoCellFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-xl);
  font-size: var(--font-size-31xl);
}
.input {
  width: 112px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  height: 57px;
  position: relative;
  line-height: 20px;
  color: var(--color-darkslategray-700);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
}
.headerRowCell {
  flex: 1;
  border-top: 1px solid var(--color-darkslategray-1000);
  border-bottom: 1px solid var(--color-darkslategray-1000);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-16xl);
  min-width: 112px;
  max-width: 100%;
}
.input1 {
  width: 52px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  height: 36px;
  position: relative;
  line-height: 20px;
  color: var(--color-darkslategray-700);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.headerRowCell1 {
  width: 277px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-36xl) var(--padding-10xl);
}
.input2 {
  width: 104px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  height: 36px;
  position: relative;
  line-height: 20px;
  color: var(--color-darkslategray-700);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.headerRowCell2 {
  width: 276px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-31xl) var(--padding-10xl);
}
.input3 {
  width: 112px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  height: 36px;
  position: relative;
  line-height: 20px;
  color: var(--color-darkslategray-800);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.headerRowCell3,
.orderAmountCellFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerRowCell3 {
  width: 214px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  padding: var(--padding-9xl) var(--padding-10xl) var(--padding-10xl);
}
.orderAmountCellFrame {
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 100%;
  row-gap: 20px;
}
.h3 {
  margin: 0;
  width: 301px;
  position: relative;
  font-size: inherit;
  line-height: 20px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bodyRowData,
.footerFrame {
  align-self: stretch;
  flex-direction: row;
  box-sizing: border-box;
}
.bodyRowData {
  flex: 1;
  border-bottom: 1px solid var(--color-darkslategray-800);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-153xl-5) var(--padding-xl) var(--padding-153xl)
    var(--padding-3xl);
  max-width: 100%;
}
.footerFrame {
  height: 659px;
  justify-content: flex-start;
  padding: 0 0 var(--padding-29xl);
  text-align: center;
  color: var(--color-gray-300);
  font-family: var(--font-apple-sd-gothic-neo);
}
.bodyDataFrame,
.cartInfoRowFrame,
.footerFrame {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.cartInfoRowFrame {
  width: 1533.1px;
  flex-direction: column;
  justify-content: flex-start;
}
.bodyDataFrame {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1025px) {
  .cart {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .h3,
  .input,
  .input1,
  .input2,
  .input3 {
    font-size: var(--font-size-5xl);
    line-height: 16px;
  }
}
@media screen and (max-width: 750px) {
  .footerFrame {
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .cart {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .h3,
  .input,
  .input1,
  .input2,
  .input3 {
    font-size: var(--font-size-lg);
    line-height: 12px;
  }
  .bodyRowData {
    padding-top: var(--padding-93xl);
    padding-bottom: var(--padding-93xl);
    box-sizing: border-box;
  }
}
