.listItem, .listItem1 {
  flex: 1; /* 모든 아이템에 동일한 공간 배정 */
  margin: 0 15px; /* 아이템 간 일정한 간격 유지 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 30px); /* 2개 아이템에 대한 너비 계산 */

}
.newYearEdition,
.newYearEdition1 {
  font-size: 28px;
  text-align: center; /* 이미지 중앙 기준 정렬 */
}

.itemImgIcon, .itemImgIcon1 {
  max-width: 100%; /* 최대 너비를 100%로 설정 */
  height: auto; /* 이미지 높이 자동 조정 */
  object-fit: cover; /* 컨테이너를 꽉 채우도록 */
}
.best {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
}
.itemImgIcon {
  align-self: stretch;
  height: 600px;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.div,
.link {
  align-items: center;
}
.div {
  height: 29px;
  width: 537.5px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 31.2px;
  display: flex;
  flex-shrink: 0;
  white-space: nowrap;
  max-width: 603%;
}
.link {
  width: 89.2px;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0;
  box-sizing: border-box;
  text-align: left;
}
.div1,
.div2,
.newYearEdition {
  position: relative;
  letter-spacing: -0.16px;
}
.newYearEdition {
  align-self: stretch;
  line-height: 33.6px;
}

.div1,
.div2 {
  white-space: nowrap;
}
.div1 {
  flex: 1;
  text-decoration: line-through;
  line-height: 42px;
}
.div2 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.chatItemFrame,
.footerFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatItemFrame {
  width: 96px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.footerFrame {
  width: 223.5px;
  flex-direction: row;
}
.priceTag,
.spraySetSection {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.priceTag {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
}
.spraySetSection {
  flex-direction: column;
  justify-content: flex-start;
  gap: 1px 0;
  font-size: var(--font-size-9xl);
}
.ml {
  margin: 0;
}
.sectionListContainer1 {
  width: 100%;
}
.sectionListContainer {
  height: 59px;
  width: 334px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: calc(100% - 0px);
}
.helpSectionLink {
  align-self: stretch;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-500);
  font-family: var(--font-apple-sd-gothic-neo);
}
.divitemDescription {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-12xs) 0 0;
  gap: 9px 0;
  flex-shrink: 0;
  cursor: pointer;
}
.divitemDescription,
.listItem,
.productImageFrame {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.productImageFrame {
  align-self: stretch;
  height: 123px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 13.199999999999818px 0 var(--padding-smi);
}
.listItem {
  margin-left: -160px;
  width: 624px;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 var(--padding-xs);
  gap: 8px 0;
  cursor: pointer;
  font-size: var(--font-size-7xl);
}
.itemImgIcon1 {
  align-self: stretch;
  height: 600px;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.div3,
.div4,
.newYearEdition1 {
  position: relative;
  letter-spacing: -0.16px;
}
.newYearEdition1 {
  align-self: stretch;
  line-height: 33.6px;
}
.div3,
.div4 {
  white-space: nowrap;
}
.div3 {
  flex: 1;
  text-decoration: line-through;
  line-height: 42px;
}
.div4 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.frameWrapper,
.parent,
.wrapper {
  display: flex;
  align-items: flex-start;
}
.wrapper {
  width: 98px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.frameWrapper,
.parent {
  flex-direction: row;
}
.parent {
  width: 225.5px;
  justify-content: flex-start;
}
.frameWrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
}
.newYearEditionTheSprayParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px 0;
}
.ml1,
.mlWrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.ml1 {
  height: 41.6px;
  width: 357.3px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  flex-shrink: 0;
}
.mlWrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  font-family: var(--font-apple-sd-gothic-neo);
}
.frameParent,
.listItemInner {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frameParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 1px 0;
}
.productImageFrame,
.listItemInner {
  margin-top: 10px;
  text-align: center; /* 이미지 중앙 기준 정렬 */
}
.listItemInner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 13.199999999999818px 0 var(--padding-smi);
  box-sizing: border-box;
}
.div5 {
  height: 32px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 31.2px;
  display: inline-block;
  white-space: nowrap;
}
.link1,
.listItem1 {
  box-sizing: border-box;
  max-width: 100%;
}
.link1 {
  width: 537.5px;
  height: 31px;
  background-color: var(--color-white);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-12xs) 0;
  font-size: var(--font-size-7xl);
}
.listItem1 {
  width: 624px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-xs) 2.399999999999636px;
  gap: 9px 0;
  cursor: pointer;
  z-index: 1;
  margin-left: -0.5px;
}
.itemImgIcon2 {
  height: 600px;
  width: 600px;
  position: relative;
  border-radius: var(--br-xs);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
  max-width: 100%;
  z-index: 0;
}
.link2,
.newYearEdition2 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.newYearEdition2 {
  height: 31px;
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.link2 {
  width: 371px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-12xs) 0;
  box-sizing: border-box;
}
.div6 {
  height: 29px;
  width: 370.8px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 31.2px;
  display: flex;
  flex-shrink: 0;
  white-space: nowrap;
}
.div6,
.divitemDescription1,
.link3,
.listItem2 {
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.link3 {
  width: 537.5px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-12xs) 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-7xl);
}
.divitemDescription1,
.listItem2 {
  display: none;
}
.divitemDescription1 {
  margin: 0 !important;
  position: absolute;
  top: 624px;
  left: calc(50% - 268.7px);
  flex-direction: column;
  z-index: 1;
}
.itemsContainer {
  display: flex;
  justify-content: flex-start; /* 두 번째 아이템을 중앙에 위치시키기 위해 변경 */
  align-items: center;
  gap: 40px; /* 두 아이템 사이 간격 */
  margin-top: 20px;
  margin-left: -80px;
}

.listItem2 {
  height: 600px;
  width: 624px;
  flex-direction: row;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  position: relative;
  gap: 0 10px;
  z-index: 3;
  text-align: left;
}
.best1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 1920px;
}
.ownerInfoFrame {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--font-size-9xl);
}
.bestSection,
.footerTextFrame,
.ownerInfoFrame {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.bestSection {
  /*width: 1617px;*/
  flex-direction: column;
  /*justify-content: flex-start;*/
  gap: 34px 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 500px;
}
.footerTextFrame {
  padding: 0 var(--padding-xl) var(--padding-455xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  width: 100%;
  position: relative; /* 상대적 위치 설정 */
  padding-top: 80px; /* "Best" 레이블을 위한 공간 확보 */
  padding-left: 200px;
}
@media screen and (max-width: 1900px) {
  .best1 {
    max-width: 100%;
  }
}
@media screen and (max-width: 1425px) {
  .best1 {
    max-width: 100%;
  }
  .footerTextFrame {
    padding-bottom: var(--padding-289xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .best {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .best1 {
    max-width: 100%;
  }
  .bestSection {
    gap: 17px 0;
  }
  .footerTextFrame {
    padding-bottom: var(--padding-181xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .best {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .div {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .newYearEdition {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div1,
  .div2 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
  .newYearEdition1 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div3,
  .div4,
  .div5 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
  .div5 {
    line-height: 25px;
  }
  .newYearEdition2 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div6 {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .best1 {
    max-width: 100%;
  }
}
