.icon {
  width: 334px;
  flex: 1;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
  z-index: 2;
}
.contentFrame {
  height: 63px;
  width: 733px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.headerList,
.headerList1 {
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  z-index: 2;
}
.headerList3 {
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.loginSVGFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.antistressText,
.productListItemLink {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frameWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-4xs);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.productListItemLink {
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.antistressText {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-4xs);
  box-sizing: border-box;
}
.antiStress {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33px;
  font-weight: 500;
}
.p,
.p1 {
  margin: 0;
}
.div {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
}
.p2,
.p3,
.p4 {
  margin: 0;
}
.div1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
}
.instagramFrame,
.naturalIngredientsText,
.relaxationFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.naturalIngredientsText {
  gap: 10px 0;
  font-size: var(--font-size-xl);
}
.instagramFrame,
.relaxationFrame {
  max-width: 100%;
}
.relaxationFrame {
  gap: 17.2px 0;
  font-size: var(--font-size-14xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.instagramFrame {
  width: 587px;
  gap: 143px 0;
}
.headerList4,
.headerListItemLink {
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.headerList4 {
  letter-spacing: -0.16px;
  line-height: 48px;
}
.cartCountText,
.innerFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.cartCountText {
  /*justify-content: flex-start;*/
  /*gap: 0 12px;*/
  text-align: right;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: space-between; /* Space between items */
  gap: var(--gap-xl); /* Same gap as productListItemLink */
  padding: 0 0 0 var(--padding-4xs); /* Same padding as antistressText */
}
.innerFrame {
  align-self: stretch;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1575px) {
  .innerFrame {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 825px) {
  .antiStress {
    font-size: var(--font-size-7xl);
    line-height: 26px;
  }
  .instagramFrame {
    gap: 71px 0;
  }
  .headerList4 {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
}
@media screen and (max-width: 450px) {
  .antiStress,
  .div,
  .div1 {
    font-size: var(--font-size-xl);
    line-height: 20px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-base);
  }
  .instagramFrame {
    gap: 36px 0;
  }
  .headerList4 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
}
