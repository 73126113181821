.frameChild {
    align-self: stretch;
    width: 1533px;
    position: relative;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-silver);
    border-bottom: 1px solid var(--color-silver);
    box-sizing: border-box;
    display: none;
    max-width: 100%;
}
.heading4,
.thumbIcon {
    position: relative;
    z-index: 1;
}
.thumbIcon {
    height: 233.3px;
    width: 200px;
    object-fit: cover;
}
.heading4 {
    margin: 0;
    font-size: inherit;
    letter-spacing: -0.16px;
    line-height: 34px;
    font-weight: 400;
    font-family: inherit;
}
.heading4TheSpraySetWrapper,
.monFriList {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
}
.heading4TheSpraySetWrapper {
    flex-direction: column;
    align-items: flex-start;
    min-width: 416px;
    min-height: 133px;
    flex-shrink: 0;
}
.monFriList {
    flex-direction: row;
    align-items: flex-end;
    row-gap: 20px;
}
.monFriListWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 15.700000000000728px;
    box-sizing: border-box;
    min-width: 616px;
    max-width: 100%;
}
.frameItem {
    width: 1px;
    flex: 1;
    position: relative;
    border-right: 1px solid var(--color-silver);
    box-sizing: border-box;
    z-index: 1;
}
.lineWrapper {
    height: 260.5px;
    width: 52px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.frameInner {
    position: absolute;
    top: 13px;
    left: 0;
    background-color: var(--color-gainsboro-300);
    width: 26px;
    height: 30px;
    z-index: 1;
}
.div {
    position: absolute;
    top: 0;
    left: 9px;
    line-height: 56px;
    display: inline-block;
    min-width: 10px;
    z-index: 2;
}
.rectangleGroup {
    align-self: stretch;
    height: 56px;
    position: relative;
}
.frameWrapper {
    height: 160px;
    width: 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-5xs) 0 0;
    box-sizing: border-box;
}
.div1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 12px;
    z-index: 1;
}
.wrapper {
    height: 145px;
    width: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-4xs) 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: var(--font-size-14xl);
    color: var(--color-darkslategray-500);
    font-family: var(--font-alegreya);
}
.rectangleDiv {
    position: absolute;
    top: 13px;
    left: 0;
    background-color: var(--color-gainsboro-300);
    width: 26px;
    height: 30px;
    z-index: 1;
}
.div2 {
    position: absolute;
    top: 0;
    left: 8px;
    line-height: 56px;
    display: inline-block;
    min-width: 12px;
    z-index: 2;
}
.rectangleContainer {
    width: 26px;
    height: 56px;
    position: relative;
}
.frameContainer {
    height: 160px;
    width: 79px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.lineDiv {
    width: 1px;
    flex: 1;
    position: relative;
    border-right: 1px solid var(--color-silver);
    box-sizing: border-box;
    z-index: 1;
}
.lineContainer {
    height: 260.5px;
    width: 71px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.heading41 {
    position: relative;
    letter-spacing: -0.16px;
    line-height: 34px;
    display: inline-block;
    min-width: 99px;
    z-index: 1;
}
.heading4TheSpraySetContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 111px;
    min-height: 160px;
    font-size: var(--font-size-21xl);
    font-family: var(--font-cormorant);
}
.frameChild1 {
    width: 1px;
    flex: 1;
    position: relative;
    border-right: 1px solid var(--color-silver);
    box-sizing: border-box;
    z-index: 1;
}
.lineFrame {
    height: 265px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 var(--padding-8xs-5);
    box-sizing: border-box;
}
.h3 {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 20px;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    min-width: 52px;
    z-index: 1;
}
.container,
.frameParent {
    display: flex;
    justify-content: flex-start;
}
.container {
    height: 146px;
    width: 52px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    font-size: var(--font-size-11xl);
    color: var(--color-darkslategray-700);
}
.frameParent {
    width: 642px;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-4xl);
    min-width: 642px;
    max-width: 100%;
    font-size: var(--font-size-3xl);
    font-family: var(--font-apple-sd-gothic-neo);
}
.onSpringFooter,
.rectangleParent {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 100%;
}
.rectangleParent {
    flex: 1;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-silver);
    border-bottom: 1px solid var(--color-silver);
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 var(--padding-19xl) 0 0;
    gap: var(--gap-xl);
}
.onSpringFooter {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 var(--padding-8xs);
    text-align: left;
    font-size: var(--font-size-21xl);
    color: var(--color-darkslategray-200);
    font-family: var(--font-cormorant);
}
@media screen and (max-width: 1350px) {
    .frameParent,
    .monFriListWrapper {
        flex: 1;
    }
    .rectangleParent {
        flex-wrap: wrap;
        padding: var(--padding-xl);
        box-sizing: border-box;
    }
}
@media screen and (max-width: 800px) {
    .thumbIcon {
        flex: 1;
    }
    .heading4 {
        font-size: var(--font-size-13xl);
        line-height: 27px;
    }
    .heading4TheSpraySetWrapper {
        flex: 1;
        min-width: 100%;
        min-height: auto;
    }
    .monFriList {
        flex-wrap: wrap;
    }
    .monFriListWrapper {
        min-width: 100%;
    }
    .div1 {
        font-size: var(--font-size-7xl);
        line-height: 16px;
    }
    .heading41 {
        font-size: var(--font-size-13xl);
        line-height: 27px;
    }
    .heading4TheSpraySetContainer {
        min-height: auto;
    }
    .h3 {
        font-size: var(--font-size-5xl);
        line-height: 16px;
    }
    .frameParent {
        flex-wrap: wrap;
        min-width: 100%;
    }
}
@media screen and (max-width: 450px) {
    .heading4 {
        font-size: var(--font-size-5xl);
        line-height: 20px;
    }
    .div {
        font-size: var(--font-size-lg);
        line-height: 45px;
    }
    .div1 {
        font-size: var(--font-size-xl);
        line-height: 12px;
    }
    .div2 {
        font-size: var(--font-size-lg);
        line-height: 45px;
    }
    .heading41 {
        font-size: var(--font-size-5xl);
        line-height: 20px;
    }
    .h3 {
        font-size: var(--font-size-lg);
        line-height: 12px;
    }
}
