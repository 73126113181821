.relaxation {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 500;
  font-family: inherit;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}
.div,
.div1,
.div2,
.divactive {
  height: 3px;
  width: 26px;
  position: relative;
  border-radius: var(--br-12xs);
  z-index: 2;
}
.div,
.div1 {
  background-color: var(--color-gray-500);
  cursor: pointer;
}
.div2,
.divactive {
  background-color: var(--color-white);
}
.div2 {
  background-color: var(--color-gray-500);
  cursor: pointer;
}
.activeTab,
.divParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.divParent {
  justify-content: flex-start;
  gap: 0 6px;
}
.activeTab {
  align-self: stretch;
  justify-content: flex-end;
}
.loginSVG,
.relaxationSection {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.relaxationSection {
  width: 865px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 190px 0;
}
.loginSVG {
  width: 1241px;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-56xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1275px) {
  .relaxationSection {
    gap: 95px 0;
  }
}
@media screen and (max-width: 825px) {
  .relaxation {
    font-size: var(--font-size-41xl);
    line-height: 44px;
  }
}
@media screen and (max-width: 450px) {
  .relaxation {
    font-size: var(--font-size-26xl);
    line-height: 33px;
  }
  .relaxationSection {
    gap: 47px 0;
  }
}
