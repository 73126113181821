.help {
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  font-weight: 300;
}
.footerFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-1071xl);
}
.onSpringInc {
  margin: 0;
  font-weight: 300;
}
.address744Ho,
.emailInfoonSpringcokr,
.ownerBryanKim,
.sujeongGuSeongnamSiGyeon,
.tel07044036450 {
  margin: 0;
}
.onSpring,
.onSpringIncContainer {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.onSpringIncContainer {
  align-self: stretch;
}
.onSpring {
  font-weight: 300;
  color: var(--color-darkslategray-100);
}
.chatLinkList {
  width: 424.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 64.4px 0;
  max-width: 100%;
}
.listItem,
.listItem1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  white-space: nowrap;
  cursor: pointer;
}
.chatItemLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px 0;
}
.listItem2 {
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  font-weight: 300;
}
.p,
.p1 {
  margin: 0;
}
.listItemContainer {
  height: 53px;
  position: relative;
  font-size: var(--font-size-mid);
  letter-spacing: -0.16px;
  line-height: 26.4px;
  display: flex;
  align-items: center;
}
.chat {
  gap: 1px 0;
}
.chat,
.chatList,
.monFriSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.monFriSection {
  gap: 20px 0;
}
.chatList {
  padding: var(--padding-sm) 0 0;
}
.listItem3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  cursor: pointer;
}
.infoonSpringcokr,
.p2 {
  margin: 0;
}
.listItemContainer2 {
  line-break: anywhere;
}
.listItemContainer1 {
  height: 53px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  display: flex;
  align-items: center;
}
.lunchTime12001330,
.monFri10001700 {
  margin: 0;
}
.listItemContainer3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.listItemLink11ChatGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8.6px 0;
}
.p3,
.p4,
.qa {
  margin: 0;
}
.listItemContainer4 {
  position: relative;
  font-size: var(--font-size-mini);
  letter-spacing: -0.16px;
  line-height: 23px;
}
.frameParent {
  gap: 15px 0;
}
.companyInfoInner,
.frameParent,
.listItemLink11ChatParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.listItemLink11ChatParent {
  gap: 9px 0;
}
.companyInfoInner {
  padding: var(--padding-11xs) 0 0;
}
.companyInfo,
.footer {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.companyInfo {
  width: 1703px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-xl);
}
.footer {
  align-self: stretch;
  background: linear-gradient(180deg, #fff, #e3e8e1);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-138xl) var(--padding-5xl) 25.300000000000185px;
  box-sizing: border-box;
  gap: 13px 0;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-dimgray);
  font-family: var(--font-apple-sd-gothic-neo);
}
@media screen and (max-width: 1200px) {
  .footerFrame {
    padding-left: var(--padding-526xl);
    box-sizing: border-box;
  }
  .companyInfo {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 825px) {
  .footerFrame {
    padding-left: var(--padding-253xl);
    box-sizing: border-box;
  }
  .footer {
    padding-top: var(--padding-83xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .help {
    font-size: var(--font-size-lg);
    line-height: 21px;
  }
  .footerFrame {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .onSpring,
  .onSpringIncContainer {
    font-size: var(--font-size-base);
    line-height: 21px;
  }
  .chatLinkList {
    gap: 32px 0;
  }
  .listItem,
  .listItem1,
  .listItem2,
  .listItem3,
  .listItemContainer1,
  .listItemContainer3 {
    font-size: var(--font-size-base);
    line-height: 21px;
  }
}
