.childNodes,
.div,
.join {
  display: flex;
  box-sizing: border-box;
}
.childNodes {
  width: 918.5px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) var(--padding-60xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
.div,
.join {
  position: relative;
  align-items: center;
  padding-right: var(--padding-xl);
}
.join {
  margin: 0;
  width: 98.5px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.div {
  width: 64.4px;
  flex-shrink: 0;
}
.siblingOnceRemovedNodes,
.tableBodyRowDataIn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.tableBodyRowDataIn {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  padding: var(--padding-xl) 0 var(--padding-mid);
}
.siblingOnceRemovedNodes {
  align-self: stretch;
  padding: 0 0 8.599999999998545px;
  flex-shrink: 0;
}
.tableBody {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 14.4px;
}
.cousinOnceRemovedTwiceNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-3xs) 0;
  flex-shrink: 0;
  font-size: var(--font-size-xs);
  color: var(--color-darkslategray-500);
}
.div1 {
  width: 186.3px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.siblingOnceRemovedNodes1,
.tableBodyRowDataIn1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.tableBodyRowDataIn1 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  padding: var(--padding-xl) 0 var(--padding-mid);
}
.siblingOnceRemovedNodes1 {
  align-self: stretch;
  padding: 0 0 8.600000000000364px;
  flex-shrink: 0;
}
.tableBody1 {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 14.4px;
  flex-shrink: 0;
}
.descendantTwiceRemovedNodes {
  width: 318.1px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 9.99999999999818px 0;
  flex-shrink: 0;
  font-size: var(--font-size-xs);
  color: var(--color-darkslategray-500);
}
.descendantTwiceRemovedNodes,
.div2,
.tableBodyRowDataIn2 {
  display: flex;
  box-sizing: border-box;
}
.div2 {
  width: 112.7px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn2 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-mid);
  max-width: 100%;
}
.div3,
.siblingOnceRemovedNodes2 {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.siblingOnceRemovedNodes2 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 16.799999999999272px;
  max-width: 100%;
}
.div3 {
  width: 156.9px;
  position: relative;
  align-items: center;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn3 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  padding: var(--padding-xl) 0 var(--padding-mid);
}
.siblingOnceRemovedNodes3,
.tableBodyRowDataIn3,
.tableBodyRowDataIn4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.siblingOnceRemovedNodes3 {
  align-self: stretch;
  padding: 0 0 12.799999999999272px;
  flex-shrink: 0;
}
.tableBodyRowDataIn4 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: var(--color-gainsboro-700);
  height: 53px;
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  padding: var(--padding-xl) 0 var(--padding-mid);
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  min-width: 300px;
}
.hidden {
  display: none;
}

.div4 {
  flex: 1;
  position: relative;
  line-height: 54px;
  font-weight: 600;
  border: none;
  background-color: rgba(256, 256, 256, 0);
}
.tableBodyRowDataLi {
  width: 103.2px;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 19.299999999999272px var(--padding-12xs)
    var(--padding-2xl);
  box-sizing: border-box;
  white-space: nowrap;
}
.ancestorThriceRemovedNodes,
.div5 {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.ancestorThriceRemovedNodes {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  gap: 0 10px;
  max-width: 100%;
  text-align: center;
}
.div5 {
  width: 79.1px;
  position: relative;
  align-items: center;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn5 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-mid);
  flex-shrink: 0;
  color: var(--color-dimgray-100);
}
.div6 {
  width: 79.1px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn6 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-mid);
  flex-shrink: 0;
  color: var(--color-dimgray-100);
}
.tableBody2 {
  width: 92.8px;
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: -0.16px;
  line-height: 14.4px;
  color: var(--color-gray-100);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.siblingFourTimes {
  position: relative;
  line-height: 56px;
  font-weight: 600;
}
.directionDnsvgIcon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.cousinFourTimesRemovedNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.tableBody3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
  z-index: 1;
}
.auntFourTimesRemovedNodes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 5.1px;
}
.tableBodyRowDataOp,
.uncleFourTimesRemovedNodes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mid) 0 0;
}
.tableBodyRowDataOp {
  width: 316px;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.tableBody4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
  z-index: 1;
}
.tableBodyRowDataIn7,
.tableBodyRowDataIn8 {
  width: 316px;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
}
.tableBodyRowDataIn7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-mid) var(--padding-9xs);
  box-sizing: border-box;
}
.tableBodyRowDataIn8 {
  height: 56px;
  position: relative;
}
.div7 {
  position: relative;
  line-height: 56px;
  font-weight: 600;
}
.directionDnsvgIcon1 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.directionDnsvgWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.tableBody5 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
  z-index: 1;
}
.frameParent,
.tableBodyRowDataOp1,
.tableBodyRowDataOpInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  flex-direction: row;
  gap: 0 5.1px;
}
.tableBodyRowDataOp1,
.tableBodyRowDataOpInner {
  flex-direction: column;
  padding: var(--padding-mid) 0 0;
}
.tableBodyRowDataOp1 {
  width: 316px;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.tableBody6 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
  z-index: 1;
}
.tableBodyRowDataIn10,
.tableBodyRowDataIn9 {
  width: 316px;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
}
.tableBodyRowDataIn9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-mid) var(--padding-9xs);
  box-sizing: border-box;
}
.tableBodyRowDataIn10 {
  height: 56px;
  position: relative;
}
.ancestorFourTimesRemovedNo,
.uncleTwiceRemovedNodes {
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.ancestorFourTimesRemovedNo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px 7px;
  min-height: 124px;
}
.uncleTwiceRemovedNodes {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 13.299999999999272px;
  gap: 9.28px 0;
  max-width: 100%;
  color: var(--color-darkslategray-500);
}
.div8,
.tableBodyRowDataIn11,
.uncleTwiceRemovedNodes {
  display: flex;
  box-sizing: border-box;
}
.div8 {
  width: 64.4px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn11 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-mid);
  max-width: 100%;
}
.siblingOnceRemovedNodes4,
.tableBody7 {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.siblingOnceRemovedNodes4 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 11.100000000000364px;
  max-width: 100%;
}
.tableBody7 {
  width: 63.7px;
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: -0.16px;
  line-height: 14.4px;
  color: var(--color-darkslategray-500);
  align-items: center;
  padding-right: var(--padding-xl);
}
.tableBodyRowDataIn12 {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-gainsboro-400);
}
.cousinFiveTimesRemovedNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
}
.tableBody8 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
}
.auntFiveTimesRemovedNodes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 6px;
}
.tableBodyRowDataIn13 {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslategray-500);
}
.tableBodyRowDataInWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
}
.tableBody9 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
}
.auntFiveTimesRemovedNodes1,
.uncleFiveTimesRemovedNodes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 6px;
}
.uncleFiveTimesRemovedNodes {
  padding: 0 var(--padding-xl) 0 0;
  gap: 0 21.8px;
  flex-shrink: 0;
  color: var(--color-darkslategray-500);
}
.cousinOnceRemovedNodes,
.descendantOnceRemovedNodes {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.descendantOnceRemovedNodes {
  gap: 0.2px 0;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  font-family: var(--font-apple-sd-gothic-neo);
}
.cousinOnceRemovedNodes {
  gap: 28px 0;
}
.h3 {
  margin: 0;
  width: 106.1px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.divtitlearea {
  align-self: stretch;
  border-top: 1px solid var(--color-darkslategray-500);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16.299999999999272px 0 30.700000000000728px;
}
.tableBody10 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 14.4px;
  font-weight: 600;
  z-index: 1;
}
.tableBodyRowDataIn14 {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslategray-500);
}
.formInputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
}
.tableBody11 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
}
.listItemSet {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 6px;
}
.tableBodyRowDataIn15 {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-gainsboro-400);
}
.tableBodyRowDataInContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
}
.tableBody12 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 22px;
}
.listItemSet1 {
  justify-content: flex-start;
  gap: 0 6px;
}
.linkCollection,
.listItemSet1,
.tableBodyRowDataIn16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.linkCollection {
  justify-content: flex-start;
  gap: 0 21.9px;
}
.tableBodyRowDataIn16 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: var(--color-gainsboro-700);
  height: 56px;
  flex: 1;
  border-radius: var(--br-xs);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  justify-content: flex-end;
  padding: 14.399999999999636px 4.200000000000728px var(--padding-base);
  box-sizing: border-box;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-500);
  min-width: 236px;
}
.tableBody13 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  z-index: 1;
}
.tableBodyRowDataIn17 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 14.399999999999636px 4.200000000000728px var(--padding-base);
  box-sizing: border-box;
  min-width: 236px;
}
.tableBody14 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  z-index: 1;
}
.tableBodyRowDataIn18 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-700);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 14.399999999999636px 4.19999999999709px var(--padding-base);
  box-sizing: border-box;
  min-width: 236px;
}
.dataAggregator,
.imageHolder,
.progressBarSet {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.progressBarSet {
  flex-direction: row;
  gap: 0 0.55px;
}
.dataAggregator,
.imageHolder {
  flex-direction: column;
}
.imageHolder {
  gap: 8px 0;
  font-size: var(--font-size-base);
}
.dataAggregator {
  padding: 0 var(--padding-xl) 0 0;
  gap: 4.2px 0;
}
.div9,
.link {
  display: flex;
  justify-content: center;
}
.div9 {
  width: 88.5px;
  position: relative;
  line-height: 54px;
  font-weight: 600;
  align-items: center;
  flex-shrink: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);;
  color: #ffffff;
}
.link {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-darkslategray-500);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-12xs) var(--padding-xl);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
}
.cousinSixTimesRemovedNodes {
  align-self: stretch;
  gap: 44px 0;
  margin-top: -0.2px;
  font-size: var(--font-size-xs);
}
.cousinSixTimesRemovedNodes,
.siblingNodes,
.siblingSixTimesRemovedNode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.siblingSixTimesRemovedNode {
  align-self: stretch;
  font-size: var(--font-size-3xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.siblingNodes {
  width: 966px;
  gap: 60px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1150px) {
  .siblingNodes {
    gap: 30px 0;
  }
}
@media screen and (max-width: 800px) {
  .join {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .ancestorThriceRemovedNodes,
  .progressBarSet {
    flex-wrap: wrap;
  }
  .cousinSixTimesRemovedNodes {
    gap: 22px 0;
  }
}
@media screen and (max-width: 450px) {
  .join {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .h3 {
    font-size: var(--font-size-lg);
    line-height: 21px;
  }
}
