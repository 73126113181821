.headingTextFrame {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.emptyFrame {
  position: absolute;
  top: 0;
  left: 9px;
  line-height: 56px;
  z-index: 1;
}
.thumbnailFrame {
  height: 56px;
  width: 26px;
  position: relative;
}
.emptyFrame1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.emptyFrameWrapper {
  width: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-14xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-alegreya);
}
.frameChild {
  position: absolute;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.monFriFrame {
  position: absolute;
  top: 0;
  left: 8px;
  line-height: 56px;
  z-index: 1;
}
.rectangleParent {
  height: 56px;
  width: 26px;
  position: relative;
}
.headerRowCell {
  width: 278px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-right: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-86xl) var(--padding-57xl) var(--padding-87xl)
    var(--padding-56xl);
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
@media screen and (max-width: 750px) {
  .emptyFrame1 {
    font-size: var(--font-size-7xl);
    line-height: 16px;
  }
}
@media screen and (max-width: 450px) {
  .emptyFrame {
    font-size: var(--font-size-lg);
    line-height: 45px;
  }
  .emptyFrame1 {
    font-size: var(--font-size-xl);
    line-height: 12px;
  }
  .monFriFrame {
    font-size: var(--font-size-lg);
    line-height: 45px;
  }
  .headerRowCell {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
