.icon {
  height: 47px;
  width: 334px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.headerList,
.headerList1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList3 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.cartLink,
.noticeText {
  display: flex;
  justify-content: flex-start;
}
.noticeText {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.cartLink {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 67px;
  max-width: 100%;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}
.headerList4 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.deleteButton,
.loginLink {
  align-items: flex-start;
  justify-content: flex-start;
}
.deleteButton {
  display: flex;
  flex-direction: row;
  gap: 0 12px;
  text-align: right;
}
.loginLink {
  width: 914.5px;
  gap: 0 191px;
}
.csQnadetailInner,
.loginLink,
.parent {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.parent {
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
.csQnadetailInner {
  width: 1905px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-18xl) var(--padding-91xl) var(--padding-xl);
  box-sizing: border-box;
}
@media screen and (max-width: 1350px) {
  .cartLink {
    display: none;
  }
  .loginLink {
    width: 336.5px;
  }
}
@media screen and (max-width: 1125px) {
  .loginLink {
    gap: 0 95px;
  }
}
@media screen and (max-width: 800px) {
  .cartLink {
    gap: 0 33px;
  }
}
@media screen and (max-width: 450px) {
  .cartLink {
    gap: 0 17px;
  }
  .loginLink {
    gap: 0 48px;
  }
}
