.input {
    width: 112px;
    border: none;
    outline: none;
    font-family: var(--font-apple-sd-gothic-neo);
    font-size: var(--font-size-11xl);
    background-color: transparent;
    height: 36px;
    position: relative;
    line-height: 20px;
    color: var(--color-darkslategray-700);
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0;
}
.headerRowCell {
    flex: 1;
    border-top: 1px solid var(--color-darkslategray-1000);
    border-bottom: 1px solid var(--color-darkslategray-1000);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xl) var(--padding-16xl) var(--padding-10xl);
    min-width: 112px;
    max-width: 100%;
}
.div {
    height: 36px;
    width: 52px;
    position: relative;
    font-size: var(--font-size-11xl);
    line-height: 20px;
    font-family: var(--font-apple-sd-gothic-neo);
    color: var(--color-darkslategray-700);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 52px;
}
.headerRowCell1 {
    cursor: pointer;
    border: none;
    padding: var(--padding-9xl) var(--padding-xl) var(--padding-10xl);
    background-color: transparent;
    width: 278px;
    border-top: 1px solid var(--color-darkslategray-800);
    border-bottom: 1px solid var(--color-darkslategray-800);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.headerRowCell1:hover {
    background-color: var(--color-dimgray-400);
}
.div1 {
    height: 36px;
    width: 104px;
    position: relative;
    font-size: var(--font-size-11xl);
    line-height: 20px;
    font-family: var(--font-apple-sd-gothic-neo);
    color: var(--color-darkslategray-700);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 104px;
}
.headerRowCell2 {
    cursor: pointer;
    border: none;
    padding: var(--padding-9xl) var(--padding-xl) var(--padding-10xl);
    background-color: transparent;
    width: 288px;
    border-top: 1px solid var(--color-darkslategray-800);
    border-bottom: 1px solid var(--color-darkslategray-800);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.headerRowCell2:hover {
    background-color: var(--color-dimgray-400);
}
.div2 {
    height: 36px;
    flex: 1;
    position: relative;
    font-size: var(--font-size-11xl);
    line-height: 20px;
    font-family: var(--font-apple-sd-gothic-neo);
    color: var(--color-darkslategray-800);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 52px;
}
.headerRowCell3 {
    cursor: pointer;
    border: none;
    padding: var(--padding-9xl) var(--padding-11xl) var(--padding-10xl);
    background-color: transparent;
    width: 112px;
    border-top: 1px solid var(--color-darkslategray-800);
    border-bottom: 1px solid var(--color-darkslategray-800);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.headerRowCell3:hover {
    background-color: var(--color-dimgray-400);
}
.headerRowCellParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.6666666666666666px;
    max-width: 100%;
}
.frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    font-size: var(--font-size-21xl);
    color: var(--color-darkslategray-200);
}
.thumbnailCell {
    width: 1538px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-18xl);
    max-width: 100%;
}
.productInfoCell {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px var(--padding-xl) var(--padding-170xl) var(--padding-5xl);
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-31xl);
    color: var(--color-darkslategray-500);
    font-family: var(--font-cormorant);
}

@media screen and (max-width: 800px) {
    .input {
        font-size: var(--font-size-5xl);
        line-height: 16px;
    }

    .div {
        font-size: var(--font-size-5xl);
        line-height: 16px;
    }

    .div1 {
        font-size: var(--font-size-5xl);
        line-height: 16px;
    }

    .div2 {
        font-size: var(--font-size-5xl);
        line-height: 16px;
    }

    .thumbnailCell {
        gap: 18px 37px;
    }

    .productInfoCell {
        padding-bottom: var(--padding-104xl);
        box-sizing: border-box;
    }
}
@media screen and (max-width: 450px) {
    .input {
        font-size: var(--font-size-lg);
        line-height: 12px;
    }

    .div {
        font-size: var(--font-size-lg);
        line-height: 12px;
    }

    .div1 {
        font-size: var(--font-size-lg);
        line-height: 12px;
    }

    .div2 {
        font-size: var(--font-size-lg);
        line-height: 12px;
    }
}
