.frameContainer,
.productPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  align-self: stretch;
  gap: 55.6px 0;
  max-width: 100%;
}
.productPage {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: 0 0 0.3000000000001819px;
  box-sizing: border-box;
  gap: 71px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1425px) {
  .productPage {
    gap: 35px 0;
  }
}
@media screen and (max-width: 950px) {
  .frameContainer {
    gap: 28px 0;
  }
  .productPage {
    gap: 18px 0;
  }
}
