.listItem {
  top: 153px;
  display: flex;
  align-items: center;
  width: 73px;
  cursor: pointer;
}
.listItem,
.listItem1,
.listItem2,
.myPage {
  position: absolute;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItem1 {
  top: 187px;
  cursor: pointer;
}
.listItem2,
.myPage {
  top: 119px;
}
.myPage {
  top: 0;
  font-size: var(--font-size-31xl);
  line-height: 55px;
}
.category,
.csMyPageOrder2Child {
  position: absolute;
  top: 297px;
  left: 23px;
  width: 163px;
  height: 221px;
}
.csMyPageOrder2Child {
  left: 524px;
  border-radius: var(--br-3xs);
  background-color: var(--color-gainsboro-300);
  width: 871px;
  height: 153px;
  display: none;
}
.listItem3,
.listItem4,
.listItem5,
.listItem6 {
  position: absolute;
  top: 544px;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItem3 {
  left: 513px;
  color: var(--color-darkslategray-900);
  cursor: pointer;
}
.listItem4,
.listItem5,
.listItem6 {
  left: 668px;
}
.listItem5,
.listItem6 {
  top: 599px;
  left: 513px;
  font-size: var(--font-size-4xl);
}
.listItem6 {
  left: 623px;
}
.thumbIcon {
  position: absolute;
  top: 16px;
  left: 0;
  width: 150px;
  height: 175px;
  object-fit: cover;
}
.heading4 {
  position: absolute;
  top: 37.1px;
  left: 150.2px;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.heading41 {
  top: 86.1px;
  left: 150.2px;
  font-size: var(--font-size-7xl);
  line-height: 31.2px;
}
.div1,
.heading41,
.listItem7 {
  position: absolute;
  letter-spacing: -0.16px;
}
.div1 {
  top: 118.1px;
  left: 150.2px;
  font-size: var(--font-size-6xl);
  line-height: 55px;
  color: var(--color-darkslategray-500);
}
.listItem7 {
  top: 88.1px;
  left: 900px;
  font-size: var(--font-size-4xl);
  line-height: 33.6px;
}
.headerRowCell {
  position: absolute;
  top: calc(50% - 205.1px);
  left: 15.8px;
  border-bottom: 1px solid var(--color-darkslategray-1000);
  box-sizing: border-box;
  width: 1258.5px;
  height: 210.6px;
}
.div2,
.heading42,
.heading43 {
  position: absolute;
  top: 37.6px;
  left: 150.2px;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.div2,
.heading43 {
  top: 86.6px;
  font-size: var(--font-size-7xl);
  line-height: 31.2px;
}
.div2 {
  top: 118.6px;
  font-size: var(--font-size-6xl);
  line-height: 55px;
  color: var(--color-darkslategray-500);
}
.thumbIcon1 {
  position: absolute;
  top: 16px;
  left: 0;
  width: 150px;
  height: 175px;
  object-fit: cover;
}
.listItem8 {
  position: absolute;
  top: 86.6px;
  left: 900px;
  font-size: var(--font-size-4xl);
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.headerRowCell1 {
  top: calc(50% + 5.4px);
  left: 15.8px;
  border-bottom: 1px solid var(--color-darkslategray-1000);
  box-sizing: border-box;
  width: 1258.5px;
  height: 210.6px;
}
.child,
.div,
.headerRowCell1 {
  position: absolute;
}
.child {
  top: -0.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1289.6px;
  height: 1px;
}
.div {
  top: calc(50% - 281.5px);
  left: 513px;
  width: 1288.6px;
  height: 432px;
  font-size: var(--font-size-21xl);
}
.csMyPageOrder2Item {
  position: absolute;
  top: 578px;
  left: 668px;
  max-height: 100%;
  width: 170px;
}
.csMyPageOrder2 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 1869px;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
