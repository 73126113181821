.h3 {
  margin: 0;
  margin-top: 200px;
  position: relative;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
}
.scalerNode {
  position: absolute;
  margin-top: 200px;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.thresholdNode {
  margin: 0;
  margin-top: 200px;
  position: absolute;
  top: 0;
  left: 9px;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.normalizerNode {

  height: 56px;
  flex: 1;
  position: relative;
}
.ruleSetNode {
  position: relative;
  line-height: 56px;
  margin-top: 200px;
  margin-right: 12px;
}
.normalizerNodeChild {
  position: absolute;
  margin-top: 200px;
  top: 13px;
  left: 0;
  background-color: var(--color-gainsboro-300);
  width: 26px;
  height: 30px;
}
.h31 {
  margin: 0;
  margin-top: 200px;
  position: absolute;
  top: 0;
  left: 8px;
  font-size: inherit;
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.normalizerNode1 {
  height: 56px;
  flex: 1;
  position: relative;
}
.aggregatorNode,
.joinerNode,
.transformerNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.joinerNode {
  width: 101px;
  justify-content: flex-start;
  gap: var(--gap-xl-5);
}
.aggregatorNode,
.transformerNode {
  max-width: 100%;
}
.aggregatorNode {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.transformerNode {
  width: 644px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.strong0,
.total {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
  margin-top: 20px;
}
.total {
  margin: 0;
  margin-top: 20px;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.strong0 {
  text-align: right;
}
.falseBranchNode,
.trueBranchNode {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.falseBranchNode {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.trueBranchNode {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
}
.cart1 {
  width: 50px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-darkslategray-200);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.buy1,
.cart,
.cart1 {
  display: flex;
  justify-content: center;
}
.cart {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-white);
  flex: 0.8704;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 201px;
}
.cart:hover {
  background-color: var(--color-gainsboro-200);
}
.buy1 {
  width: 41.5px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.buy,
.constantNode,
.matrixNode {
  display: flex;
  align-items: flex-start;
}
.buy {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-base);
  background-color: var(--color-darkslategray-200);
  align-self: stretch;
  border-radius: var(--br-base);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  justify-content: center;
}
.buy:hover {
  background-color: var(--color-dimgray-300);
}
.constantNode,
.matrixNode {
  justify-content: flex-start;
}
.matrixNode {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 201px;
}
.constantNode {
  align-self: stretch;
  flex-direction: row;
  gap: 42.29999999999927px;
}
.conditionListNode {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-20xl);
  font-size: var(--font-size-11xl);
  font-family: var(--font-cormorant);
}
.collapsibleNode,
.conditionListNode,
.filterNode {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.filterNode {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-10xl);
}
.collapsibleNode {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-5xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
@media screen and (max-width: 900px) {
  .strong0,
  .total {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }
  .constantNode {
    flex-wrap: wrap;
    gap: 42.29999999999927px 21px;
  }
  .conditionListNode {
    gap: 19px 39px;
  }
}
@media screen and (max-width: 450px) {
  .h3,
  .h31,
  .ruleSetNode,
  .thresholdNode {
    font-size: var(--font-size-lg);
    line-height: 45px;
  }
  .aggregatorNode {
    flex-wrap: wrap;
  }
  .strong0,
  .total {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }
  .cart1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
  .cart {
    flex: 1;
  }
  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
}
