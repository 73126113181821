.headerList,
.listItem,
.listItem1 {
  position: absolute;
  letter-spacing: -0.16px;
}
.listItem {
  top: 334px;
  left: 645px;
  line-height: 33.6px;
  text-align: center;
}
.headerList,
.listItem1 {
  display: flex;
  align-items: center;
}
.headerList {
  top: calc(50% - 1211.5px);
  left: calc(50% + 877px);
  font-size: var(--font-size-21xl);
  line-height: 48px;
  color: var(--color-white);
  text-align: right;
  width: 71.5px;
  height: 48px;
}
.listItem1 {
  top: 153px;
  left: 0;
  line-height: 33.6px;
  width: 73px;
}
.listItem2 {
  top: 187px;
  line-height: 33.6px;
  cursor: pointer;
}
.listItem2,
.listItem3,
.myPage {
  position: absolute;
  left: 0;
  letter-spacing: -0.16px;
}
.listItem3 {
  top: 119px;
  line-height: 33.6px;
  cursor: pointer;
}
.myPage {
  top: 0;
  font-size: var(--font-size-31xl);
  line-height: 55px;
}
.category {
  position: absolute;
  top: 297px;
  left: 23px;
  width: 163px;
  height: 221px;
  font-size: var(--font-size-9xl);
}
.div2 {
  top: 0;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.child,
.div1,
.div2 {
  position: absolute;
}
.child {
  top: 2px;
  left: 187px;
  background-color: var(--color-gainsboro-300);
  width: 634px;
  height: 50px;
}
.div1 {
  top: 44.5px;
  left: 164px;
  width: 821px;
  height: 55px;
}
.div4,
.div5 {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.div5 {
  top: 61px;
}
.item {
  top: 63px;
  left: 187px;
  background-color: var(--color-gainsboro-300);
  width: 634px;
  height: 50px;
}
.div3,
.inner,
.item {
  position: absolute;
}
.inner {
  top: 0;
  left: 187px;
  background-color: var(--color-gainsboro-300);
  width: 634px;
  height: 50px;
}
.div3 {
  top: 141.5px;
  left: 164px;
  width: 821px;
  height: 116px;
}
.div7,
.div8,
.div9 {
  position: absolute;
  top: 63px;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.div8,
.div9 {
  top: 0;
  left: 2px;
}
.div9 {
  top: 126px;
  left: 0;
}
.child1,
.child2,
.groupChild,
.rectangleDiv {
  position: absolute;
  top: 2px;
  left: 188px;
  background-color: var(--color-gainsboro-300);
  width: 505px;
  height: 50px;
}
.child1,
.child2,
.groupChild {
  top: 128px;
  width: 634px;
}
.child2,
.groupChild {
  top: 65px;
  left: 185px;
  width: 637px;
}
.groupChild {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 114px;
}
.heading4 {
  position: absolute;
  top: 9px;
  left: 40px;
  letter-spacing: -0.16px;
  line-height: 31.2px;
}
.rectangleParent {
  position: absolute;
  top: 2px;
  left: 708px;
  width: 114px;
  height: 50px;
  text-align: right;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
}
.div6 {
  position: absolute;
  top: 299.5px;
  left: 164px;
  width: 822px;
  height: 181px;
}
.child3,
.child4,
.rectangleIcon {
  position: absolute;
  width: 119px;
  height: 50px;
}
.child3 {
  top: 2.5px;
  left: 338px;
  background-color: var(--color-gainsboro-300);
}
.child4,
.rectangleIcon {
  top: 0.5px;
  left: 492px;
}
.child4 {
  top: 2.5px;
  left: 185px;
  background-color: var(--color-gainsboro-300);
}
.div11,
.div12 {
  letter-spacing: -0.16px;
  line-height: 55px;
}
.div11 {
  position: absolute;
  top: 1px;
  left: 471px;
}
.div12 {
  top: 0;
  left: 317px;
}
.div10,
.div12,
.div13 {
  position: absolute;
}
.div13 {
  top: 1.5px;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
}
.div10 {
  top: 522.5px;
  left: 164px;
  width: 611px;
  height: 56.5px;
  font-family: var(--font-cormorant);
}
.groupItem,
.lineDiv {
  position: absolute;
  box-sizing: border-box;
}
.lineDiv {
  top: -0.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-black);
  width: 1151px;
  height: 1px;
}
.groupItem {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gainsboro-400);
  border: 1px solid var(--color-lightgray);
  width: 114px;
  height: 50px;
}
.heading41 {
  position: absolute;
  top: 9px;
  left: 23px;
  letter-spacing: -0.16px;
  line-height: 31.2px;
}
.rectangleGroup {
  position: absolute;
  top: 723.5px;
  left: 517px;
  width: 114px;
  height: 50px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
}
.div {
  position: absolute;
  top: 377.5px;
  left: 639px;
  width: 1150px;
  height: 773.5px;
  font-size: var(--font-size-6xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-apple-sd-gothic-neo);
}
.csMyPageProfile {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 1869px;
  text-align: left;
  font-size: var(--font-size-16xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
