.thumbIcon {
  height: 1050px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 585px;
}
.discountChild {
  height: 40px;
  width: 60px;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: none;
}
.chatFrame {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 45.6px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #383c35;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.discount {
  width: 60px;
  border-radius: var(--br-mini);
  background-color: var(--color-gainsboro-400);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 var(--padding-base);
  box-sizing: border-box;
  text-align: center;
}
.div,
.div1,
.heading4 {
  position: relative;
  letter-spacing: -0.16px;
}
.heading4 {
  margin: 0;
  font-size: var(--font-size-21xl);
  line-height: 33.6px;
  font-weight: 400;
  font-family: inherit;
}
.div,
.div1 {
  line-height: 45.6px;
  white-space: nowrap;
}
.div {
  text-decoration: line-through;
}
.div1 {
  color: var(--color-darkslategray-200);
}
.complexNetwork {
  flex-direction: row;
  gap: var(--gap-3xl);
}
.complexNetwork,
.connectedComponentsTree,
.ownerFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.connectedComponentsTree {
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  font-size: var(--font-size-19xl);
  color: var(--color-darkslategray-300);
}
.ownerFrame {
  flex-direction: column;
  gap: var(--gap-sm);
}
.ml {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  display: inline-block;
  max-width: 100%;
}
.helpFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.decisiveJunction {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-165xl);
}
.connectedComponents,
.decisiveJunction,
.mergingGraph,
.siblingNodes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.mergingGraph {
  width: 667.7px;
  flex-direction: column;
  padding: var(--padding-90xl) 0 0;
  box-sizing: border-box;
  min-width: 667.7000000000007px;
}
.connectedComponents,
.siblingNodes {
  flex-direction: row;
}
.connectedComponents {
  flex: 1;
  gap: var(--gap-mini);
}
.siblingNodes {
  width: 1712.7px;
  padding: 0 var(--padding-46xl) var(--padding-83xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1600px) {
  .mergingGraph {
    flex: 1;
  }
  .connectedComponents {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .siblingNodes {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-47xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .thumbIcon {
    min-width: 100%;
  }
  .heading4 {
    font-size: var(--font-size-13xl);
    line-height: 27px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-11xl);
    line-height: 36px;
  }
  .decisiveJunction {
    gap: 92px 184px;
  }
  .mergingGraph {
    padding-top: var(--padding-52xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .siblingNodes {
    padding-bottom: var(--padding-24xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .chatFrame {
    font-size: var(--font-size-lg);
    line-height: 36px;
  }
  .heading4 {
    font-size: var(--font-size-5xl);
    line-height: 20px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-4xl);
    line-height: 27px;
  }
  .decisiveJunction {
    gap: 46px 184px;
  }
}
