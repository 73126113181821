.cs {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.helpFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-2xl);
  text-align: left;
  font-size: var(--font-size-31xl);
}
.listItem,
.listItem1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItem {
  width: 73.3px;
  cursor: pointer;
}
.listItem1 {
  width: 63.2px;
  flex-shrink: 0;
}
.span {
  font-family: var(--font-apple-sd-gothic-neo);
}
.listItemContainer {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-family: inherit;
}
.cancelRegisterGroupChild {
  height: 61px;
  width: 1195px;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.listItem2 {
  width: 200px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-6xl);
  background-color: transparent;
  height: 34px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  color: var(--color-darkslategray-200);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 1;
}
.cancelRegisterGroup,
.cancelRegisterGroupItem {
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  max-width: 100%;
}
.cancelRegisterGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi) var(--padding-10xl) var(--padding-sm);
}
.cancelRegisterGroupItem {
  height: 679px;
  width: 1195px;
  position: relative;
  display: none;
}
.listItem3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  z-index: 1;
}
.cancelRegisterGroup1,
.cancelRegisterGroupInner {
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  box-sizing: border-box;
  max-width: 100%;
}
.cancelRegisterGroup1 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--color-lightgray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xl) var(--padding-10xl);
  font-size: var(--font-size-6xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.cancelRegisterGroupInner {
  height: 61px;
  width: 1195px;
  position: relative;
  border: 1px solid var(--color-gainsboro-300);
  display: none;
}
.listItem4 {
  width: 87px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-6xl);
  background-color: transparent;
  height: 34px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  color: var(--color-darkslategray-200);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 1;
}
.cancelRegisterGroup2,
.groupRectangle {
  align-self: stretch;
  justify-content: flex-start;
}
.cancelRegisterGroup2 {
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-smi) var(--padding-10xl) var(--padding-sm);
  max-width: 100%;
}
.groupRectangle {
  flex: 1;
  flex-direction: column;
  gap: 31px 0;
}
.chatListFrame,
.groupRectangle,
.monFriText {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.monFriText {
  height: 936px;
  width: 1195px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-16xl);
}
.chatListFrame {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.div {
  position: absolute;
  top: 14px;
  left: 99px;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: left;
  z-index: 1;
}
.rectangleParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 83px;
  flex: 1;
  position: relative;
  min-width: 162px;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.div1 {
  position: absolute;
  top: 14px;
  left: 99px;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: left;
  z-index: 1;
}
.rectangleGroup {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 83px;
  flex: 1;
  position: relative;
  min-width: 162px;
}
.chatItemLinkNoticeFrame,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frameParent {
  width: 567px;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0 67px;
}
.chatItemLinkNoticeFrame {
  width: 1127px;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.footerFrame,
.groupFrame,
.qAText {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.qAText {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  gap: 47px 0;
}
.footerFrame,
.groupFrame {
  align-items: flex-start;
}
.footerFrame {
  flex: 1;
  flex-direction: column;
  gap: 7px 0;
}
.groupFrame {
  width: 1868px;
  flex-direction: row;
  padding: 0 var(--padding-4xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1325px) {
  .chatListFrame {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  .cs {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .listItemContainer {
    font-size: var(--font-size-9xl);
    line-height: 27px;
  }
  .groupRectangle {
    gap: 15px 0;
  }
  .frameParent {
    gap: 0 33px;
  }
  .qAText {
    gap: 23px 0;
  }
}
@media screen and (max-width: 450px) {
  .cs {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .listItem,
  .listItem1 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .listItemContainer {
    font-size: var(--font-size-2xl);
    line-height: 20px;
  }
  .listItem2,
  .listItem3,
  .listItem4 {
    font-size: var(--font-size-xl);
    line-height: 27px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .frameParent {
    gap: 0 17px;
  }
}
