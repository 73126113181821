.itemImageFrame, .priceFrame, .productListFrame, .productDivisionFrame {
  flex: 1; /* 각 컨테이너에 동일한 공간 배정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.itemImgIcon, .itemImgIcon1, .itemImgIcon2 {
  width: 100%; /* 이미지가 컨테이너의 전체 너비를 차지하도록 */
  height: auto; /* 이미지의 높이를 자동으로 설정하여 비율 유지 */
  object-fit: cover; /* 컨테이너를 꽉 채우도록 */
}
.listItem, .listItem1, .listItem2 {
  width: 100%; /* 리스트 아이템의 너비를 컨테이너에 맞춤 */
  flex: 1; /* 각 아이템에 동일한 공간 배정 */
  margin: 0 10px; /* 좌우 간격 */
  padding: 20px; /* 내부 패딩 */
}
.productListFrame, .productDivisionFrame {
  display: flex;
  justify-content: space-between; /* 아이템들 사이에 균등한 간격을 유지 */
  /*align-items: ; !* 아이템들을 수직으로 중앙 정렬 *!*/
  /*padding: 20px; !* 패딩 추가 *!*/
  width: 100%; /* 컨테이너의 너비를 100%로 설정 */
}
.set {
  position: absolute; /* 절대 위치 사용 */
  top: 400px; /* 최상단에 위치 */
  left: 0; /* 좌측에 위치 */
  background-color: #fff; /* 배경색 */
  padding: 5px 10px; /* 패딩 */
  /*box-shadow: 0 2px 4px rgba(0,0,0,0.1); !* 그림자 효과 *!*/
  z-index: 10; /* 다른 요소 위에 보이도록 z-index 설정 */
  margin: 0;
  width: 72.4px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33.9px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.descriptionFrame {
  width: 260.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.itemImgIcon {
  align-self: stretch;
  height: 600px;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.listItem {
  flex: 0.9615;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  gap: 13px 0;
  min-width: 406px;
  max-width: 100%;
  cursor: pointer;
}
.itemImgIcon1 {
  height: 600px;
  flex: 1;
  position: relative;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.listItem1 {
  min-width: 406px;
  max-width: 100%;
  margin: 0 !important;
  position: absolute;
  top: -613px;
  left: -3.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs) var(--padding-58xl);
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
}
.ml2 {
  margin: 0;
}
.sectionListContainer1 {
  width: 100%;
}
.sectionListContainer {
  width: 334px;
  height: 59px;
  position: absolute;
  margin: 0 !important;
  bottom: -26px;
  left: 119.7px;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  display: flex;
  align-items: center;
  opacity: 0.9;
}
.div,
.div1,
.setBodyMist {
  position: relative;
  letter-spacing: -0.16px;
}
.setBodyMist {
  align-self: stretch;
  font-size: var(--font-size-9xl);
  line-height: 33.6px;
  color: var(--color-darkslategray-200);
}
.div,
.div1 {
  white-space: nowrap;
}
.div {
  flex: 1;
  text-decoration: line-through;
  line-height: 42px;
}
.div1 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.productItemBusinessInfoFra {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.productItemChatFrame,
.productItemLinkFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.productItemChatFrame {
  width: 223.5px;
  justify-content: flex-start;
}
.productItemLinkFrame {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
}
.divitemDescription,
.productListFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}
.divitemDescription {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 var(--padding-21xl);
  box-sizing: border-box;
  gap: 5px 0;
  cursor: pointer;
  z-index: 2;
}
.productListFrame {
  width: 574px;
  flex-direction: row;
}
.itemImgIcon2 {
  height: 600px;
  flex: 1;
  position: relative;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.listItem2,
.listItemParent,
.productDivisionFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.listItem2 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  cursor: pointer;
}
.listItemParent,
.productDivisionFrame {
  flex-direction: column;
}
.listItemParent {
  align-self: stretch;
  gap: 12px 0;
}
.productDivisionFrame {
  flex: 1;
  padding: 0 0 var(--padding-12xs);
  box-sizing: border-box;
  min-width: 406px;
}
.priceFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 0 25px;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
}
.itemImageFrame,
.itemListFrame {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
  width: 100%;
}
.itemImageFrame {
  flex: 1;
  flex-direction: column;
  gap: 46.5px 0;
}
.itemListFrame {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1900px) {
  .listItem,
  .productDivisionFrame {
    flex: 1;
  }
  .priceFrame {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 950px) {
  .set {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .listItem,
  .productDivisionFrame {
    min-width: 100%;
  }
  .itemImageFrame {
    gap: 23px 0;
  }
  .productListFrame, .productDivisionFrame {
    flex-direction: column; /* 아이템들을 세로로 쌓음 */
    justify-content: center; /* 중앙 정렬 */
  }

  .listItem, .listItem1, .listItem2 {
    margin: 10px 0; /* 세로 정렬시 상하 마진 적용 */
    width: 100%; /* 너비 100% 설정으로 화면 전체 너비 사용 */
  }
}
@media screen and (max-width: 450px) {
  .set {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .setBodyMist {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
  .productListFrame, .productDivisionFrame {
    flex-direction: column; /* 아이템들을 세로로 쌓음 */
    justify-content: center; /* 중앙 정렬 */
  }

  .listItem, .listItem1, .listItem2 {
    margin: 10px 0; /* 세로 정렬시 상하 마진 적용 */
    width: 100%; /* 너비 100% 설정으로 화면 전체 너비 사용 */
  }
}
