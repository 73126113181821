.divhinokAboutWrap {
  height: 1080px;
  width: 1920px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  max-width: 100%;
}
.brandstory3,
.divswiperSlide {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divswiperSlide {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-17xl) var(--padding-34xl);
  box-sizing: border-box;
  gap: 383.8px 0;
  background-image: url(../../../public/BS4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  z-index: 1;
}
.brandstory3 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: row;
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .divswiperSlide {
    gap: 192px 0;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .divswiperSlide {
    gap: 96px 0;
  }
}
@media screen and (max-width: 450px) {
  .divswiperSlide {
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
}
