.headerList,
.headerList1 {
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  z-index: 2;
}
.headerList3 {
  cursor: pointer;
}
.headerListItemLinkParent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 67px;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
.headerList4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  cursor: pointer;
  z-index: 2;
}
.frameBrandStory {
  width: 914.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: right;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
.dailySkin {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33px;
  font-weight: 500;
  z-index: 2;
}
.p,
.p1 {
  margin: 0;
}
.div {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
  z-index: 2;
}
.p2,
.p3,
.p4 {
  margin: 0;
}
.div1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
  z-index: 2;
}
.textmyfavoritewater {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px 0;
  font-size: var(--font-size-xl);
}
.framedailyskinbodycare,
.textdailyskinbodycare {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.textdailyskinbodycare {
  width: 333.6px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 27.2px 0;
}
.framedailyskinbodycare {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
}
.onSpa {
  margin: 0;
  position: relative;
  font-size: var(--font-size-56xl);
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 500;
  font-family: var(--font-cormorant);
  color: var(--color-gray-400);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}
.innerFrame,
.onSpaLabel {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.innerFrame {
  width: 1069.6px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 99px 0;
}
.onSpaLabel {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 403px 0 0;
  box-sizing: border-box;
}
.divswiperSlideIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  object-fit: cover;
  z-index: 1;
}
.divhinokAboutWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.div2,
.div3,
.div4,
.divactive {
  position: absolute;
  top: 1024px;
  left: 967px;
  border-radius: var(--br-12xs);
  background-color: var(--color-white);
  width: 26px;
  height: 3px;
  z-index: 2;
}
.div2,
.div3,
.div4 {
  left: 999px;
  background-color: var(--color-gray-500);
  cursor: pointer;
}
.div3,
.div4 {
  left: 1031px;
}
.div4 {
  left: 1063px;
}
.headerListItemLink {
  top: 15px;
  left: 1738px;
  width: 62px;
  height: 63px;
  overflow: hidden;
  object-fit: contain;
  cursor: pointer;
}
.headerListItemLink,
.icon,
.instagram1Icon {
  position: absolute;
  z-index: 2;
}
.icon {
  top: 31px;
  left: 36px;
  width: 334px;
  height: 47px;
  object-fit: cover;
  cursor: pointer;
}
.instagram1Icon {
  top: 25px;
  left: 1518px;
  width: 29px;
  height: 29px;
  overflow: hidden;
}
.activeDivWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.brandstory1 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-17xl-5) 246px;
  box-sizing: border-box;
  gap: 414px 0;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-14xl);
  color: var(--color-white);
  font-family: var(--font-apple-sd-gothic-neo);
}
@media screen and (max-width: 1200px) {
  .innerFrame {
    gap: 49px 0;
  }
  .onSpaLabel {
    padding-right: 201px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .dailySkin {
    font-size: var(--font-size-7xl);
    line-height: 26px;
  }
  .onSpa {
    font-size: var(--font-size-41xl);
    line-height: 44px;
  }
  .brandstory1 {
    gap: 207px 0;
  }
}
@media screen and (max-width: 750px) {
  .headerListItemLinkParent {
    display: none;
    gap: 0 33px;
  }
  .frameBrandStory {
    width: 432.5px;
  }
  .innerFrame {
    gap: 25px 0;
  }
  .onSpaLabel {
    padding-right: 100px;
    box-sizing: border-box;
  }
  .brandstory1 {
    gap: 103px 0;
  }
}
@media screen and (max-width: 450px) {
  .dailySkin,
  .div,
  .div1 {
    font-size: var(--font-size-xl);
    line-height: 20px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-base);
  }
  .onSpa {
    font-size: var(--font-size-26xl);
    line-height: 33px;
  }
  .onSpaLabel {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
