.logIn {
  margin: 0;
  width: 147.2px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.logInParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 89px 0;
  max-width: 100%;
}
.kakaoLoginFormFrame,
.login {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.kakaoLoginFormFrame {
  width: 974px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-2xs) 22.200000000000728px var(--padding-xl);
  max-width: 100%;
}
.login {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-3xl) 0 0;
  gap: 248px 0;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1025px) {
  .logIn {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .logInParent {
    gap: 44px 0;
  }
  .login {
    gap: 124px 0;
  }
}
@media screen and (max-width: 750px) {
  .logInParent {
    gap: 22px 0;
  }
  .login {
    gap: 62px 0;
  }
}
@media screen and (max-width: 450px) {
  .logIn {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
}
