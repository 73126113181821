.icon {
  height: 47px;
  width: 334px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.logoFrame,
.logoFrameWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.logoFrame {
  width: 1867.5px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 var(--padding-xl) 0 0;
  gap: var(--gap-xl);
}
.logoFrameWrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs);
}
