.qALabelWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) var(--padding-58xl) var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.registrationFrame {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.div1 {
  position: absolute;
  top: 14px;
  left: 99px;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.div {
  height: 83px;
  width: 250px;
  position: relative;
}
.csQnadetail,
.footerHelp {
  display: flex;
  box-sizing: border-box;
}
.footerHelp {
  width: 1282px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  max-width: 100%;
}
.csQnadetail {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0.3000000000001819px;
  gap: 101px 0;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-white);
  font-family: var(--font-apple-sd-gothic-neo);
}
@media screen and (max-width: 1125px) {
  .qALabelWrapper {
    padding-bottom: var(--padding-31xl);
    box-sizing: border-box;
  }
  .csQnadetail {
    gap: 50px 0;
  }
}
@media screen and (max-width: 800px) {
  .qALabelWrapper {
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
  .csQnadetail {
    gap: 25px 0;
  }
}
@media screen and (max-width: 450px) {
  .div1 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
}
