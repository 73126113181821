.listItem {
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 15px;
  font-weight: 600;
}
.listItemLinkWrapper {
  width: 40.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.c121412,
.p,
.p1,
.p2 {
  margin: 0;
}
.c121412Container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  color: var(--color-lightgray);
  text-align: left;
}
.frameContainer {
  flex-direction: column;
  gap: var(--gap-xs);
}
.frameContainer,
.frameGroup,
.frameParent,
.frameWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
}
.frameWrapper {
  flex-direction: row;
  /*padding: 0 var(--padding-6xl);*/
  box-sizing: border-box;
}
.frameGroup,
.frameParent {
  align-self: stretch;
  flex-direction: column;
  /*gap: var(--gap-27xl);*/
  padding: 0;
  margin-bottom: 0;
}
.frameParent {
  /*gap: var(--gap-111xl);*/
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
  gap: 0;
  margin-bottom: 0;
}
.help {
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  font-weight: 300;
}
.helpWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /*padding: 0 0 0 var(--padding-1071xl);*/
}
.onSpringInc {
  margin: 0;
  font-weight: 300;
}
.address744Ho,
.emailInfoonSpringcokr,
.ownerBryanKim,
.sujeongGuSeongnamSiGyeon,
.tel07044036450 {
  margin: 0;
}
.onSpring,
.onSpringIncContainer {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.onSpringIncContainer {
  align-self: stretch;
}
.onSpring {
  font-weight: 300;
  color: var(--color-darkslategray-100);
}
.onSpringIncOwnerBryanKiParent {
  width: 424.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 64.29999999999927px;
  max-width: 100%;
}
.listItem1,
.listItem2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  white-space: nowrap;
  cursor: pointer;
}
.listItemLink11ChatParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.listItem3 {
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  font-weight: 300;
}
.p3,
.p4 {
  margin: 0;
}
.listItemContainer {
  height: 53px;
  position: relative;
  font-size: var(--font-size-mid);
  letter-spacing: -0.16px;
  line-height: 26.4px;
  display: flex;
  align-items: center;
}
.listItemLink11ChatGroup {
  gap: var(--gap-12xs);
}
.frameParent1,
.frameWrapper1,
.listItemLink11ChatGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent1 {
  gap: var(--gap-xl);
}
.frameWrapper1 {
  padding: var(--padding-sm) 0 0;
}
.listItem4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  cursor: pointer;
}
.infoonSpringcokr,
.p5 {
  margin: 0;
}
.listItemContainer2 {
  line-break: anywhere;
}
.listItemContainer1 {
  height: 53px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  display: flex;
  align-items: center;
}
.lunchTime12001330,
.monFri10001700 {
  margin: 0;
}
.listItemContainer3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.listItemMonFri100017Wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 6.399999999999636px;
}
.p6,
.p7,
.qa {
  margin: 0;
}
.listItemContainer4 {
  position: relative;
  font-size: var(--font-size-mini);
  letter-spacing: -0.16px;
  line-height: 23px;
}
.listItemLink11ChatParent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8.600000000000364px;
}
.listItemLink11ChatContainer {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.frameDiv,
.frameWrapper2,
.listItemLink11ChatContainer {
  display: flex;
  align-items: flex-start;
}
.frameWrapper2 {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.frameDiv {
  width: 1703px;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  font-size: var(--font-size-xl);
}
.footer,
.productPageMist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0;
  gap: 180px;
}
.footer {
  align-self: stretch;
  background: linear-gradient(180deg, #fff, #e3e8e1);
  overflow: hidden;
  /*padding: var(--padding-138xl) var(--padding-5xl) 25.400000000001455px;*/
  gap: var(--gap-smi);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-dimgray);
  font-family: var(--font-apple-sd-gothic-neo);
}
.productPageMist {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .frameParent {
    gap: 65px 130px;
  }
  .helpWrapper {
    padding-left: var(--padding-526xl);
    box-sizing: border-box;
  }
  .frameDiv {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 825px) {
  .frameGroup {
    gap: 23px 46px;
  }
  .frameParent {
    gap: 32px 130px;
  }
  .helpWrapper {
    padding-left: var(--padding-253xl);
    box-sizing: border-box;
  }
  .footer {
    /*padding-top: var(--padding-83xl);*/
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .help {
    font-size: var(--font-size-lg);
    line-height: 21px;
  }
  .helpWrapper {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .onSpring,
  .onSpringIncContainer {
    font-size: var(--font-size-base);
    line-height: 21px;
  }
  .onSpringIncOwnerBryanKiParent {
    gap: 32px 64.29999999999927px;
  }
  .listItem1,
  .listItem2,
  .listItem3,
  .listItem4,
  .listItemContainer1,
  .listItemContainer3 {
    font-size: var(--font-size-base);
    line-height: 21px;
  }
}
