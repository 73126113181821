.divhinokAboutWrap {
  height: 1080px;
  width: 1920px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  max-width: 100%;
}
.family,
.icon {
  position: relative;
  z-index: 2;
}
.icon {
  width: 334px;
  flex: 1;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.family {
  margin: 0;
  width: 198px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 500;
  font-family: inherit;
  display: flex;
  align-items: center;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.secondaryFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.loginSVG,
.primaryText,
.subHeader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.primaryText {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: 701px 0;
}
.loginSVG,
.subHeader {
  box-sizing: border-box;
}
.subHeader {
  height: 819px;
  width: 386px;
  flex-direction: column;
  padding: var(--padding-base) 0 0;
  min-width: 386px;
}
.loginSVG {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-4xs);
}
.tailoredForOur {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33px;
  font-weight: 500;
}
.p,
.p1 {
  margin: 0;
}
.div {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
}
.activeDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17.2px 0;
}
.p2,
.p3,
.p4,
.p5 {
  margin: 0;
}
.div1 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
}
.userFriendlyOnsen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px 0;
}
.instagramIcon,
.subHeaderParent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.instagramIcon {
  width: 923.5px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 195px 0;
  min-width: 923.5px;
  font-size: var(--font-size-14xl);
  color: var(--color-white);
  font-family: var(--font-apple-sd-gothic-neo);
}
.subHeaderParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-56xl);
  color: var(--color-gray-400);
  font-family: var(--font-cormorant);
}
.div2,
.div3,
.div4,
.divactive {
  height: 3px;
  width: 26px;
  position: relative;
  border-radius: var(--br-12xs);
  z-index: 2;
}
.div2,
.div3,
.div4 {
  background-color: var(--color-gray-500);
  cursor: pointer;
}
.divactive {
  background-color: var(--color-white);
}
.activeDivGroup,
.containerFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.activeDivGroup {
  justify-content: flex-start;
  gap: 0 6px;
}
.containerFrame {
  width: 1711px;
  justify-content: center;
  max-width: 100%;
}
.brandstory4,
.divswiperSlide {
  display: flex;
  justify-content: flex-start;
}
.divswiperSlide {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-mini) var(--padding-17xl-5) var(--padding-34xl)
    var(--padding-17xl);
  box-sizing: border-box;
  gap: 190px 0;
  background-image: url(../../../public/BS3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  z-index: 1;
}
.brandstory4 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: flex-start;
  letter-spacing: normal;
}
@media screen and (max-width: 1400px) {
  .instagramIcon,
  .subHeader {
    flex: 1;
  }
  .subHeaderParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .instagramIcon {
    gap: 97px 0;
    min-width: 100%;
  }
  .divswiperSlide {
    gap: 95px 0;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .family {
    font-size: var(--font-size-41xl);
    line-height: 44px;
  }
  .subHeader {
    min-width: 100%;
  }
  .tailoredForOur {
    font-size: var(--font-size-7xl);
    line-height: 26px;
  }
  .divswiperSlide {
    gap: 47px 0;
  }
}
@media screen and (max-width: 450px) {
  .family {
    font-size: var(--font-size-26xl);
    line-height: 33px;
  }
  .primaryText {
    gap: 350px 0;
  }
  .div,
  .div1,
  .tailoredForOur {
    font-size: var(--font-size-xl);
    line-height: 20px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-base);
  }
  .instagramIcon {
    gap: 49px 0;
  }
  .divswiperSlide {
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
}
