.cart {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.spraySetFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) var(--padding-18xl);
  color: var(--color-darkslategray-500);
}
.input {
  width: 112px;
  border: 0;
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  height: 36px;
  position: relative;
  line-height: 20px;
  color: var(--color-darkslategray-700);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
}
.headerRowCell {
  flex: 1;
  border-top: 1px solid var(--color-darkslategray-1000);
  border-bottom: 1px solid var(--color-darkslategray-1000);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-16xl) var(--padding-10xl);
  min-width: 112px;
  max-width: 100%;
}
.div,
.headerRowCell1 {
  display: flex;
  justify-content: center;
}
.div {
  align-self: stretch;
  width: 52px;
  position: relative;
  font-size: var(--font-size-11xl);
  line-height: 20px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-700);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.headerRowCell1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xl) var(--padding-xl) var(--padding-10xl);
  background-color: transparent;
  height: 93px;
  width: 278px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
}
.headerRowCell1:hover,
.headerRowCell2:hover,
.headerRowCell3:hover {
  background-color: var(--color-dimgray-400);
}
.div1 {
  align-self: stretch;
  width: 104px;
  position: relative;
  font-size: var(--font-size-11xl);
  line-height: 20px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-700);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.div1,
.div2,
.headerRowCell2 {
  display: flex;
  justify-content: center;
}
.headerRowCell2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xl) var(--padding-xl) var(--padding-10xl);
  background-color: transparent;
  height: 93px;
  width: 288px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
}
.div2 {
  height: 36px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-11xl);
  line-height: 20px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-800);
  text-align: center;
  align-items: center;
}
.headerRowCell3,
.totalFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerRowCell3 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xl) var(--padding-11xl) var(--padding-10xl);
  background-color: transparent;
  width: 112px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
}
.totalFrame {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 0.6666666666666666px;
  max-width: 100%;
}
.thumbIcon {
  height: 233.3px;
  width: 200px;
  position: relative;
  object-fit: cover;
}
.heading4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.chatFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 416px;
  min-height: 133px;
  max-width: 100%;
  flex-shrink: 0;
}
.headerRowCell4,
.headerRowCell5 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: var(--font-cormorant);
}
.headerRowCell4 {
  flex: 1;
  border-top: 1px solid var(--color-darkslategray-1000);
  border-right: 1px solid var(--color-darkslategray-1000);
  border-bottom: 1px solid var(--color-darkslategray-1000);
  align-items: flex-end;
  padding: var(--padding-base) 0 17.700000000000728px;
  min-width: 554px;
  row-gap: 20px;
  max-width: 100%;
}
.headerRowCell5 {
  border: 0;
  background-color: transparent;
  height: 267px;
  width: 288px;
  outline: 0;
  border-top: 1px solid var(--color-darkslategray-800);
  border-right: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  align-items: flex-start;
  padding: var(--padding-97xl) var(--padding-75xl);
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
}
.div3 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.bodyRowData,
.headerRowCell6 {
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerRowCell6 {
  width: 115px;
  border-top: 1px solid var(--color-darkslategray-800);
  padding: var(--padding-104xl) var(--padding-12xl) var(--padding-105xl)
    var(--padding-13xl);
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-700);
}
.bodyRowData {
  align-self: stretch;
  flex-wrap: wrap;
  padding: 0;
  row-gap: 20px;
  max-width: 100%;
}
.thumbIcon1 {
  height: 233.3px;
  width: 200px;
  position: relative;
  object-fit: cover;
}
.heading41 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.heading4TheSpraySetWrapper {
  height: 133.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 159px;
}
.headerRowCell7,
.headerRowCell8,
.headerRowCell9 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.headerRowCell7 {
  flex: 1;
  border-top: 1px solid var(--color-darkslategray-1000);
  border-right: 1px solid var(--color-darkslategray-1000);
  border-bottom: 1px solid var(--color-darkslategray-1000);
  align-items: flex-end;
  padding: var(--padding-base) 0 17.700000000000728px;
  min-width: 359px;
  max-width: 100%;
  row-gap: 20px;
}
.headerRowCell8,
.headerRowCell9 {
  width: 278px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-right: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  align-items: flex-start;
}
.headerRowCell9 {
  border: 0;
  background-color: transparent;
  height: 267px;
  width: 288px;
  outline: 0;
  padding: var(--padding-97xl) var(--padding-75xl);
  font-family: var(--font-cormorant);
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
}
.div4 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.bodyRowData1,
.buyFrame,
.headerRowCell10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerRowCell10 {
  width: 115px;
  border-top: 1px solid var(--color-darkslategray-800);
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-104xl) var(--padding-12xl) var(--padding-105xl)
    var(--padding-13xl);
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-700);
  font-family: var(--font-apple-sd-gothic-neo);
}
.bodyRowData1,
.buyFrame {
  align-self: stretch;
  max-width: 100%;
}
.bodyRowData1 {
  border-bottom: 1px solid var(--color-darkslategray-800);
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  row-gap: 20px;
  font-family: var(--font-cormorant);
}
.buyFrame {
  flex-direction: column;
  min-height: 619px;
  font-size: var(--font-size-21xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.total {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
}
.totalWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.strong0 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
  text-align: right;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.buy,
.buy1 {
  display: flex;
  justify-content: center;
}
.buy1 {
  width: 41.5px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 31px;
  align-items: center;
  flex-shrink: 0;
}
.buy {
  width: 309px;
  border-radius: var(--br-base);
  background-color: var(--color-darkslategray-200);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-mini)
    var(--padding-3xl);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-white);
}
.frameParent {
  width: 371.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-18xl);
  max-width: 100%;
}
.rowFrameInner {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  font-size: var(--font-size-11xl);
}
.productInfoFrame,
.rowFrame,
.rowFrameInner {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.rowFrame {
  width: 1533.1px;
  flex-direction: column;
  justify-content: flex-start;
}
.productInfoFrame {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-52xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1100px) {
  .productInfoFrame {
    padding-bottom: var(--padding-27xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .cart {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .div,
  .div1,
  .div2,
  .input {
    font-size: var(--font-size-5xl);
    line-height: 16px;
  }
  .thumbIcon {
    flex: 1;
  }
  .heading4 {
    font-size: var(--font-size-13xl);
    line-height: 27px;
  }
  .chatFrame {
    flex: 1;
    min-width: 100%;
    min-height: auto;
  }
  .headerRowCell4 {
    flex-wrap: wrap;
    min-width: 100%;
  }
  .div3 {
    font-size: var(--font-size-5xl);
    line-height: 16px;
  }
  .heading41 {
    font-size: var(--font-size-13xl);
    line-height: 27px;
  }
  .div4,
  .strong0,
  .total {
    font-size: var(--font-size-5xl);
    line-height: 16px;
  }
  .strong0,
  .total {
    line-height: 24px;
  }
  .productInfoFrame {
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .cart {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .div,
  .div1,
  .div2,
  .input {
    font-size: var(--font-size-lg);
    line-height: 12px;
  }
  .heading4 {
    font-size: var(--font-size-5xl);
    line-height: 20px;
  }
  .div3 {
    font-size: var(--font-size-lg);
    line-height: 12px;
  }
  .thumbIcon1 {
    flex: 1;
  }
  .heading41 {
    font-size: var(--font-size-5xl);
    line-height: 20px;
  }
  .heading4TheSpraySetWrapper {
    flex: 1;
  }
  .headerRowCell7 {
    flex-wrap: wrap;
    min-width: 100%;
  }
  .div4,
  .strong0,
  .total {
    font-size: var(--font-size-lg);
    line-height: 12px;
  }
  .strong0,
  .total {
    line-height: 18px;
  }
  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
  .frameParent {
    gap: 18px 37px;
  }
}
