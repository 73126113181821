.cs {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.csWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-2xl) 0;
  text-align: left;
  font-size: var(--font-size-31xl);
}
.listItem,
.listItem1 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.listItem {
  width: 93.3px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.listItem1 {
  width: 63.2px;
  flex-shrink: 0;
}
.listItem1,
.listItem2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.frameChild {
  align-self: stretch;
  height: 0.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.listItemLinkNoticeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 0;
  gap: 13px 0;
  text-align: left;
  font-size: var(--font-size-16xl);
  font-family: var(--font-cormorant);
}
.listItem3 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItemLinkNoticeWrapper {
  width: 176px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
  box-sizing: border-box;
}
.listItem4 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItemLinkNoticeContainer {
  width: 78px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-xs) 0 0;
  box-sizing: border-box;
}
.listItem5 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-weight: 300;
}
.listItemLinkNoticeFrame {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
  box-sizing: border-box;
  min-width: 90px;
}
.rectangleShape {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  cursor: pointer;
}
.div1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.deleteLabel,
.div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.deleteLabel {
  flex-direction: column;
  padding: var(--padding-9xs) 0 0;
  margin-left: -71px;
}
.div {
  height: 65px;
  width: 99px;
  flex-direction: row;
  padding: 0 var(--padding-12xl) 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-4xl);
  color: var(--color-white);
}
.frameParent,
.registrationButton,
.registrationButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frameParent {
  width: 369px;
  justify-content: center;
  gap: 0 27px;
}
.registrationButton,
.registrationButtonWrapper {
  box-sizing: border-box;
}
.registrationButton {
  flex: 1;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-xl);
}
.registrationButtonWrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 8.200000000000728px;
}
.listItem6 {
  position: absolute;
  top: 34.5px;
  left: 10.2px;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 1258.3px;
  height: 399.5px;
  object-fit: contain;
  z-index: 1;
}
.listItemLinkNoticeGroup {
  align-self: stretch;
  height: 399.5px;
  position: relative;
}
.listItem7,
.listItem8 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItem7 {
  width: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItem8 {
  flex: 1;
  font-size: var(--font-size-4xl);
  white-space: nowrap;
}
.frameDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 12px;
}
.chatItemFrameInner,
.listItem9 {
  display: flex;
  box-sizing: border-box;
}
.chatItemFrameInner {
  width: 261.4px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 2.2000000000007276px;
}
.listItem9 {
  width: 227px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  align-items: center;
  padding-right: var(--padding-xl);
}
.chatListItemFrame,
.monFriLabel {
  box-sizing: border-box;
  max-width: 100%;
}
.chatListItemFrame {
  height: 1px;
  flex: 1;
  position: relative;
  border-top: 1px solid var(--color-black);
}
.monFriLabel {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-11xs);
}
.chatItemFrame,
.listItemLinkNoticeParent1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.listItemLinkNoticeParent1 {
  display: flex;
  align-items: flex-start;
  gap: 28.5px 0;
  max-width: 100%;
  text-align: left;
}
.chatItemFrame {
  gap: 22.25px 0;
}
.chatItemFrame,
.chatListFrame,
.companyInfo {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.chatListFrame {
  width: 1260.4px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
  font-family: var(--font-apple-sd-gothic-neo);
}
.companyInfo {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.monFriText,
.weekdaysLabelChild {
  height: 135px;
  position: relative;
  background-color: var(--color-gainsboro-600);
}
.monFriText {
  flex: 1;
  min-width: 719px;
  max-width: 100%;
}
.weekdaysLabelChild {
  width: 116px;
  border-radius: var(--br-3xs);
  display: none;
}
.div2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  z-index: 1;
}
.monFriTextParent,
.weekdaysLabel {
  flex-direction: row;
  align-items: flex-start;
}
.weekdaysLabel {
  border-radius: var(--br-3xs);
  background-color: var(--color-gainsboro-600);
  display: flex;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-16xl) var(--padding-21xl)
    var(--padding-13xl);
}
.monFriTextParent {
  flex: 1;
  flex-wrap: wrap;
  gap: 0 27px;
}
.helpText,
.helpTextInner,
.monFriTextParent,
.qALabel {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.helpTextInner {
  width: 1271.4px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 11.200000000000728px 0 var(--padding-2xs);
  box-sizing: border-box;
  text-align: left;
  font-family: var(--font-apple-sd-gothic-neo);
}
.helpText,
.qALabel {
  flex-direction: column;
}
.helpText {
  align-self: stretch;
  align-items: flex-end;
  gap: 66.5px 0;
}
.qALabel {
  flex: 1;
  align-items: flex-start;
  gap: 7px 0;
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1350px) {
  .companyInfo {
    justify-content: center;
  }
}
@media screen and (max-width: 1125px) {
  .monFriText {
    min-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .cs {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .listItem2 {
    font-size: var(--font-size-9xl);
    line-height: 27px;
  }
  .registrationButton {
    flex-wrap: wrap;
  }
  .helpText {
    gap: 33px 0;
  }
}
@media screen and (max-width: 450px) {
  .cs {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .listItem,
  .listItem1 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .listItem2 {
    font-size: var(--font-size-2xl);
    line-height: 20px;
  }
  .listItem3,
  .listItem4,
  .listItem5 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div1 {
    font-size: var(--font-size-lg);
    line-height: 44px;
  }
  .frameParent {
    flex-wrap: wrap;
  }
  .listItem6,
  .listItem7,
  .listItem8 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .listItem8 {
    font-size: var(--font-size-lg);
  }
  .div2,
  .listItem9 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div2 {
    line-height: 44px;
  }
  .helpText {
    gap: 17px 0;
  }
}
