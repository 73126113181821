.onspaIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.cleanMade,
.div {
  position: relative;
  letter-spacing: -0.16px;
  z-index: 1;
}
.cleanMade {
  margin: 0;
  width: 158.8px;
  font-size: inherit;
  line-height: 40.8px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  max-width: 26%;
}
.div {
  font-size: var(--font-size-base);
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  display: inline-block;
  max-width: 25%;
}
.originIcon,
.sectionListItem {
  max-width: 100%;
  overflow: hidden;
}
.sectionListItem {
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) 202.19999999999985px;
  box-sizing: border-box;
  position: relative;
  gap: 11px 0;
  cursor: pointer;
}
.originIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100%;
  object-fit: cover;
}
.factEffect {
  margin: 0;
  width: 177.6px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 40.8px;
  font-weight: 400;
  font-family: inherit;
  align-items: center;
  max-width: 30%;
  z-index: 1;
}
.div1,
.factEffect,
.sectionListItem1 {
  display: flex;
  position: relative;
}
.div1 {
  width: 157.4px;
  height: 16px;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  align-items: center;
  flex-shrink: 0;
  max-width: 26%;
  z-index: 1;
}
.div3 {
  /*padding-top: 10px;*/
  display: block;
  width: 100%;
  height: 16px;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  align-items: center;
  flex-shrink: 0;
  max-width: 26%;
  z-index: 1;
  text-align: left;
  white-space: nowrap;
}
.sectionListItem1 {
  border-radius: var(--br-xs);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) 207.19999999999985px;
  box-sizing: border-box;
  gap: 16px 0;
  max-width: 100%;
  cursor: pointer;
}
.relaxationIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div2,
.sustainability {
  position: relative;
  letter-spacing: -0.16px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.sustainability {
  margin: 0;
  width: 181px;
  font-size: inherit;
  line-height: 40.8px;
  font-weight: 400;
  font-family: inherit;
  max-width: 30%;
}
.div2 {
  width: 171.9px;
  height: 16px;
  font-size: var(--font-size-base);
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  flex-shrink: 0;
  max-width: 29%;
}
.brandstory,
.brandstoryWrapper,
.sectionListItem2 {
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.sectionListItem2 {
  border-radius: var(--br-xs);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-xl) 207.19999999999985px;
  box-sizing: border-box;
  position: relative;
  gap: 16px 0;
  cursor: pointer;
}
.brandstory,
.brandstoryWrapper {
  flex-direction: row;
}
.sectionListItem, .sectionListItem1, .sectionListItem2 {
  flex: 1; /* 각 아이템이 사용 가능한 공간을 균등하게 차지 */
  text-align: center; /* 텍스트 중앙 정렬 */
}
.brandstory {
  flex: 1;
  /*display: grid;*/
  gap: var(--gap-xl);
  grid-template-columns: repeat(3, minmax(450px, 1fr));
  display: flex;
  flex-direction: row; /* 가로 방향 배치 */
  flex-wrap: nowrap; /* 모든 아이템을 한 줄에 배치 */
  justify-content: space-around; /* 요소들 사이에 균등한 간격을 제공 */
  align-items: center; /* 요소들을 수직 중앙 정렬 */
  width: 100%; /* 부모 컨테이너의 전체 너비 사용 */
}
.brandstoryWrapper {
  align-self: stretch;
  display: flex;
  padding: 0 var(--padding-21xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-15xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1450px) {
  .brandstory {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(450px, 780px));
  }
}
@media screen and (max-width: 1200px) {
  .brandstory {
    grid-template-columns: minmax(450px, 1fr);
  }
}
@media screen and (max-width: 825px) {
  .cleanMade,
  .factEffect,
  .sustainability {
    font-size: var(--font-size-8xl);
    line-height: 33px;
  }
}
@media screen and (max-width: 450px) {
  .cleanMade,
  .factEffect,
  .sustainability {
    font-size: var(--font-size-xl);
    line-height: 24px;
  }
}
