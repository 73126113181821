.loginKakaosvgFillIcon {
  height: 56px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 56px;
}
.div {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 54px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.formFieldsetFrame,
.formLink {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.formLink {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-34xl-5) 0 53.70000000000073px;
  background-color: var(--color-gold);
  flex: 0.7415;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  box-sizing: border-box;
  gap: 0 6px;
  min-width: 187px;
}
.loginNaversvgFillIcon {
  height: 54px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 54px;
}
.div1,
.formFieldsetLink {
  display: flex;
  justify-content: center;
}
.div1 {
  width: 91.7px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 54px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
  align-items: center;
  flex-shrink: 0;
}
.formFieldsetLink {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-12xs) var(--padding-xl);
  box-sizing: border-box;
  gap: 0 6px;
  min-width: 187px;
  white-space: nowrap;
}
.google11 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.google11Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
}
.div2 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 54px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.formFieldsetLink1,
.formLinkParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.formFieldsetLink1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-12xs) var(--padding-76xl) var(--padding-12xs)
    89.10000000000036px;
  background-color: var(--color-white);
  flex: 0.4458;
  border-radius: var(--br-xs);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  justify-content: center;
  box-sizing: border-box;
  min-width: 187px;
}
.formLinkParent {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 13.600000000000364px 0;
  gap: 0 21px;
}
.formFieldsetLabel,
.monFriFrame {
  outline: 0;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-base);
  color: var(--color-gainsboro-100);
  border: 0;
  width: 100%;
}
.formFieldsetLabel {
  background-color: var(--color-gainsboro-100);
  align-self: stretch;
  height: 62px;
  border-radius: var(--br-xs);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-2xl) var(--padding-base) var(--padding-lgi);
  box-sizing: border-box;
  min-width: 250px;
  color: #4a4a4a;
}
.formFieldsetLabel1 {
  background-color: var(--color-gainsboro-100);
  align-self: stretch;
  height: 62px;
  border-radius: var(--br-xs);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-2xl) var(--padding-base) var(--padding-lgi);
  box-sizing: border-box;
  min-width: 250px;
  color: #4a4a4a;
}
.monFriFrame {
  padding: var(--padding-mid) var(--padding-base) 28.399999999999636px;
  height: 100%;
  background: 0 0;
  position: relative;
  z-index: 3;
}
.div3,
.divplaceholder {
  flex-shrink: 0;
  display: flex;
}
.div3 {
  width: 59.1px;
  height: 16px;
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-gainsboro-100);
  text-align: left;
  align-items: center;
}
.divplaceholder {
  width: 942px;
  height: 21px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-12xs) var(--padding-base);
  box-sizing: border-box;
}
.input,
.wrapperMonFriFrame {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.input {
  width: 99%;
  height: 56px;
  border-radius: var(--br-xs);
  background-color: var(--color-gainsboro-100);
  box-shadow: 2px 4px 10px rgba(157, 180, 171, 0.2) inset;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-base) 0;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 4px;
  pointer-events: none;
}
.wrapperMonFriFrame {
  position: relative;
  width: 100%;
  border: 0;
  background-color: transparent;
  align-self: stretch;
  height: 73.4px;
  flex-direction: row;
  align-items: flex-start;
  min-width: 250px;
}
.formFieldsetInput {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
}
.formFieldsetInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-12xs);
}
.formFieldset {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 19.2px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: left;
}
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 21.19999999999891px 0;
  gap: 0 6px;
}
.div4 {
  width: 43.9px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 54px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.formFieldsetLink2,
.formFieldsetLinkFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.formFieldsetLink2 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-darkslategray-500);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-xl);
}
.formFieldsetLinkFrame {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 11.300000000001091px 0;
}
.formFieldset1,
.formFieldsetLink3 {
  align-self: stretch;
  position: relative;
}
.formFieldset1 {
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
}
.formFieldsetLink3 {
  height: 1px;
  background-color: var(--color-darkslategray-500);
}
.passwordFindLinkFrame {
  width: 67.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.7px 0;
}
.formFieldset2,
.formFieldsetLink4 {
  align-self: stretch;
  position: relative;
}
.formFieldset2 {
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
  padding-right: 0;
}
.formFieldsetLink4 {
  height: 1px;
  background-color: var(--color-darkslategray-500);
}
.iDFindLinkFrame,
.passwordFindLinkFrame1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.passwordFindLinkFrame1 {
  flex: 1;
  flex-direction: column;
  gap: 0.7px 0;
}
.iDFindLinkFrame {
  width: 159.3px;
  flex-direction: row;
  gap: 0 11.8px;
}
.formFieldset3,
.formFieldsetLink5 {
  align-self: stretch;
  position: relative;
}
.formFieldset3 {
  font-size: var(--font-size-sm);
  letter-spacing: -0.16px;
  line-height: 22.4px;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
  text-align: center;
  padding-right: 0;
}
.formFieldsetLink5 {
  height: 1px;
  background-color: var(--color-darkslategray-500);
}
.formFieldsetLinkParent {
  width: 51.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.7px 0;
}
.iDFindLinkFrameParent,
.passwordLabelFrame {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.iDFindLinkFrameParent {
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.passwordLabelFrame {
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
@media screen and (max-width: 1025px) {
  .formLinkParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .formFieldsetLink1,
  .formLink {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
  }
  .iDFindLinkFrameParent {
    flex-wrap: wrap;
    justify-content: center;
  }
}
