.total {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 61.2px;
}
.totalWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0px 0px;
}
.strong0 {
  width: 15px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  min-width: 15px;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.buy1 {
  width: 41.5px;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: -0.16px;
  line-height: 31px;
  font-family: var(--font-cormorant);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 41.5px;
}
.buy {
  cursor: pointer;
  border: none;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-mini)
  var(--padding-3xl);
  background-color: var(--color-darkslategray-500);
  width: 309px;
  border-radius: var(--br-base);
  box-shadow: 0px 4px 4px rgba(0, 58, 64, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}
.buy:hover {
  background-color: var(--color-dimgray-200);
}
.frameParent {
  width: 371.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-18xl);
  max-width: 100%;
}
.cartInner {
  width: 758.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--padding-xl) var(--padding-52xl);
  box-sizing: border-box;
  max-width: 100%;
}
.cart {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0px 0.2999999999992724px;
  box-sizing: border-box;
  gap: 165px;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}

@media screen and (max-width: 1150px) {
  .cart {
    gap: 82px 165px;
  }
}
@media screen and (max-width: 800px) {
  .total {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }

  .strong0 {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }

  .cart {
    gap: 41px 165px;
  }
}
@media screen and (max-width: 450px) {
  .total {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }

  .strong0 {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }

  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }

  .frameParent {
    gap: 18px 37px;
  }
}
