.cs,
.listItem {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.cs {
  width: 78.7px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 55px;
  display: flex;
  align-items: center;
}
.listItem {
  width: 93.3px;
  cursor: pointer;
}
.listItem,
.listItem1,
.listItem2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItem1 {
  margin: 0;
  width: 63.2px;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  flex-shrink: 0;
  cursor: pointer;
}
.listItem2 {
  width: 112px;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.menu,
.menuChild {
  align-self: stretch;
}
.menuChild {
  height: 0.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px 0;
}
.listItem3,
.listItem4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.listItem3 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.listItem4 {
  width: 139px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}
.chatWrapper,
.noticeListChild {
  align-self: stretch;
  max-width: 100%;
}
.chatWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
  font-family: var(--font-apple-sd-gothic-neo);
}
.noticeListChild {
  height: 0.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.monFri,
.noticeList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.noticeList {
  align-self: stretch;
  gap: 38.25px 0;
}
.monFri {
  width: 1258px;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-16xl);
}
.chatList,
.image1Icon {
  align-self: stretch;
  max-width: 100%;
}
.chatList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.image1Icon {
  height: 614.4px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frame,
.h3 {
  position: absolute;
}
.frame {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.h3 {
  margin: 0;
  top: 14px;
  left: 99px;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.text {
  height: 83px;
  width: 250px;
  position: relative;
}
.textWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.footerContainer,
.image {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.footerContainer {
  width: 500px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 88.6px 0;
}
.image {
  width: 1258px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  color: var(--color-white);
  font-family: var(--font-apple-sd-gothic-neo);
}
.footer,
.ownerInfo {
  align-self: stretch;
  flex-direction: column;
  max-width: 100%;
}
.ownerInfo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 62.5px 0;
}
.footer {
  align-items: flex-start;
  gap: 7px 0;
  text-align: center;
  font-size: var(--font-size-9xl);
}
.csNoticedetail,
.footer,
.line,
.qAList {
  display: flex;
  justify-content: flex-start;
}
.line {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px 0;
  max-width: 100%;
}
.csNoticedetail,
.qAList {
  box-sizing: border-box;
}
.qAList {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xs) var(--padding-24xl) var(--padding-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
.csNoticedetail {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-3xl) 0 0.3000000000001819px;
  gap: 211px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .chatList {
    flex-wrap: wrap;
    justify-content: center;
  }
  .qAList {
    padding-bottom: var(--padding-9xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1125px) {
  .csNoticedetail {
    gap: 105px 0;
  }
}
@media screen and (max-width: 800px) {
  .cs {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .listItem2 {
    font-size: var(--font-size-9xl);
    line-height: 27px;
  }
  .noticeList {
    gap: 19px 0;
  }
  .footerContainer {
    gap: 44px 0;
  }
  .ownerInfo {
    gap: 31px 0;
  }
  .qAList {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .csNoticedetail {
    gap: 53px 0;
  }
}
@media screen and (max-width: 450px) {
  .cs {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .listItem,
  .listItem1 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .listItem2 {
    font-size: var(--font-size-2xl);
    line-height: 20px;
  }
  .listItem3,
  .listItem4 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .chat {
    flex-wrap: wrap;
    justify-content: center;
  }
  .h3 {
    font-size: var(--font-size-3xl);
    line-height: 44px;
  }
  .footerContainer {
    gap: 22px 0;
  }
  .ownerInfo {
    gap: 16px 0;
  }
}
