.icon {
  height: 47px;
  width: 334px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.headerList,
.headerList1,
.icon {
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList3 {
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.cartLinkFrame,
.instagramFrame {
  display: flex;
  justify-content: flex-start;
}
.cartLinkFrame {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.instagramFrame {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-48xl);
  max-width: 100%;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}
.headerList4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  cursor: pointer;
}
.instagramFrameParent,
.thumbnailFrame {
  align-items: flex-start;
  justify-content: flex-start;
}
.thumbnailFrame {
  display: flex;
  flex-direction: row;
  gap: var(--gap-xs);
  text-align: right;
}
.instagramFrameParent {
  width: 914.5px;
  gap: var(--gap-172xl);
}
.cartFrame,
.headerFrame,
.instagramFrameParent {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.headerFrame {
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.cartFrame {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-16xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1250px) {
  .instagramFrame {
    display: none;
  }
  .instagramFrameParent {
    width: 336.5px;
  }
}
@media screen and (max-width: 1100px) {
  .instagramFrameParent {
    gap: 191px 95px;
  }
}
@media screen and (max-width: 750px) {
  .instagramFrame {
    gap: 67px 33px;
  }
}
@media screen and (max-width: 450px) {
  .instagramFrame {
    gap: 67px 17px;
  }
  .instagramFrameParent {
    gap: 191px 48px;
  }
}
