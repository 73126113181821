.onSpringInc {
  margin: 0;
  font-weight: 300;
}
.address744Ho,
.emailInfoonSpringcokr,
.ownerBryanKim,
.sujeongGuSeongnamSiGyeon,
.tel07044036450 {
  margin: 0;
}
.onSpringIncContainer1 {
  width: 100%;
}
.help,
.onSpring,
.onSpringIncContainer {
  position: absolute;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.onSpringIncContainer {
  top: 195px;
  left: 24px;
  display: flex;
  align-items: center;
  width: 424.8px;
  height: 157px;
}
.help,
.onSpring {
  font-weight: 300;
}
.onSpring {
  top: 416.4px;
  left: 24px;
  color: var(--color-darkslategray-100);
}
.help {
  top: 157px;
  left: 1484px;
  font-size: var(--font-size-3xl);
  text-decoration: underline;
  display: flex;
  align-items: center;
  width: 42.4px;
  height: 25px;
}
.infoonSpringcokr,
.p {
  margin: 0;
}
.listItem,
.listItemContainer {
  position: absolute;
  top: 233px;
  left: 1484px;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.listItem {
  top: 197px;
  cursor: pointer;
}
.lunchTime12001330,
.monFri10001700 {
  margin: 0;
}
.listItemContainer2 {
  width: 100%;
}
.listItemContainer1 {
  position: absolute;
  top: 294.6px;
  left: 1484px;
  letter-spacing: -0.16px;
  line-height: 26.4px;
  display: flex;
  align-items: center;
  width: 204.5px;
  height: 51.4px;
}
.p1,
.p2,
.qa {
  margin: 0;
}
.listItemContainer3 {
  position: absolute;
  top: 361px;
  left: 1484px;
  font-size: var(--font-size-mini);
  letter-spacing: -0.16px;
  line-height: 23px;
}
.listItem1 {
  top: 209px;
  cursor: pointer;
}
.listItem1,
.listItem2,
.listItem3 {
  position: absolute;
  left: 874px;
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.listItem2 {
  top: 237px;
  cursor: pointer;
}
.listItem3 {
  top: 284px;
  text-decoration: underline;
  font-weight: 300;
}
.p3,
.p4 {
  margin: 0;
}
.listItemContainer4 {
  position: absolute;
  top: 312px;
  left: 874px;
  font-size: var(--font-size-mid);
  letter-spacing: -0.16px;
  line-height: 26.4px;
}
.footer {
  position: absolute;
  top: 3916px;
  left: calc(50% - 960px);
  background: linear-gradient(180deg, #fff, #e3e8e1);
  width: 1920px;
  height: 468.7px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-dimgray);
  font-family: var(--font-apple-sd-gothic-neo);
}
