.divhinokAboutWrap,
.icon {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.divhinokAboutWrap {
  height: 1080px;
  width: 1920px;
  display: none;
}
.icon {
  align-self: stretch;
  height: 47px;
  object-fit: cover;
  cursor: pointer;
  z-index: 2;
}
.frameWrapper,
.waterBrand {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.waterBrand {
  width: 334px;
  flex-direction: column;
  padding: var(--padding-base) 0 0;
}
.frameWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-4xs);
}
.mineralWater {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 33px;
  font-weight: 500;
}
.text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 9.399999999999636px;
  font-size: var(--font-size-14xl);
}
.onYang1,
.p,
.p1,
.p2 {
  margin: 0;
  white-space: nowrap;
}
.onYang {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
}
.p3,
.p4 {
  margin: 0;
  white-space: nowrap
}
.txt {
  width: 100%;
}
.div,
.text1 {
  display: flex;
  max-width: 100%;
  flex-shrink: 0;
}
.div {
  margin-top: 9px;
  width: 333.6px;
  height: 76.8px;
  position: relative;
  /*letter-spacing: -0.16px;*/
  line-height: 25.6px;
  font-weight: 300;
  align-items: center;
}
.text1 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.8px 0;
}
.p5,
.p6,
.p7 {
  margin: 0;
  white-space: nowrap
}
.txt1 {
  width: 100%;
}
.div1 {
  width: 329.6px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-weight: 300;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.textParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px 0;
}
.frameGroup,
.textParent,
.waterBrandParent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frameGroup {
  width: 923.5px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 324px 0;
}
.waterBrandParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.origin {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 500;
  font-family: inherit;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}
.frameParent,
.text2 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.text2 {
  width: 573px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-56xl);
  font-family: var(--font-cormorant);
}
.frameParent {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 59.2px 0;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-apple-sd-gothic-neo);
}
.div2,
.div3,
.divIcon,
.divactive {
  height: 3px;
  width: 26px;
  position: relative;
  border-radius: var(--br-12xs);
  z-index: 2;
}
.divIcon {
  min-height: 3px;
  cursor: pointer;
}
.div2,
.div3,
.divactive {
  background-color: var(--color-white);
}
.div2,
.div3 {
  background-color: var(--color-gray-500);
  cursor: pointer;
}
.divActive,
.divActiveWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.divActive {
  justify-content: flex-start;
  gap: 0 6px;
}
.divActiveWrapper {
  width: 1711px;
  justify-content: center;
  max-width: 100%;
}
.brandstory2,
.divswiperSlide {
  display: flex;
  justify-content: flex-start;
}
.divswiperSlide {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-mini) var(--padding-17xl-5) var(--padding-34xl)
    var(--padding-17xl);
  box-sizing: border-box;
  gap: 190px 0;
  background-image: url(../../../public/BS2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  z-index: 1;
}
.brandstory2 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: flex-start;
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .waterBrandParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1150px) {
  .frameGroup {
    gap: 162px 0;
  }
  .divswiperSlide {
    gap: 95px 0;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .mineralWater {
    font-size: var(--font-size-7xl);
    line-height: 26px;
  }
  .origin {
    font-size: var(--font-size-41xl);
    line-height: 44px;
  }
  .frameParent {
    gap: 30px 0;
  }
  .divswiperSlide {
    gap: 47px 0;
  }
}
@media screen and (max-width: 450px) {
  .mineralWater {
    font-size: var(--font-size-xl);
    line-height: 20px;
  }
  .div,
  .div1,
  .onYang {
    font-size: var(--font-size-base);
    line-height: 20px;
  }
  .frameGroup {
    gap: 81px 0;
  }
  .origin {
    font-size: var(--font-size-26xl);
    line-height: 33px;
  }
  .divswiperSlide {
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
}
