.component4Child,
.component4Item {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1054px;
  object-fit: cover;
}
.component4Item {
  left: 1920px;
}
.component4 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.icon {
  height: 47px;
  width: 334px;
  position: relative;
  object-fit: contain;
  max-width: 100%;
  z-index: 1;
}
.headerList1,
.headerList2 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.headerList1,
.headerList2,
.headerList3,
.headerList4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  z-index: 1;
}
.headerList4 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.headerList,
.instagram1Wrapper {
  display: flex;
  justify-content: center;
}
.instagram1Wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.headerList {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 67px;
  max-width: 100%;
}
.headerList5,
.headerListItemLink {
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.hea5 {
  position: fixed;
  color: black;
  top: 0;
  right: 20px;
  z-index: 1;
  font-size: medium;
}
.hea6 {
  position: fixed;
  color: black;
  top: 22px;
  right: 20px;
  z-index: 1;
  font-size: medium;
}
.headerListItemLink {
  height: 50px;
  width: 50px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.headerList5 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
}
.loginLinkFrameText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 12px;
  text-align: right;
}
.headerListParent,
.parent {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.headerListParent {
  width: 914.5px;
  align-items: center;
  justify-content: space-around;
  gap: 0 191px;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
.parent {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 var(--padding-735xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  width: 100%;
}
.sectionList {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 66px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}
.sectionListItemHeadinWrapper {
  width: 555.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  max-width: 100%;
}
.p,
.p1 {
  margin: 0;
  font-family: var(--font-cormorant);
}
.sectionListContainer {
  height: 41.6px;
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  display: flex;
  align-items: center;
  max-width: 100%;
  z-index: 1;
}
.component4Parent,
.sectionListItemWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.sectionListItemWrapper {
  width: 344px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  font-size: var(--font-size-lgi);
  font-family: var(--font-apple-sd-gothic-neo);
}
.component4Parent {
  align-self: stretch;
  flex-direction: column;
  padding: var(--padding-7xl) var(--padding-20xl) 69.39999999999964px
    var(--padding-14xl);
  position: relative;
  gap: 17px 0;
  text-align: left;
  font-size: var(--font-size-46xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1450px) {
  .parent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .headerListParent {
    gap: 0 95px;
  }
  .component4Parent {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .headerList {
    gap: 0 33px;
  }
  .parent {
    padding-bottom: var(--padding-471xl);
    box-sizing: border-box;
  }
  .sectionList {
    font-size: var(--font-size-33xl);
    line-height: 53px;
  }
  .component4Parent {
    padding-bottom: var(--padding-10xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .headerList {
    gap: 0 17px;
  }
  .headerListParent {
    gap: 0 48px;
  }
  .parent {
    padding-bottom: var(--padding-299xl);
    box-sizing: border-box;
  }
  .sectionList {
    font-size: var(--font-size-20xl);
    line-height: 40px;
  }
}
