.sectionList {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
}
.sectionListItemLink {
  align-self: stretch;
  height: 535.2px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.after,
.cart {
  position: absolute;
}
.cart {
  top: 0;
  left: calc(50% - 21.25px);
  letter-spacing: -0.16px;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.after {
  top: 1px;
  left: calc(50% - 21.2px);
  width: 42.5px;
  height: 54px;
  overflow: hidden;
}
.sectionListItem {
  width: 84.4px;
  height: 56px;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: none;
}
.sectionList1 {
  margin: 0;
  width: 152.6px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.sectionListItemLinkWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.div,
.div1 {
  position: relative;
  letter-spacing: -0.16px;
  white-space: nowrap;
}
.div {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.div1 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.frameWrapper1,
.parent,
.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper {
  width: 98px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.frameWrapper1,
.parent {
  flex-direction: row;
}
.parent {
  flex: 1;
  row-gap: 20px;
}
.frameWrapper1 {
  align-self: stretch;
  padding: 0 var(--padding-34xl) 0 var(--padding-33xl);
}
.ml {
  margin: 0;
}
.sectionListContainer {
  align-self: stretch;
  height: 59px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-dimgray-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.frameParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 6px 0;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
}
.frameContainer,
.frameDiv {
  display: flex;
  max-width: 100%;
}
.frameDiv {
  width: 334px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 13px 0;
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: var(--font-size-9xl);
}
.sectionListItemLinkGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs-8);
  min-width: 348px;
  max-width: 100%;
  cursor: pointer;
}
.sectionListItemLink1 {
  align-self: stretch;
  height: 535.2px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.after1,
.cart1 {
  position: absolute;
}
.cart1 {
  top: 0;
  left: calc(50% - 21.25px);
  letter-spacing: -0.16px;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.after1 {
  top: 1px;
  left: calc(50% - 21.2px);
  width: 42.5px;
  height: 54px;
  overflow: hidden;
}
.sectionListItem1 {
  width: 84.4px;
  height: 56px;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  display: none;
}
.sectionList2 {
  margin: 0;
  width: 138px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-weight: 400;
  font-family: inherit;
  align-items: center;
  flex-shrink: 0;
}
.ml1,
.sectionList2,
.sectionListItemLinkFrame {
  display: flex;
  justify-content: center;
}
.sectionListItemLinkFrame {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
}
.ml1 {
  height: 41.6px;
  flex: 1;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  align-items: center;
  max-width: 100%;
}
.sectionListItemLink2 {
  position: absolute;
  top: 40px;
  left: 0;
  width: 360px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 1.2999999999997272px 5.599999999999454px
    1.400000000000091px;
  box-sizing: border-box;
  opacity: 0.9;
  max-width: 100%;
}
.div2,
.div3 {
  position: relative;
  letter-spacing: -0.16px;
  white-space: nowrap;
}
.div2 {
  flex: 1;
  text-decoration: line-through;
  line-height: 42px;
}
.div3 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.container,
.group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container {
  width: 96px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.group {
  position: absolute;
  top: 0;
  left: 73px;
  width: 223.5px;
  flex-direction: row;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
  font-family: var(--font-cormorant);
}
.sectionListItemLinkParent1 {
  align-self: stretch;
  height: 91.2px;
  position: relative;
  max-width: 100%;
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
}
.frameParent2,
.frameWrapper2 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frameParent2 {
  width: 360px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 13px 0;
}
.frameWrapper2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-9xl);
}
.sectionListItemLinkContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs-8);
  min-width: 348px;
  max-width: 100%;
  cursor: pointer;
}
.frameGroup,
.sectionListItemLinkParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameGroup {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 44px;
  text-align: center;
  font-size: var(--font-size-5xl);
}
.sectionListItemLinkParent {
  flex: 1;
  flex-direction: column;
  gap: 21px 0;
}
.frameParent,
.frameWrapper,
.mainPage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.frameWrapper {
  width: 1194.4px;
  flex-direction: row;
  padding: 0 var(--padding-21xl);
  max-width: 100%;
}
.frameParent,
.mainPage {
  flex-direction: column;
}
.frameParent {
  align-self: stretch;
  padding: 0 0 var(--padding-16xl);
  gap: 34px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-dimgray-100);
  font-family: var(--font-cormorant);
}
.mainPage {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: 0 0 0.3000000000001819px;
  gap: 234px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .mainPage {
    gap: 117px 0;
  }
}
@media screen and (max-width: 825px) {
  .sectionList {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .sectionListItemLinkContainer,
  .sectionListItemLinkGroup {
    min-width: 100%;
  }
  .frameGroup {
    gap: 0 22px;
  }
  .frameParent {
    gap: 17px 0;
  }
  .mainPage {
    gap: 58px 0;
  }
}
@media screen and (max-width: 450px) {
  .sectionList {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
  .cart {
    font-size: var(--font-size-lgi);
    line-height: 43px;
  }
  .sectionList1 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
  .parent {
    flex-wrap: wrap;
  }
  .frameWrapper1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .cart1 {
    font-size: var(--font-size-lgi);
    line-height: 43px;
  }
  .sectionList2 {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div2,
  .div3 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
}
