.headerList,
.headerList1 {
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  z-index: 2;
}
.headerList3 {
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.instagramFrame,
.menuItem {
  display: flex;
  justify-content: flex-start;
}
.instagramFrame {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.menuItem {
  flex-direction: row;
  align-items: flex-end;
  gap: 0 67px;
  max-width: 100%;
}
.headerList4,
.headerListItemLink {
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.headerList4 {
  letter-spacing: -0.16px;
  line-height: 48px;
}
.cartText,
.shopHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.cartText {
  justify-content: flex-start;
  gap: 0 12px;
  text-align: right;
  /*height: 48px;*/
  /*width: 32px;*/
}
.shopHeader {
  flex: 1;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1400px) {
  .shopHeader {
    flex: 1;
  }
}
@media screen and (max-width: 825px) {
  .menuItem {
    display: none;
    gap: 0 33px;
  }
}
@media screen and (max-width: 450px) {
  .menuItem {
    gap: 0 17px;
  }
}
