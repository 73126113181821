.inputTitle, .inputContent, .inputPassword {
  width: 100%; /* Adjust width as necessary */
  padding: 8px; /* Adjust padding as necessary */
  margin: 5px 0; /* Adjust margin as necessary */
  background-color: transparent; /* Make the background transparent */
  border: none; /* Remove the border */
  outline: none; /* Remove the outline to prevent it from appearing on focus */
  box-sizing: border-box;
  color: inherit; /* Inherits the color from the parent element */
}

.inputContent {
  height: 100px; /* Adjust based on your design */
}

.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 250px;
  height: 83px;
  cursor: pointer;
}
.div {
  position: absolute;
  top: 14px;
  left: 99px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.groupItem,
.rectangleParent {
  position: absolute;
  width: 250px;
  height: 83px;
}
.rectangleParent {
  top: 1401px;
  left: 1315px;
  color: var(--color-darkslategray-500);
}
.groupItem {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  cursor: pointer;
}
.div1 {
  position: absolute;
  top: 14px;
  left: 99px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.rectangleGroup {
  position: absolute;
  top: 1401px;
  left: 998px;
  width: 250px;
  height: 83px;
  color: var(--color-darkslategray-500);
}
.span {
  font-family: var(--font-apple-symbols);
}
.listItemContainer {
  position: absolute;
  top: 426px;
  left: 650px;
  font-size: var(--font-size-16xl);
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-family: var(--font-cormorant);
}
.csQnawriteChild,
.csQnawriteItem {
  position: absolute;
  top: 491px;
  left: 650px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 1195px;
  height: 61px;
}
.csQnawriteItem {
  top: 1293px;
  border: 1px solid var(--color-gainsboro-300);
}
.listItem {
  position: absolute;
  top: 1306px;
  left: 679px;
  font-size: var(--font-size-6xl);
  letter-spacing: -0.16px;
  line-height: 33.6px;
}
.csQnawriteInner {
  position: absolute;
  top: 583px;
  left: 650px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 1195px;
  height: 679px;
}
.listItem1 {
  top: 608px;
  left: 679px;
  font-size: var(--font-size-6xl);
  line-height: 33.6px;
}
.cs,
.listItem1,
.listItem2,
.listItem3,
.listItem4 {
  position: absolute;
  letter-spacing: -0.16px;
}
.listItem2 {
  top: 504px;
  left: 679px;
  font-size: var(--font-size-6xl);
  line-height: 33.6px;
}
.cs,
.listItem3,
.listItem4 {
  left: 0;
  display: flex;
  align-items: center;
}
.cs {
  top: 0;
  font-size: var(--font-size-31xl);
  line-height: 55px;
  text-align: left;
  width: 58.7px;
  height: 56px;
}
.listItem3,
.listItem4 {
  line-height: 33.6px;
  justify-content: center;
  height: 31px;
}
.listItem3 {
  top: 84px;
  width: 73.3px;
  cursor: pointer;
}
.listItem4 {
  top: 122px;
  width: 63.2px;
}
.csParent {
  position: absolute;
  top: 296px;
  left: 23px;
  width: 73.3px;
  height: 153px;
  text-align: center;
  font-family: var(--font-cormorant);
}
.csQnawrite {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 2156px;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
