.total {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 30px;
  font-weight: 400;
  font-family: inherit;
}
.totalAmountLabelFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.strong0 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 30px;
  text-align: right;
}
.chatCellFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.buy,
.buy1 {
  display: flex;
  justify-content: center;
}
.buy1 {
  margin: 0;
  width: 41.5px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 31px;
  font-weight: 400;
  font-family: inherit;
  align-items: center;
  flex-shrink: 0;
}
.buy {
  width: 309px;
  border-radius: var(--br-base);
  background-color: var(--color-darkslategray-200);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-mini) var(--padding-xl) var(--padding-mini)
    var(--padding-3xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-white);
}
.chatFrame,
.weekdayFrame {
  display: flex;
  max-width: 100%;
}
.weekdayFrame {
  width: 371.1px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 37px 0;
}
.chatFrame {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1025px) {
  .strong0,
  .total {
    font-size: var(--font-size-5xl);
    line-height: 24px;
  }
}
@media screen and (max-width: 450px) {
  .strong0,
  .total {
    font-size: var(--font-size-lg);
    line-height: 18px;
  }
  .buy1 {
    font-size: var(--font-size-3xl);
    line-height: 48px;
  }
  .weekdayFrame {
    gap: 18px 0;
  }
}
