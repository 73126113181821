.thumbIcon {
  height: 233.3px;
  width: 200px;
  position: relative;
  object-fit: cover;
}
.heading4 {
  margin: 0;
  font-size: inherit;
  line-height: 33.6px;
  font-weight: 400;
  font-family: inherit;
}
.div,
.heading4,
.heading41 {
  position: relative;
  letter-spacing: -0.16px;
}
.heading41 {
  line-height: 31.2px;
  white-space: nowrap;
}
.div {
  font-size: var(--font-size-6xl);
  line-height: 55px;
  font-family: var(--font-apple-sd-gothic-neo);
  color: var(--color-darkslategray-500);
}
.auntThriceRemovedNodes,
.siblingThriceRemovedNodes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.auntThriceRemovedNodes {
  font-size: var(--font-size-7xl);
}
.siblingThriceRemovedNodes {
  gap: 15px 0;
}
.descendantThriceRemovedNode,
.headerRowCell {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.descendantThriceRemovedNode {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 30.299999999999272px;
  min-width: 416px;
  flex-shrink: 0;
}
.headerRowCell {
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkslategray-1000);
  flex-direction: row;
  align-items: flex-end;
  padding: var(--padding-base) 0 17.700000000000728px;
  row-gap: 20px;
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 750px) {
  .thumbIcon {
    flex: 1;
  }
  .heading4 {
    font-size: var(--font-size-13xl);
    line-height: 27px;
  }
  .descendantThriceRemovedNode {
    flex: 1;
    min-width: 100%;
  }
  .headerRowCell {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .heading4 {
    font-size: var(--font-size-5xl);
    line-height: 20px;
  }
  .heading41 {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }
  .div {
    font-size: var(--font-size-xl);
    line-height: 44px;
  }
}
