.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-lightgray);
  width: 250px;
  height: 83px;
  cursor: pointer;
}
.div {
  position: absolute;
  top: 14px;
  left: 99px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.groupItem,
.rectangleParent {
  position: absolute;
  width: 250px;
  height: 83px;
}
.rectangleParent {
  top: 1323px;
  left: 1315px;
  color: var(--color-darkslategray-500);
}
.groupItem {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  cursor: pointer;
}
.div1 {
  position: absolute;
  top: 14px;
  left: 99px;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.rectangleGroup {
  position: absolute;
  top: 1323px;
  left: 998px;
  width: 250px;
  height: 83px;
  color: var(--color-darkslategray-500);
}
.span {
  font-family: var(--font-apple-sd-gothic-neo);
}
.listItemContainer {
  position: absolute;
  top: 426px;
  left: 650px;
  font-size: var(--font-size-16xl);
  letter-spacing: -0.16px;
  line-height: 33.6px;
  font-family: var(--font-cormorant);
}
.csNoticeupdateChild,
.csNoticeupdateItem {
  position: absolute;
  top: 491px;
  left: 650px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 1195px;
  height: 61px;
}
.csNoticeupdateItem {
  top: 583px;
  height: 679px;
}
.listItem {
  top: 608px;
  left: 679px;
  font-size: var(--font-size-6xl);
  line-height: 33.6px;
}
.cs,
.listItem,
.listItem1,
.listItem2,
.listItem3 {
  position: absolute;
  letter-spacing: -0.16px;
}
.listItem1 {
  top: 504px;
  left: 679px;
  font-size: var(--font-size-6xl);
  line-height: 33.6px;
}
.cs,
.listItem2,
.listItem3 {
  left: 0;
  display: flex;
  align-items: center;
}
.cs {
  top: 0;
  font-size: var(--font-size-31xl);
  line-height: 55px;
  text-align: left;
  width: 58.7px;
  height: 56px;
}
.listItem2,
.listItem3 {
  line-height: 33.6px;
  justify-content: center;
  height: 31px;
}
.listItem2 {
  top: 84px;
  width: 73.3px;
  cursor: pointer;
}
.listItem3 {
  top: 122px;
  width: 63.2px;
}
.csParent {
  position: absolute;
  top: 296px;
  left: 23px;
  width: 73.3px;
  height: 153px;
  text-align: center;
  font-family: var(--font-cormorant);
}
.csNoticeupdate {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 2156px;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-apple-sd-gothic-neo);
}
