.icon {
  height: 47px;
  width: 334px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.headerList,
.headerList1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.headerList,
.headerList1,
.headerList2,
.headerList3 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
}
.headerList3 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.instagram1Icon {
  width: 29px;
  height: 29px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.listFrame,
.loginFrame {
  display: flex;
  justify-content: flex-start;
}
.loginFrame {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-4xs);
}
.listFrame {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 67px;
  max-width: 100%;
}
.headerListItemLink {
  height: 63px;
  width: 62px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}
.headerList4 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.16px;
  line-height: 48px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
}
.cSFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 12px;
  text-align: right;
}
.headerFrame,
.shopFrame {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.shopFrame {
  width: 914.5px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 191px;
}
.headerFrame {
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.cSqnaUpdateContainer,
.csQnaupdate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.cSqnaUpdateContainer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-16xl) var(--padding-5xs);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
.csQnaupdate {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-3xl) 0 0.3000000000001819px;
  gap: 203px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1125px) {
  .shopFrame {
    gap: 0 95px;
  }
  .csQnaupdate {
    gap: 101px 0;
  }
}
@media screen and (max-width: 800px) {
  .listFrame {
    gap: 0 33px;
  }
  .csQnaupdate {
    gap: 51px 0;
  }
}
@media screen and (max-width: 450px) {
  .listFrame {
    gap: 0 17px;
  }
  .shopFrame {
    gap: 0 48px;
  }
}
