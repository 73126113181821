.blankLine,
.id,
.p,
.p1,
.p10,
.p11,
.p12,
.p13,
.p14,
.p15,
.p16,
.p17,
.p18,
.p19,
.p2,
.p20,
.p21,
.p22,
.p23,
.p24,
.p25,
.p26,
.p27,
.p28,
.p29,
.p3,
.p30,
.p31,
.p32,
.p33,
.p34,
.p35,
.p36,
.p37,
.p38,
.p39,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9,
.pc {
  margin: 0;
}
.pcContainer {
  position: absolute;
  top: 337px;
  left: 12px;
  letter-spacing: -0.16px;
  line-height: 23px;
}
.divcontainer {
  position: absolute;
  top: calc(50% - 1962.5px);
  left: calc(50% - 523px);
  width: 1046px;
  height: 3925px;
}
.termsOfUse {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 55px;
}
.child {
  top: 62.8px;
  left: 3px;
  max-height: 100%;
  width: 974px;
}
.child,
.content,
.div {
  position: absolute;
}
.div {
  top: 269px;
  left: 5px;
  width: 977px;
  height: 62.8px;
  font-size: var(--font-size-31xl);
  font-family: var(--font-cormorant);
}
.content {
  top: calc(50% - 2192.5px);
  left: calc(50% - 86px);
  width: 1046px;
  height: 3925px;
}
.termsofuse {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 4385px;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-darkslategray-500);
  font-family: var(--font-apple-sd-gothic-neo);
}
