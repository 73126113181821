.link, .button {
  border: none; /* 테두리 제거 */
}
.headerList,
.headerList1 {
  cursor: pointer;
}
.infoText {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;  /* 전체 너비 사용 */
  max-width: 600px;  /* 최대 너비 설정 */
  padding: 10px;  /* 내용과 테두리 사이의 간격 */
  box-sizing: border-box;  /* 패딩을 너비에 포함 */
}
.headerList,
.headerList1,
.headerList2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 48px;
  display: none;
}
.headerList3,
.icon {
  position: relative;
  display: none;
  cursor: pointer;
}
.headerList3 {
  letter-spacing: -0.16px;
  line-height: 48px;
}
.icon {
  width: 334px;
  height: 47px;
  object-fit: cover;
  max-width: 100%;
}
.headerList4,
.join {
  letter-spacing: -0.16px;
}
.headerList4 {
  position: relative;
  line-height: 48px;
  text-align: right;
  display: none;
  cursor: pointer;
}
.join {
  margin: 0;
  width: 98.5px;
  font-size: inherit;
  line-height: 55px;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}
.input,
.join,
.label {
  position: relative;
}
.input {
  height: 16px;
  width: 16px;
  border-radius: var(--br-9xs);
  background-color: var(--color-gainsboro-100);
}
.label {
  width: 107.9px;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-family: var(--font-apple-sd-gothic-neo);
  font-size: var(--font-size-lg);
  background-color: transparent;
  height: 19.2px;
  letter-spacing: -0.16px;
  line-height: 19.2px;
  color: var(--color-darkslategray-500);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
}
.divagreeall {
  align-self: stretch;
  border-top: 1px solid var(--color-darkslategray-500);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi) 0 40.099999999998545px;
  gap: 0 10px;
}
.input1 {
  margin: 0;
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-9xs);
}
.label1,
.label2 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 19.2px;
}
.inputPersonalInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  gap: 0 4.9px;
}
.input2 {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
}
.listItemSMSConsent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.6000000000003638px 0 0;
}
.label3,
.label4 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 19.2px;
}
.labelPersonalInfoProvide {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4.5px;
}
.input3 {
  margin: 0;
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-9xs);
}
.label5,
.label6 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 19.2px;
}
.inputPersonalInfo1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
  gap: 0 4.9px;
}
.heading3Input {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
}
.springIncOwnerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.6000000000003638px 0 0;
}
.heading3,
.heading31 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 19.2px;
}
.footerFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 6px;
}
.upArrowIcon {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-shrink: 0;
}
.monFri {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 1.2000000000007276px;
}
.listItem,
.listItem1 {
  position: relative;
  letter-spacing: -0.16px;
  line-height: 19.2px;
}
.listItem1 {
  flex: 1;
}
.chatListItemLinkChat {
  width: 172.1px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 6.15px;
}
.divAgreeAllContainer,
.linkCancelButtonNext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.linkCancelButtonNext {
  align-self: stretch;
  padding: 0 var(--padding-base) 0 0;
  gap: 14.7px 0;
}
.divAgreeAllContainer {
  width: 100%;
  max-width: 800px;
  /*width: 241.5px;*/
  gap: 19.4px 0;
  min-height: auto;
  flex-direction: column;
}
.directionUpsvgIcon,
.directionUpsvgIcon1,
.directionUpsvgIcon2 {
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.directionUpsvgIcon {
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 71.4px;
}
.directionUpsvgIcon1,
.directionUpsvgIcon2 {
  position: relative;
}
.ownerInfoFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 17.2px 0;
}
.weekdaysLabelIcon {
  width: 22px;
  flex: 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
}
.helpTextFrame {
  align-self: stretch;
  width: 22.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 53.5px 0;
}
.linkCancelButtonNext1 {
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}
.divagreeinner,
.joinParent,
.linkCancelButtonNext1,
.tEXTLabelAllAgree {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.divagreeinner {
  flex: 1;
  border-top: 1px solid var(--color-whitesmoke);
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-sm) 0 26.99999999999818px;
  gap: var(--gap-xl);
}
.joinParent,
.tEXTLabelAllAgree {
  flex-direction: column;
  justify-content: flex-start;
}
.tEXTLabelAllAgree {
  flex: 1;
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
}
.joinParent {
  height: 361.2px;
  gap: 24px 0;
}
.div {
  width: 29.3px;
  position: relative;
  letter-spacing: -0.16px;
  line-height: 54px;
  font-weight: 600;
  align-items: center;
  flex-shrink: 0;
}
.div,
.div1,
.link {
  display: flex;
  justify-content: center;
}
.link {
  width: 459px;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-12xs) var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.div1 {
  width: 29.6px;
  position: relative;
  line-height: 54px;
  font-weight: 600;
  align-items: center;
  flex-shrink: 0;
}
.button,
.frameParent,
.linkChat {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.button {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-darkslategray-500);
  box-shadow: 0 4px 4px rgba(0, 58, 64, 0.1);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-xl);
  box-sizing: border-box;
  min-width: 306px;
  color: var(--color-white);
}
.frameParent,
.linkChat {
  justify-content: flex-start;
}
.linkChat {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 12px;
  text-align: center;
  font-size: var(--font-size-base);
  font-family: var(--font-apple-sd-gothic-neo);
}
.frameParent {
  flex: 1;
  flex-direction: column;
  gap: 40.1px 0;
}
.divAgreeAllFrame,
.joinAgree {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.divAgreeAllFrame {
  width: 974px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xs) 122.70000000000071px var(--padding-xl);
  max-width: 100%;
  font-size: var(--font-size-31xl);
}
.joinAgree {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-2xs) 0 0.2999999999992724px;
  gap: 201px 0;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-500);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 1025px) {
  .headerList,
  .headerList1,
  .headerList2,
  .headerList3,
  .headerList4 {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }
  .join {
    font-size: var(--font-size-21xl);
    line-height: 44px;
  }
  .joinAgree {
    gap: 100px 0;
  }
}
@media screen and (max-width: 750px) {
  .frameParent {
    gap: 20px 0;
  }
  .divAgreeAllFrame {
    padding-bottom: 80px;
    box-sizing: border-box;
  }
  .joinAgree {
    gap: 50px 0;
  }
}
@media screen and (max-width: 450px) {
  .headerList,
  .headerList1,
  .headerList2,
  .headerList3,
  .headerList4 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .join {
    font-size: var(--font-size-11xl);
    line-height: 33px;
  }
}
