.ml {
  margin: 0;
}
.sectionListContainer1 {
  width: 100%;
}
.sectionListContainer {
  width: 334px;
  height: 59px;
  position: absolute;
  margin: 0 !important;
  bottom: -26px;
  left: 119.7px;
  letter-spacing: -0.16px;
  line-height: 25.6px;
  font-family: var(--font-apple-sd-gothic-neo);
  display: flex;
  align-items: center;
  opacity: 0.9;
}
.div,
.div1,
.setBodySerum {
  position: relative;
  letter-spacing: -0.16px;
}
.setBodySerum {
  align-self: stretch;
  font-size: var(--font-size-9xl);
  line-height: 33.6px;
  color: var(--color-darkslategray-200);
}
.div,
.div1 {
  white-space: nowrap;
}
.div {
  flex: 1;
  text-decoration: line-through;
  line-height: 42px;
}
.div1 {
  width: 127.5px;
  height: 42px;
  line-height: 45.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.monFriFrame {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-darkslategray-200);
}
.chatItemFrame,
.chatLinkFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.chatLinkFrame {
  width: 223.5px;
  justify-content: flex-start;
}
.chatItemFrame {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
}
.divitemDescription,
.helpTextFrame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divitemDescription {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 var(--padding-21xl);
  position: relative;
  gap: 5px 0;
  cursor: pointer;
}
.helpTextFrame {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-smi);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  font-family: var(--font-cormorant);
}
@media screen and (max-width: 450px) {
  .setBodySerum {
    font-size: var(--font-size-3xl);
    line-height: 27px;
  }
  .div,
  .div1 {
    font-size: var(--font-size-2xl);
    line-height: 36px;
  }
}
